import React, { Component } from 'react'
import { connect } from 'react-redux'
import { map } from 'lodash'

import { Col, Row, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'

import SelectBox from '../../../../shared/SelectBox'

import { AppState } from '../../../../../store'
import { setSuccess } from '../../../../../store/actions'

import {
  SelectValue,
  CustomSelectValue,
  QueueTypeEnum,
} from '../../../../../shared/models'
import {
  btnDistributeText,
  btnLoadingText,
} from '../../../../../shared/constants'
import {
  getRegularUsers,
  formatUserDataForLabel,
  taskCountOptionsForUserAtFinding,
  taskCountOptionsForUserAtTagging,
  validateField,
  getCountriesBySicForSourceFinding,
  getCountriesBySicForSourceTagging,
  redistributeReserveToUserDistributor,
  distributorGetSicsForInitialFinding,
  distributorGetSicsForInitialTagging,
  cancelActiveRequests,
  distributorGetBatchesFromSource,
} from '../../../../../services'

interface CountriesSelectValue extends SelectValue {
  countrySicCount?: number
}

interface State {
  countries: CountriesSelectValue[]
  sics: SelectValue[]
  sicsCounting: {}
  selectedSicsCount: number
  users: SelectValue[]

  taskCountOptions: SelectValue[]
  taskCountOptionSelected: number
  batches: CustomSelectValue[]
  selectedBatch: number

  destinationUserId?: number | null
  sourceUserId?: number | null
  domicileId?: number | null
  sic?: string
  isSubmitting: boolean
  btnDistributeText: string
}

interface DispatchProps {}

interface StateProps {
  isFinding: boolean
}

type Props = StateProps & AppState & DispatchProps

const mapStateToProps = (state: AppState) => ({ ...state })
const mapDispatchToProps = () => ({})

const COMPANY_TYPE = 'Distributor'
class RedistributeQueueToUser extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const taskCountOptionsBase = this.props.isFinding
      ? taskCountOptionsForUserAtFinding()
      : taskCountOptionsForUserAtTagging()
    this.state = {
      selectedSicsCount: 0,
      sicsCounting: {},
      countries: [],
      users: [],
      sics: [],

      domicileId: 0,
      selectedBatch: 0,
      batches: [],
      taskCountOptions: taskCountOptionsBase,
      taskCountOptionSelected: 0,

      destinationUserId: null,
      sourceUserId: null,
      isSubmitting: false,
      btnDistributeText,
    }
  }

  async componentDidMount(): Promise<void> {
    await cancelActiveRequests()
    await this.getInitialData()
  }

  setTaskCountsOptions(number): void {
    // add new value and set the new value
    const taskCountOptionsBase = this.props.isFinding
      ? taskCountOptionsForUserAtFinding()
      : taskCountOptionsForUserAtTagging()
    let NewtaskCountOptionsBase = taskCountOptionsBase.filter(
      element => element.value < number
    )
    NewtaskCountOptionsBase.push({
      value: number,
      label: number,
    })
    this.setState({
      taskCountOptions: NewtaskCountOptionsBase,
      taskCountOptionSelected: number,
    })
  }

  setMaxCompsByCountry(comps, fromStateCountries = false) {
    const sicCount = comps.reduce(
      (count, sic) => count + sic.countrySicCount,
      0
    )
    this.setTaskCountsOptions(sicCount)
    this.setState({ selectedSicsCount: sicCount })
    if (!fromStateCountries) {
      this.setState({
        countries: map(comps, comp => ({
          value: comp.id,
          label: comp.name,
          countrySicCount: comp.countrySicCount,
        })),
      })
    }
  }

  async getCountries(
    sourceId: number,
    sic: string | null = null
  ): Promise<void> {
    const countriesBySicResult = this.props.isFinding
      ? await getCountriesBySicForSourceFinding(sourceId, sic)
      : await getCountriesBySicForSourceTagging(sourceId, sic)

    this.setMaxCompsByCountry(
      countriesBySicResult ? countriesBySicResult.data : []
    )
  }

  async getUsers(): Promise<void> {
    const regularUsersResult = await getRegularUsers()
    const regularUsers = regularUsersResult ? regularUsersResult.data : []
    this.setState({
      users: map(regularUsers, user => {
        return {
          label: formatUserDataForLabel(user),
          value: user.userId,
        }
      }),
    })
  }

  async getInitialData(): Promise<void> {
    this.setState({
      domicileId: 0,
      destinationUserId: null,
      taskCountOptionSelected: 0,
      selectedBatch: 0,
      sourceUserId: null,
    })
    await this.getUsers()
  }

  async getBatches(sourceUserId): Promise<void> {
    const args = {
      companyType: COMPANY_TYPE,
      userId: sourceUserId,
    }
    const batchesForSystemResult = this.props.isFinding
      ? await distributorGetBatchesFromSource({
          ...args,
          companyStatus: QueueTypeEnum.FindingInitial,
        })
      : await distributorGetBatchesFromSource({
          ...args,
          companyStatus: QueueTypeEnum.TaggingInitial,
        })

    const batches = batchesForSystemResult ? batchesForSystemResult.data : []

    this.setState({
      batches: map(batches, item => {
        return {
          value: item.batchId,
          label: `${item.batchName} (${item.companiesCount})`,
          quantity: parseInt(item.companiesCount),
        }
      }),
    })
  }

  handleCountryChange(domicileId: number): void {
    if (Number(domicileId) === 0) {
      this.setState({ domicileId })
      return this.setMaxCompsByCountry(this.state.countries, true)
    }

    const { countries, selectedSicsCount } = this.state
    const selectedCountry = countries.find(
      country => country.value === +domicileId
    )
    let selectedSics = selectedSicsCount

    if (selectedCountry && selectedCountry.countrySicCount) {
      selectedSics = selectedCountry.countrySicCount
    }

    this.setTaskCountsOptions(selectedSics)
    this.setState({
      domicileId,
      selectedSicsCount: selectedSics,
    })
  }

  async handleUserChange(userId: number, isSource: boolean): Promise<void> {
    let {
      sourceUserId: newSourceUserId,
      destinationUserId: newDestinationUserId,
    } = this.state

    if (isSource) {
      newSourceUserId = userId
    } else {
      newDestinationUserId = userId
    }

    if (newSourceUserId === newDestinationUserId) {
      if (isSource) {
        newDestinationUserId = null
      } else {
        newSourceUserId = null
      }
    }

    if (newSourceUserId === null || !userId.toString().length) {
      this.setState({
        domicileId: undefined,
        selectedSicsCount: 0,
        selectedBatch: 0,
        sic: undefined,
        sourceUserId: newSourceUserId,
        destinationUserId: newDestinationUserId,
        taskCountOptionSelected: 0,
      })
    } else if (isSource) {
      this.setState({
        sourceUserId: newSourceUserId,
        destinationUserId: newDestinationUserId,
        selectedBatch: 0,
      })
      await Promise.all([
        this.getSics(userId),
        this.getCountries(userId),
        this.getBatches(userId),
      ])
    } else {
      this.setState({
        sourceUserId: newSourceUserId,
        destinationUserId: newDestinationUserId,
      })
    }
  }

  clearAll = () => {
    this.setState({
      domicileId: undefined,
      destinationUserId: null,
      selectedSicsCount: 0,
      sic: undefined,
      sourceUserId: null,
      taskCountOptionSelected: 0,
    })
  }

  async getSics(userId: number): Promise<void> {
    const sicsResult = this.props.isFinding
      ? await distributorGetSicsForInitialFinding(userId)
      : await distributorGetSicsForInitialTagging(userId)

    const sics = sicsResult ? sicsResult.data : []
    const sicCount = sics.reduce(
      (accumulator, sic) => accumulator + sic.sicCompanies,
      0
    )
    this.setTaskCountsOptions(sicCount)
    this.setState({
      sics: map(sics, sicNumber => ({
        value: sicNumber.sic,
        label: sicNumber.sic,
      })),
      selectedSicsCount: sicCount,
    })
  }

  handleTaskCountChange(selectedTaskCount: number): void {
    this.setState({ taskCountOptionSelected: selectedTaskCount })
  }

  async handleBatchChange(batchId: any): Promise<void> {
    if (Number(batchId) === 0) {
      this.setState({ selectedBatch: batchId })
      return this.setMaxCompsByCountry(this.state.countries, true)
    }

    const { batches } = this.state
    const batchFound = batches.find(batch => batch.value === batchId)
    let newTotalCompanies: number =
      batchFound && batchFound.quantity ? batchFound.quantity : 0

    this.setTaskCountsOptions(newTotalCompanies)
    this.setState({
      selectedBatch: batchId,
    })
  }

  async handleSicChange(sic: string): Promise<void> {
    const sourceUserId = Number(this.state.sourceUserId)

    await this.getCountries(sourceUserId, sic)
    this.setState({ sic, domicileId: undefined })
  }

  disableButton(): void {
    this.setState({
      isSubmitting: true,
      btnDistributeText: btnLoadingText,
    })
  }

  async redistributeReserve(): Promise<void> {
    const {
      sourceUserId,
      destinationUserId,
      domicileId,
      selectedBatch,
      taskCountOptionSelected,
      sic,
    } = this.state

    const { isFinding } = this.props

    if (
      validateField(sourceUserId, 'source') &&
      validateField(destinationUserId, 'destination')
    ) {
      this.disableButton()
      const result = await redistributeReserveToUserDistributor({
        sourceUserId,
        destinationUserId,
        domicileId,
        sic,
        selectedBatch,
        taskCount: taskCountOptionSelected,
        isFinding,
      })

      if (result) {
        setSuccess(
          `Successfully Redistributed ${result.data.distributedCount} Tasks`
        )
        await this.getInitialData()
        this.setState({
          sic: undefined,
          isSubmitting: false,
          btnDistributeText,
          selectedSicsCount: 0,
        })
      }
    }
  }

  render(): JSX.Element {
    const {
      countries,
      sics,
      users,
      taskCountOptions,
      domicileId,
      taskCountOptionSelected,
      destinationUserId,
      sourceUserId,
      sic,
      isSubmitting,
      btnDistributeText,

      batches,
      selectedBatch,
    } = this.state

    const shouldDisableBatchesSelect = !!(
      (sic && Number(sic) !== 0) ||
      (domicileId && domicileId !== 0) ||
      !sourceUserId
    )

    return (
      <Col sm={12}>
        <SelectBox
          values={users}
          onSelect={({ value }) => this.handleUserChange(value, true)}
          formElementLabel="Source"
          initialLabel="Redistribute From"
          inline={true}
          selected={String(sourceUserId) || ''}
        />

        <SelectBox
          values={sics}
          onSelect={({ value }) => this.handleSicChange(value)}
          formElementLabel="SIC"
          initialLabel="Select SIC"
          inline={true}
          selected={sic || ''}
          disabled={!sourceUserId || selectedBatch !== 0}
        />

        <SelectBox
          values={countries}
          onSelect={({ value }) => this.handleCountryChange(value)}
          formElementLabel="Country Name"
          initialLabel="Select Country"
          inline={true}
          selected={String(domicileId) || ''}
          disabled={!sourceUserId || selectedBatch !== 0}
        />

        <SelectBox
          values={batches}
          onSelect={({ value }) =>
            this.handleBatchChange(value === '' ? 0 : value)
          }
          formElementLabel="Batch"
          initialLabel="Select Batch"
          inline={true}
          disabled={shouldDisableBatchesSelect}
          selected={String(selectedBatch) || ''}
        />

        <SelectBox
          values={users}
          onSelect={({ value }) => this.handleUserChange(value, false)}
          formElementLabel="Destination"
          initialLabel="Redistribute To"
          inline={true}
          selected={String(destinationUserId) || ''}
          disabled={!sourceUserId}
        />
        <SelectBox
          values={taskCountOptions}
          onSelect={({ value }) => this.handleTaskCountChange(value)}
          formElementLabel={`Number To Move (${taskCountOptionSelected})`}
          initialLabel={null}
          inline={true}
          selected={String(taskCountOptionSelected) || ''}
          disabled={!sourceUserId}
        />
        <p className="clear-all-selected" onClick={() => this.clearAll()}>
          Clear all
        </p>
        <Row className="justify-content-end">
          <Button
            className="btn btn-purple right"
            onClick={() => this.redistributeReserve()}
            disabled={isSubmitting}
          >
            <FontAwesomeIcon icon={faSave} /> {btnDistributeText}
          </Button>
        </Row>
      </Col>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedistributeQueueToUser)
