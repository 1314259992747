import React, { Component } from 'react'
import { Pagination } from 'react-bootstrap'
import './Pagination.css'

interface State {
  currentPage: number
  currentPaginationChunk: number
}

interface PaginationParams {
  offset: number
  limit: number
}

type Props = {
  numberOfItems: number
  onPaginationChange: (paginationParams: PaginationParams) => void
  paginationLimit: number
  itemsPerPage: number
}

type PaginationActions =
  | 'prev'
  | 'next'
  | 'first'
  | 'last'
  | 'stepBackward'
  | 'stepForward'

class DataPagination extends Component<Props, State> {
  static defaultProps = {
    paginationLimit: 10,
    itemsPerPage: 10,
  }

  state = {
    currentPage: 1,
    currentPaginationChunk: 0,
  }

  handlePagination = (pageNumber, action?: PaginationActions) => {
    const { onPaginationChange, itemsPerPage } = this.props

    if (action) {
      pageNumber = this.executePaginationAction(action)
    }

    const offset = (pageNumber - 1) * itemsPerPage

    onPaginationChange({ offset, limit: itemsPerPage })
    this.setState({ currentPage: pageNumber })
  }

  executePaginationAction = (action: PaginationActions) => {
    const { currentPage } = this.state

    const actions = {
      prev: () => this.prev(currentPage),
      next: () => this.next(currentPage),
      first: () => this.first(),
      last: () => this.last(),
      stepBackward: () => this.stepBackward(),
      stepForward: () => this.stepForward(),
    }

    return actions[action]()
  }

  next = currentPage => {
    const { paginationLimit } = this.props
    const lastPage = this.getNumberOfPages()

    if (currentPage === lastPage) {
      return currentPage
    }

    const nextPage = currentPage + 1

    const shouldGoToNextChunk = nextPage % paginationLimit === 1
    if (shouldGoToNextChunk) {
      this.setState(prevState => ({
        currentPaginationChunk: prevState.currentPaginationChunk + 1,
      }))
    }

    return nextPage
  }

  prev = currentPage => {
    const { paginationLimit } = this.props

    if (currentPage === 1) {
      return currentPage
    }

    const prevPage = currentPage - 1

    const shouldGoToPrevChunk = prevPage % paginationLimit === 0
    if (shouldGoToPrevChunk) {
      this.setState(prevState => ({
        currentPaginationChunk: prevState.currentPaginationChunk - 1,
      }))
    }

    return prevPage
  }

  first = () => {
    this.setState({ currentPaginationChunk: 0 })
    return 1
  }

  last = () => {
    const numberOfChunks = this.getNumberOfChunks()
    const lastPage = this.getNumberOfPages()

    this.setState({ currentPaginationChunk: numberOfChunks - 1 })
    return lastPage
  }

  stepBackward = () => {
    const { paginationLimit } = this.props
    const { currentPage } = this.state

    this.setState(prevState => ({
      currentPaginationChunk: prevState.currentPaginationChunk - 1,
    }))

    return currentPage - paginationLimit
  }

  stepForward = () => {
    const { currentPage, currentPaginationChunk } = this.state
    const { paginationLimit } = this.props
    const lastPage = this.getNumberOfPages()
    const lastChunk = this.getNumberOfChunks()

    this.setState(prevState => ({
      currentPaginationChunk: prevState.currentPaginationChunk + 1,
    }))

    const isNextChunkTheLastChunk = currentPaginationChunk + 1 === lastChunk - 1
    if (isNextChunkTheLastChunk) {
      return lastPage
    }

    return currentPage + paginationLimit
  }

  splitPaginationIntoChunks = () => {
    const { paginationLimit } = this.props

    const numberOfPages = this.getNumberOfPages()
    const numberOfChunks = this.getNumberOfChunks()

    const paginationItems = Array.from(
      { length: numberOfPages },
      (_, i) => i + 1
    )

    const paginationChunks = Array.from({ length: numberOfChunks }, () => {
      return paginationItems.splice(0, paginationLimit)
    })

    return paginationChunks
  }

  getNumberOfChunks = () => {
    const { itemsPerPage, paginationLimit } = this.props
    const { numberOfItems } = this.props

    const numberOfPages = Math.ceil(numberOfItems / itemsPerPage)
    const numberOfChunks = Math.ceil(numberOfPages / paginationLimit)

    return numberOfChunks
  }

  getNumberOfPages = () => {
    const { numberOfItems, itemsPerPage } = this.props
    const numberOfPages = Math.ceil(numberOfItems / itemsPerPage)

    return numberOfPages
  }

  renderStepBackward = () => {
    const { paginationLimit } = this.props
    const { currentPage } = this.state

    if (currentPage > paginationLimit) {
      return (
        <Pagination.Ellipsis
          onClick={() => this.handlePagination(null, 'stepBackward')}
        />
      )
    }
  }

  renderStepForward = () => {
    const { currentPaginationChunk } = this.state

    const numberOfChunks = this.getNumberOfChunks()

    const isCurrentChunkTheLastChunk =
      currentPaginationChunk + 1 === numberOfChunks
    if (!isCurrentChunkTheLastChunk) {
      return (
        <Pagination.Ellipsis
          onClick={() => this.handlePagination(null, 'stepForward')}
        />
      )
    }
  }

  renderPaginationItems = () => {
    const { currentPage, currentPaginationChunk } = this.state

    const paginationChunks = this.splitPaginationIntoChunks()
    const paginationChunkToShow = paginationChunks[currentPaginationChunk]

    const paginationItems = paginationChunkToShow.map(pageNumber => {
      const props = {
        active: currentPage === pageNumber,
        onClick: () => this.handlePagination(pageNumber),
        className: 'pagination-item',
      }

      return (
        <Pagination.Item key={pageNumber} {...props}>
          {pageNumber}
        </Pagination.Item>
      )
    })

    return paginationItems
  }

  render(): JSX.Element {
    return (
      <div className="pagination-wrapper">
        <Pagination className="pagination">
          <Pagination.First
            onClick={() => this.handlePagination(null, 'first')}
          />
          <Pagination.Prev
            onClick={() => this.handlePagination(null, 'prev')}
          />
          {this.renderStepBackward()}

          {this.renderPaginationItems()}

          {this.renderStepForward()}
          <Pagination.Next
            onClick={() => this.handlePagination(null, 'next')}
          />
          <Pagination.Last
            onClick={() => this.handlePagination(null, 'last')}
          />
        </Pagination>
      </div>
    )
  }
}

export default DataPagination
