import React, { Component } from 'react'
import {
  TaggersDailyProgressReport,
  TaggersDailyProgressReportFilter,
} from '../../../shared/models'
import moment from 'moment'

import { cancelActiveRequests } from '../../../services'
import DataRange from './components/date-picker/DataRangePicker'
import Stepper from './components/stepper/Stepper'
import Step from './components/stepper/components/Step'
import styles from './TaggersReportRepositoryOverTime.module.css'
import { taggersProgressReportOvertimeService } from '../../../services/taggers-progress-report-over-time'

interface State {
  reportsData: {
    reports: TaggersDailyProgressReport[]
    totalReportsFound: number
  }
  filterParams: TaggersDailyProgressReportFilter
  activeStep: Number
  response: any
}

class TaggersReportRepositoryOverTime extends Component<{}, State> {
  downloadRepoRef: React.RefObject<unknown>

  constructor(props) {
    super(props)
    this.state = {
      filterParams: {
        reportInitDate: null,
        reportFinalDate: null,
      },
      reportsData: {
        reports: [],
        totalReportsFound: 0,
      },
      activeStep: 1,
      response: [],
    }
    this.downloadRepoRef = React.createRef()
  }

  componentDidMount = async (): Promise<void> => {
    await cancelActiveRequests()
  }

  formatFilterRequest = () => {
    const { filterParams } = this.state
    const reportInitDate = filterParams.reportInitDate
    const reportFinalDate = filterParams.reportFinalDate

    return {
      reportInitDate,
      reportFinalDate,
    }
  }

  performFilterRequest = async () => {
    const payload = this.formatFilterRequest()
    const taggersDailyReportResult = await taggersProgressReportOvertimeService(
      payload
    )
    const data = window.URL.createObjectURL(
      new Blob([taggersDailyReportResult.data.csv])
    )
    this.setState({
      response: data,
      activeStep: 3,
    })
  }

  handleGenerateReport = async () => {
    await this.performFilterRequest()
  }

  private dateSelection = selectedDates => {
    return {
      initDate: moment(selectedDates.startDate)
        .utc()
        .format(),
      endDate: moment(selectedDates.endDate)
        .utc()
        .format(),
    }
  }

  handleReportDateSelection = selectedDates => {
    const { initDate, endDate } = this.dateSelection(selectedDates)
    this.setState({
      filterParams: {
        reportInitDate: initDate,
        reportFinalDate: endDate,
      },
      activeStep: 2,
    })
  }

  handleEvent = (eventToTrigger, eventParams) => {
    const events = {
      handleReportDateSelection: this.handleReportDateSelection,
      handleGenerateReport: this.handleGenerateReport,
    }

    return events[eventToTrigger](eventParams)
  }

  render(): JSX.Element {
    const { filterParams } = this.state

    return (
      <>
        <div className="">
          <Stepper isVertical>
            <Step
              indicator="1"
              title="Select Date Range"
              isActive={this.state.activeStep === 1}
              isComplete={this.state.activeStep > 1}
              onClick={() => this.setState({ activeStep: 1 })}
            >
              <DataRange
                dataRangeTitle="Report Date"
                startDate={filterParams.reportInitDate || undefined}
                endDate={filterParams.reportFinalDate || undefined}
                handleEvent={this.handleEvent}
                handleDateSelectionEvent="handleReportDateSelection"
              />
            </Step>
            <Step
              indicator="2"
              title="Generate Report"
              isActive={this.state.activeStep === 2}
              isComplete={this.state.activeStep > 2}
              onClick={() => this.setState({ activeStep: 2 })}
            >
              <button
                className={styles['generate-button']}
                onClick={() => this.handleEvent('handleGenerateReport', null)}
              >
                Generate
              </button>
            </Step>
            <Step
              indicator="3"
              title="Download Report"
              isActive={this.state.activeStep === 3}
            >
              <a
                className={styles['download-button']}
                href={this.state.response}
                download="report.csv"
                target="_blank"
                rel="noreferrer"
              >
                download
              </a>
            </Step>
          </Stepper>
        </div>
      </>
    )
  }
}

export default TaggersReportRepositoryOverTime
