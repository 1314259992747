import React, { Component } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Dropdown from './Dropdown'
import DataRange from './DataRangePicker'
import { AppModeEnum } from '../../../../shared/models'

interface State {}

interface Props {
  appMode: AppModeEnum
  handleEvent: Function
  filterParams: any
  filterData: any
  taggers: Array<any>
  reviewers: Array<any>
  lockedCompanies: Array<any>
}

class Filters extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderDropdownTooltip = () => {
    const { filterParams } = this.props
    if (filterParams.companySearch.length >= 3) {
      return <Tooltip id="hideTooltip" />
    }

    return (
      <Tooltip id="dropdownTooltip">
        Type at least <strong>3</strong> characters
      </Tooltip>
    )
  }

  render(): JSX.Element {
    const { handleEvent, filterParams, filterData } = this.props

    return (
      <div className="filter-button-container">
        <div className="filter-button-middle-container">
          <OverlayTrigger
            placement="bottom"
            trigger="focus"
            overlay={this.renderDropdownTooltip()}
          >
            <div>
              <Dropdown
                dropdownTitle="Company"
                dropdownData={filterData.companies}
                selectedItems={this.props.lockedCompanies}
                searchValue={filterParams.companySearch}
                onChange={compSelected =>
                  handleEvent('handleCompSelection', compSelected)
                }
                handleEvent={handleEvent}
                shouldShowLockIcon
                isMultiple
                isSearchable
                alignPopover="center"
                handleSearchEventName="handleCompSearch"
                bigger={true}
              />
            </div>
          </OverlayTrigger>

          {/* <div className="filter-button-middle-container"> */}
          <Dropdown
            dropdownTitle="Founder"
            dropdownData={this.props.taggers}
            selectedItems={filterParams.founders}
            searchValue={filterParams.founderSearch}
            onChange={compSelected =>
              handleEvent('handleFounderSelection', compSelected)
            }
            handleEvent={handleEvent}
            shouldShowLockIcon
            isMultiple
            isSearchable={false}
            alignPopover="center"
            handleSearchEventName="handleFounderSearch"
          />

          <DataRange
            dataRangeTitle="Found date"
            startDate={filterParams.foundInitDate}
            endDate={filterParams.foundFinalDate}
            handleEvent={handleEvent}
            handleDateSelectionEvent="handleFoundDate"
          />

          <Dropdown
            dropdownTitle="Tagger"
            dropdownData={this.props.taggers}
            selectedItems={filterParams.taggers}
            searchValue={filterParams.taggerSearch}
            onChange={compSelected =>
              handleEvent('handleTaggerSelection', compSelected)
            }
            handleEvent={handleEvent}
            shouldShowLockIcon
            isMultiple
            isSearchable={false}
            alignPopover="center"
            handleSearchEventName="handleTaggerSearch"
          />

          <DataRange
            dataRangeTitle="Tag date"
            startDate={filterParams.tagInitDate}
            endDate={filterParams.tagFinalDate}
            handleEvent={handleEvent}
            handleDateSelectionEvent="handleTagDate"
          />

          <Dropdown
            dropdownTitle="Tester"
            dropdownData={this.props.taggers}
            selectedItems={filterParams.testers}
            searchValue={filterParams.testerSearch}
            onChange={compSelected =>
              handleEvent('handleTesterSelection', compSelected)
            }
            handleEvent={handleEvent}
            shouldShowLockIcon
            isMultiple
            isSearchable={false}
            alignPopover="center"
            handleSearchEventName="handleTesterSearch"
          />

          <DataRange
            dataRangeTitle="Test date"
            startDate={filterParams.testInitDate}
            endDate={filterParams.testFinalDate}
            handleEvent={handleEvent}
            handleDateSelectionEvent="handleTestDate"
          />

          <Dropdown
            dropdownTitle="Reviewer"
            dropdownData={this.props.reviewers}
            selectedItems={filterParams.reviewers}
            searchValue={filterParams.reviewerSearch}
            onChange={compSelected =>
              handleEvent('handleReviewerSelection', compSelected)
            }
            handleEvent={handleEvent}
            shouldShowLockIcon
            isMultiple
            isSearchable={false}
            alignPopover="center"
            handleSearchEventName="handleReviewerSearch"
          />

          <DataRange
            dataRangeTitle="Review date"
            startDate={filterParams.reviewInitDate}
            endDate={filterParams.reviewFinalDate}
            handleEvent={handleEvent}
            handleDateSelectionEvent="handleReviewDate"
          />
        </div>
        <div className="filter-button-end-container">
          <div
            className="button"
            onClick={() => handleEvent('handleApplyFiltersClick')}
          >
            Apply Filter
          </div>

          <div
            className="button"
            onClick={() => handleEvent('handleResetFilterClick')}
          >
            Reset Filter
          </div>
        </div>
      </div>
    )
  }
}

export default Filters
