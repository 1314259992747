export const CompStateTypes = {
  'finding-unassigned': 'Finding - Unassigned',
  'finding-tagger-assigned': 'Finding - Assigned',
  'finding-tagger-done': 'Finding - Tagging Complete',
  'finding-tester-assigned': 'Finding - Finding Complete, Tester Assigned',
  'finding-tester-done': 'Finding - Testing Complete, Reviewer Unassigned',
  'finding-reviewer-assigned': 'Finding - Testing Complete, Reviewer Assigned',
  'finding-reviewer-discarded': 'Finding - Review Complete, Discarded',
  'tagging-unassigned': 'Tagging - Unassigned',
  'tagging-tagger-assigned': 'Tagging - Assigned',
  'tagging-tagger-done': 'Tagging - Tagging Complete',
  'tagging-tester-assigned': 'Tagging - Tester Assigned',
  'tagging-tester-done': 'Tagging - Testing Complete, Reviewer Unassigned',
  'tagging-reviewer-assigned': 'Tagging - Testing Complete, Reviewer Assigned',
  'tagging-reviewer-approved': 'Tagging - Review Complete, Reviewer Approved',
  'tagging-reviewer-discarded': 'Tagging - Review Complete, Reviewer Discarded',
  'tagging-final-reviewer-assigned': 'Tagging - Final Reviewer Assigned',
  'tagging-final-reviewer-approved': 'Tagging - Final Reviewer Approved',
  'tagging-final-reviewer-discarded': 'Tagging - Final Reviewer Discarded',
  'finished-tagging-process': 'Finished Tagging Process',
  discarded: 'Discarded',
}
