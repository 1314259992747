import { get, post } from '../base/base-http-service'

import {
  DistributeToManager,
  QueueTypeEnum,
  GetCountriesResponse,
  GetBatchResponse,
} from '../../shared/models'

interface Sics {
  sic: string
  sicCompanies: number
}
interface GetSicsResponse {
  data: Sics[]
}

interface GetBatchFromSource {
  companyType: string
  companyStatus: string
  userId: number
}

export const getCountriesByUserIdAndSicForInitialFinding = async (
  ownerId: number,
  sic: Number | null
): Promise<GetCountriesResponse> => {
  return get(
    `tasks/service-provider/${ownerId}/finding/${
      QueueTypeEnum.FindingInitial
    }/countries/${sic ? sic : ''}`
  )
}

export const getCountriesByUserIdAndSicForInitialTagging = async (
  ownerId: number,
  sic: Number | null
): Promise<GetCountriesResponse> => {
  return get(
    `tasks/service-provider/${ownerId}/tagging/${
      QueueTypeEnum.TaggingInitial
    }/countries/${sic ? sic : ''}`
  )
}

export const serviceProviderGetCountriesFromSystem = async (
  companyType,
  companyStatus
): Promise<GetCountriesResponse> => {
  return get(
    `distribution/fromSystem/${companyType}/${companyStatus}/countries`
  )
}

export const serviceProviderGetBatchesFromSource = async (
  data: GetBatchFromSource
): Promise<GetBatchResponse> => {
  return get(
    `redistribution/fromSource/${data.companyType}/${data.companyStatus}/${
      data.userId
    }/batches`
  )
}

export const serviceProviderGetBatchesFromSystem = async (
  companyType,
  companyStatus
): Promise<GetBatchResponse> => {
  return get(`distribution/fromSystem/${companyType}/${companyStatus}/batches`)
}

export const serviceProviderGetSicsFromSystem = async (
  companyType,
  companyStatus
): Promise<GetSicsResponse> => {
  return get(`distribution/fromSystem/${companyType}/${companyStatus}/sics`)
}

export const serviceProviderGetSicsByCountryFromSystem = async (
  companyType,
  companyStatus,
  domicileId
): Promise<GetSicsResponse> => {
  return get(
    `distribution/fromSystem/${companyType}/${companyStatus}/${domicileId}/sicsByCountry`
  )
}

export const getSicsForSourceFinding = async (
  sourceId: number
): Promise<GetSicsResponse> => {
  return get(
    `tasks/service-provider/${sourceId}/finding/${
      QueueTypeEnum.FindingInitial
    }/sics`
  )
}

export const getSicsForSourceTagging = async (
  sourceId: number
): Promise<GetSicsResponse> => {
  return get(
    `tasks/service-provider/${sourceId}/tagging/${
      QueueTypeEnum.TaggingInitial
    }/sics`
  )
}

// export const distributeReserveToUserServiceProvider = async ({
//   destinationUserId,
//   selectedSics,
//   taskCount,
//   isFinding,
//   domicileId,
//   selectedBatch,
// }) => {
//   return post(`distribution/service-provider`, {
//     destinationUserId,
//     selectedSics,
//     taskCount,
//     destinationType: isFinding
//       ? QueueTypeEnum.FindingInitial
//       : QueueTypeEnum.TaggingInitial,
//     domicile_id: domicileId,
//     selectedBatch,
//   })
// }

export const redistributeReserveToUserServiceProvider = async ({
  sourceUserId,
  destinationUserId,
  sic,
  selectedBatch,
  taskCount,
  isFinding,
  domicileId,
}) => {
  return post(`distribution/service-provider/${sourceUserId}`, {
    destinationUserId,
    sic,
    taskCount,
    selectedBatch,
    destinationType: isFinding
      ? QueueTypeEnum.FindingInitial
      : QueueTypeEnum.TaggingInitial,
    domicile_id: domicileId,
  })
}

const getQueueByReviewType = (
  type: DistributeToManager
): QueueTypeEnum | null => {
  switch (type) {
    case DistributeToManager.FindingReviewToManagerFindingReview:
      return QueueTypeEnum.FindingReview
    case DistributeToManager.TaggingReviewToManagerTaggingReview:
      return QueueTypeEnum.TaggingReview
    case DistributeToManager.FinalReviewToManagerFinalReview:
      return QueueTypeEnum.TaggingFinalReview
    default:
      return null
  }
}

export const distributeReserveToManagerServiceProvider = async ({
  destinationUserId,
  taskCount,
  type,
}) => {
  return post(`distribution/fromSystemToManager/service-provider`, {
    destinationUserId,
    taskCount,
    destinationType: getQueueByReviewType(type),
  })
}

export const redistributeReserveToManagerServiceProvider = async ({
  sourceUserId,
  destinationUserId,
  taskCount,
  domicileId,
  selectedBatch,
  sic,
  type,
}) => {
  return post(
    `distribution/fromManagerToManager/service-provider/${sourceUserId}`,
    {
      destinationUserId,
      taskCount,
      destinationType: getQueueByReviewType(type),
      domicile_id: domicileId,
      selectedBatch,
      sic,
    }
  )
}
