import {
  ADD_REQUEST,
  CANCEL_REQUESTS,
  AddRequest,
  CancelRequests,
  ActiveRequestsState,
} from '../types'

const initialState: ActiveRequestsState = {
  activeRequests: [],
}

export function activeRequestsReducer(
  state: ActiveRequestsState = initialState,
  action: AddRequest | CancelRequests
): ActiveRequestsState {
  switch (action.type) {
    case ADD_REQUEST: {
      return {
        ...state,
        activeRequests: [...state.activeRequests, action.payload.request],
      }
    }
    case CANCEL_REQUESTS: {
      return {
        ...state,
        activeRequests: [],
      }
    }
    default:
      return state
  }
}
