import { get, post, patch } from './base/base-http-service'

import { User } from '../shared/models'

interface UserPayload {
  firstName: string
  lastName: string
  username: string
  email: string
  role: string
  password?: string
}
interface GetRegularUserResponse {
  data: User[]
}

interface UserResponse {
  data: User
}

export const getRegularUsers = async (): Promise<GetRegularUserResponse> => {
  return get('users/regular')
}

export const getManagerUsers = async (): Promise<GetRegularUserResponse> => {
  return get('users/manager')
}

export const saveUser = async (
  user: UserPayload,
  userId: number | null = null
): Promise<UserResponse> => {
  if (userId) {
    return await patch('users', userId, user)
  }

  return await post('users', user)
}
