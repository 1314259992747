import React from 'react'
import styles from "./Stepper.module.css";

const Stepper = ( props ) => {
    const baseClass = `Stepper`
    const verticalClass = `${styles[`${props.isVertical ? 'Stepper--vertical' : ''}`]}`
    const inlineClass = `${styles[`${props.isInline ? 'Stepper--inline' : ''}`]}`
    return (
    <div
        className={`${baseClass} ${verticalClass} ${inlineClass}`}
    >
        {props.children}
    </div>)}

export default Stepper