import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/js/src/collapse.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import styles from './BatchProcessTable.module.css'
import { Batch, Header } from '../../../../../shared/models/batch'
import CollabsableRow from './components/CollabsableRow'

interface State {
  orderBy: string
}

interface Props {
  items: Batch[]
  headers: Header[]
  sort: (id: string, direction: string) => void
}

class BatchProcessTable extends Component<Props, State> {
  sortByHeader = (id, direction) => {
    if (direction === 'asc') {
      this.props.sort(id, 'desc')
    }
    if (direction === 'desc') {
      this.props.sort(id, 'none')
    }
    if (direction === 'none') {
      this.props.sort(id, 'asc')
    }
  }

  render(): JSX.Element {
    return (
      <>
        <Table striped bordered hover>
          <thead>
            <tr className={styles['batch-process-table__header']}>
              {this.props.headers.map(header => {
                const icon =
                  header.direction === 'asc' ? faCaretUp : faCaretDown
                const isNone = header.direction === 'none'
                return (
                  <th
                    key={header.id}
                    className={styles['batch-process-table__header-cell']}
                    onClick={() =>
                      this.sortByHeader(header.id, header.direction)
                    }
                  >
                    <span> {header.name} </span>
                    {!isNone && <FontAwesomeIcon icon={icon} />}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {this.props.items.map((item, index) => {
              return (
                <CollabsableRow
                  key={`batch-${index}`}
                  item={item}
                  index={index}
                />
              )
            })}
          </tbody>
        </Table>
      </>
    )
  }
}

export default BatchProcessTable
