import React, { Component } from 'react'
import { Badge } from 'react-bootstrap'
import { CompStateTypes } from '../../../../../../../shared/constants/comp-state-types'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/js/src/collapse.js'
import styles from './BatchDetails.module.css'
import { getBatchDetails } from '../../../../../../../services/batch-process-report-service'
import { BatchCompsCount } from '../../../../../../../shared/models/batch'

interface State {
  isLoaded: boolean
  details: BatchCompsCount[]
}

interface Props {
  primary_batch_id: string
  isOpen?: boolean
}

class BatchDetail extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      details: [],
      isLoaded: false,
    }
  }

  componentDidMount = async (): Promise<void> => {
    const batchDetails = await getBatchDetails(this.props.primary_batch_id)
    this.setState({
      details: batchDetails.data,
      isLoaded: true,
    })
  }

  render(): JSX.Element {
    return (
      <td colSpan={4}>
        <div className={styles['details-row']}>
          {this.state.details.map(compState => (
            <div key={compState.compStatus} className={styles['details-item']}>
              <span className="text-body">
                {CompStateTypes[compState.compStatus]}
              </span>
              <Badge variant="primary" className={styles['details-badge']}>
                {compState.compCount}
              </Badge>
            </div>
          ))}
          {this.state.details.length === 0 && this.state.isLoaded && (
            <span className={styles['details-no-data']}>
              Whoops! There is no data in here.
            </span>
          )}
        </div>
      </td>
    )
  }
}

export default BatchDetail
