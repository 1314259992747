import { get, patch } from './base/base-http-service'
import { FindingPayload, AppModeEnum } from '../shared/models'

export const getNextFindingTask = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return get(`${getBase(activeMode)}/finding/next`)
}

export const saveFinding = async (
  findingData: FindingPayload,
  taggingId: string,
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return patch(`${getBase(activeMode)}/finding`, taggingId, findingData)
}

export const getNextFindingReviewTask = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return get(`${getBase(activeMode)}/finding/review/next`)
}

export const saveFindingReview = async (
  payload: FindingPayload,
  taggingId: string,
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return patch(`${getBase(activeMode)}/finding/review`, taggingId, payload)
}

const getBase = (activeMode: AppModeEnum): string => {
  if (activeMode === AppModeEnum.Distributors) {
    return `tasks/distributor`
  } else if (activeMode === AppModeEnum.ServiceProviders) {
    return `tasks/service-provider`
  }
  return ''
}
