import React from 'react'
import { Form } from 'react-bootstrap'

import {ShowValueAddedEnum} from '../../shared/models'

interface Props {
  handleChange: Function
  label?: string
  selected?: ShowValueAddedEnum
  readonly?: boolean
}

const ValueAddedDistributor = ({
  label,
  handleChange,
  selected,
  readonly,
}: Props) => {
  return (
    <Form.Group>
      <Form.Label className="font-weight-bold">
        {label || 'Value Added Distributor'}
      </Form.Label>
      <Form.Group>
        <Form.Check
          id={`${ShowValueAddedEnum.No}-value-added-no-readonly-${readonly}`}
          disabled={readonly}
          type="radio"
          label="No"
          value={ShowValueAddedEnum.No}
          checked={Number(selected) === ShowValueAddedEnum.No}
          onChange={({ target: { value } }) => handleChange(value)}
        />
        <Form.Check
          id={`${ShowValueAddedEnum.No}-value-added-yes-readonly-${readonly}`}
          disabled={readonly}
          type="radio"
          label="Yes"
          value={ShowValueAddedEnum.Yes}
          checked={Number(selected) === ShowValueAddedEnum.Yes}
          onChange={({ target: { value } }) => handleChange(value)}
        />
      </Form.Group>
    </Form.Group>
  )
}

export default ValueAddedDistributor
