import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import Pagination from '../../../shared/pagination/Pagination'

import { AppModeEnum, Auditable } from '../../../../shared/models'
import moment from 'moment'

type Props = {
  auditData: {
    data: Auditable[]
    totalCompsFound: number
    lockedCompaniesData: Auditable[]
    lockedCompaniesSelected: []
  }
  handleEvent: (eventToTrigger: string, eventParams?: object) => void
  appMode: AppModeEnum
}

class AuditTrailTable extends Component<Props> {
  handlePagination = paginationParams => {
    const { handleEvent } = this.props

    handleEvent('handlePagination', paginationParams)
  }

  renderAuditableRows = () => {
    const { auditData } = this.props
    return auditData.data.map(this.renderRow)
  }

  formatDate = date => {
    if (
      date !== null &&
      typeof date !== 'undefined' &&
      date.length > 0 &&
      date !== 'Finding not required'
    ) {
      const d = new Date(date)
      return moment(d).format('MM-DD-YYYY HH:mm:ss')
    } else {
      return date
    }
  }
  renderRow = auditable => {
    return (
      <tr key={auditable.company_id} className="audit-trail-table-row">
        <td>{auditable.company_name}</td>
        <td>{auditable.importId}</td>
        <td>{auditable.version}</td>
        <td>{auditable.found_by}</td>
        <td>{this.formatDate(auditable.found_at)}</td>
        <td>{auditable.tagged_by}</td>
        <td>{this.formatDate(auditable.tagged_at)}</td>
        <td>{auditable.tested_by}</td>
        <td>{this.formatDate(auditable.tested_at)}</td>
        <td>{auditable.reviewed_by}</td>
        <td>{this.formatDate(auditable.reviewed_at)}</td>
      </tr>
    )
  }

  renderPagination = () => {
    const { auditData } = this.props

    if (auditData.data.length > 0) {
      return (
        <div className="audit-trail-pagination">
          <Pagination
            numberOfItems={auditData.totalCompsFound}
            onPaginationChange={this.handlePagination}
          />
        </div>
      )
    }
  }

  renderLastRow = () => {
    const { auditData } = this.props

    if (auditData.data.length === 0) {
      return (
        <tr className="table-last-row">
          <td colSpan={12}>
            Whoops! There is no data in here. Try applying some filters.
          </td>
        </tr>
      )
    }

    return (
      <tr className="table-last-row">
        <td>
          Number of records found:
          <span className="number-of-comps-listed">
            {auditData.totalCompsFound}
          </span>
        </td>
      </tr>
    )
  }

  renderLockedComparableRows = () => {
    const { auditData } = this.props
    if (auditData.lockedCompaniesData.length > 0)
      return (
        <>
          <tr className="table-last-row">
            <td colSpan={12}>Locked Companies</td>
          </tr>

          {auditData.lockedCompaniesData.map(comp => this.renderRow(comp))}

          {auditData.data.length !== 0 && (
            <tr className="table-last-row">
              <td colSpan={12}>Rest of the companies</td>
            </tr>
          )}
        </>
      )
  }

  render(): JSX.Element {
    return (
      <div className="container_table_audit_trail">
        <Table
          style={{ width: '100%' }}
          striped
          bordered
          responsive="lg"
          className="audit-trail-table"
        >
          <thead className="audit-trail-table-head">
            <tr>
              <th>Company</th>
              <th>Import</th>
              <th>Version</th>
              <th>Found by</th>
              <th>Found on</th>
              <th>Tagged by</th>
              <th>Tagged on</th>
              <th>Tested by</th>
              <th>Tested on</th>
              <th>Reviewed by</th>
              <th>Reviewed on</th>
            </tr>
          </thead>
          <tbody className="audit-trail-table-body">
            {this.renderLockedComparableRows()}
            {this.renderAuditableRows()}
            {this.renderLastRow()}
          </tbody>
        </Table>

        {this.renderPagination()}
      </div>
    )
  }
}

export default AuditTrailTable
