import React, { Component } from 'react'
import { connect } from 'react-redux'
import './ReportsRepository.css'
import { AppState } from '../../store'
import {AppModeEnum} from '../../shared/models'
import { SidebarActiveItem } from '../../shared/models'
import { updateSidebarActiveItem } from '../../store/actions'
import { reportsRepository, ReportTypesObject } from '../../shared/constants'
import { Form, Container, Col, Row } from 'react-bootstrap'
import { ReportTypesArray } from '../../shared/constants'
import CompendiumReportDistributor from './compendium-report-repository/distributor/CompendiumReportDistributor'
import CompendiumReportServiceProvider from './compendium-report-repository/service-provider/CompendiumReportRepositoryServiceProvider'
import TaggersDailyReportRepositoryServiceProvider from './taggers-daily-progress-report-repository/service-provider/TaggersDailyReportRepositoryServiceProvider'
import CountryStatisticsReportRepositoryServiceProvider from './country-statistics-report-repository/service-provider/CountryStatisticsReportRepositoryServiceProvider'
import BatchProcessRepository from './batch-process-repository/BatchProcessRepository'
import TaggersReportRepositoryOverTime from "./taggers-progress-over-time/TaggersReportRepositoryOverTime";

interface DispatchProps {
  updateSidebarActiveItem: (key: SidebarActiveItem) => void
}

interface State {
  reportPanelSelected: String
}

type Props = AppState & DispatchProps

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = dispatch => ({
  updateSidebarActiveItem: (key: SidebarActiveItem) => {
    dispatch(updateSidebarActiveItem(key))
  },
})

class ReportsRepository extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      reportPanelSelected: '',
    }
  }

  componentDidMount(): void {
    this.props.updateSidebarActiveItem(reportsRepository.eventKey)
  }

  setSelectedReportPanel(reportPanelSelected: string): void {
    this.setState({
      reportPanelSelected,
    })
  }

  render(): JSX.Element {
    const { reportPanelSelected } = this.state
    const {
      appMode: { activeMode },
    } = this.props
    const isCompendiumReport =
      reportPanelSelected === ReportTypesObject.compendium.name
    const isDailyTaggersReport =
      reportPanelSelected === ReportTypesObject.dailyTaggers.name
    const StadisticByContryReport =
      reportPanelSelected === ReportTypesObject.statisticsByCountry.name
    const BatchProgress =
      reportPanelSelected === ReportTypesObject.batchProgress.name
    const TaggersReportOverTime =
      reportPanelSelected === ReportTypesObject.taggersProcessOvertime.name
    const isDistributor = activeMode === AppModeEnum.Distributors

    let ReportComponent: any = () => <span>Please Select a Report</span>

    if (isCompendiumReport && isDistributor) {
      ReportComponent = CompendiumReportDistributor
    }

    if (isCompendiumReport && !isDistributor) {
      ReportComponent = CompendiumReportServiceProvider
    }

    if (isDailyTaggersReport) {
      ReportComponent = TaggersDailyReportRepositoryServiceProvider
    }

    if (StadisticByContryReport) {
      ReportComponent = CountryStatisticsReportRepositoryServiceProvider
    }

    if (BatchProgress) {
      ReportComponent = BatchProcessRepository
    }

    if (TaggersReportOverTime) {
      ReportComponent = TaggersReportRepositoryOverTime
    }

    return (
      <div className="form-container">
        <Container fluid className="margin-top-75 padding-bottom-30">
          <Row>
            {ReportTypesArray.map(reportType => {
              return (
                <div
                  key={`dowloadRepositoryServiceProviderOptionContainer${
                    reportType.name
                  }`}
                >
                  <Col>
                    <Form.Check
                      id={`dowloadRepositoryServiceProviderOption${
                          reportType.name
                      }`}
                      checked={reportPanelSelected === reportType.name}
                      disabled={false}
                      type="radio"
                      label={reportType.label}
                      key={`dowloadRepositoryServiceProviderOption${
                        reportType.name
                      }`}
                      value={reportType.name}
                      onChange={({ target: { value } }) =>
                        this.setSelectedReportPanel(value)
                      }
                    />
                  </Col>
                </div>
              )
            })}
            <Col className="col-6" />
          </Row>
          <hr />
          <Row>
            <Col className="col-12">
              <ReportComponent />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsRepository)
