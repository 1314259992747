import { find, filter, cloneDeep, each, flattenDeep, map } from 'lodash'

import {
  SET_CLASSIFICATIONS,
  CLEAR_CLASSIFICATIONS,
  SET_ALL_CLASSIFICATIONS,
  AvailableMinorClassification,
  SET_AVAILABLE_MINOR_CLASSIFICATIONS,
  SET_SELECTED_MINOR_CLASSIFICATIONS,
} from '../types'
import { store } from '../index'
import { MajorClassification } from '../../shared/models'

export const setAllClassifications = (
  allClassifications: MajorClassification[]
) => {
  store.dispatch({
    type: SET_ALL_CLASSIFICATIONS,
    payload: allClassifications,
  })
}

const setAvailableMinorClassifications = () => {
  const availableMinorClassifications: AvailableMinorClassification[] = []
  const {
    classifications: {
      selected,
      allClassifications,
      selectedMinorClassifications,
    },
  } = store.getState()

  each(selected, ({ classificationNumber }) => {
    const majorClassification = find(allClassifications, {
      classificationNumber,
    }) as MajorClassification
    each(majorClassification.minorClassifications, c => {
      availableMinorClassifications.push({
        minorClassificationNumber: c.classificationNumber,
        classificationNumber: classificationNumber,
        name: c.name,
      })
    })
  })

  store.dispatch({
    type: SET_AVAILABLE_MINOR_CLASSIFICATIONS,
    payload: filter(
      availableMinorClassifications,
      ({ minorClassificationNumber }) =>
        !find(selectedMinorClassifications, { minorClassificationNumber })
    ),
  })
}

export const setMajorClassification = (
  classificationNumber: number,
  doneBy?
) => {
  const {
    classifications: {
      selected,
      allClassifications,
      selectedMinorClassifications,
    },
  } = store.getState()
  const classification = find(selected, { classificationNumber })
  const { name } = find(allClassifications, {
    classificationNumber,
  }) as MajorClassification
  if (classification) {
    store.dispatch({
      type: SET_CLASSIFICATIONS,
      payload: filter(
        selected,
        c => c.classificationNumber !== classificationNumber
      ),
    })

    store.dispatch({
      type: SET_SELECTED_MINOR_CLASSIFICATIONS,
      payload: filter(
        selectedMinorClassifications,
        ({ classificationNumber }) =>
          classificationNumber !== classification.classificationNumber
      ),
    })
  } else {
    store.dispatch({
      type: SET_CLASSIFICATIONS,
      payload: [
        ...selected,
        {
          classificationNumber,
          name,
          selectedMinorClassifications: [],
          doneBy,
        },
      ],
    })
  }

  setAvailableMinorClassifications()
}

const setSelectedMinorClassifications = () => {
  const {
    classifications: { selected },
  } = store.getState()
  const allSelectedMinorClassifications = flattenDeep(
    map(
      selected,
      ({ selectedMinorClassifications }) => selectedMinorClassifications
    )
  )

  store.dispatch({
    type: SET_SELECTED_MINOR_CLASSIFICATIONS,
    payload: allSelectedMinorClassifications,
  })
}

export const setMinorClassifications = (
  majorClassificationNumber: number,
  minorClassification: number,
  doneBy?: string
) => {
  const {
    classifications: { selected, availableMinorClassifications },
  } = store.getState()
  // clone deep to prevent store state mutation
  const selectedClassifications = cloneDeep(selected)

  const classification = find(selectedClassifications, {
    classificationNumber: majorClassificationNumber,
  })

  if (classification) {
    if (
      find(classification.selectedMinorClassifications, {
        minorClassificationNumber: minorClassification,
      })
    ) {
      classification.selectedMinorClassifications = filter(
        classification.selectedMinorClassifications,
        ({ minorClassificationNumber }) =>
          minorClassificationNumber !== minorClassification
      )
    } else {
      classification.selectedMinorClassifications.push({
        ...find(availableMinorClassifications, {
          minorClassificationNumber: minorClassification,
        }),
        ...{ doneBy },
      } as AvailableMinorClassification)
    }

    store.dispatch({
      type: SET_CLASSIFICATIONS,
      payload: selectedClassifications,
    })

    setSelectedMinorClassifications()
    setAvailableMinorClassifications()
  }
}

export const clearClassifications = () => {
  store.dispatch({
    type: CLEAR_CLASSIFICATIONS,
  })
}
