import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { map } from 'lodash'
import { Container, Row, Col } from 'react-bootstrap'

import AuthProtectedRoute from './components/core/AuthProtectedRoute'
import Login from './components/login/Login'

import { routes } from './shared/constants'

import { AuthService, checkIfTokensExists } from './services'

import './App.css'
import Loader from './components/loader/Loader'
import ModeSelection from './components/mode-selection/ModeSelection'

import Header from './components/main/header/Header'
import Sidebar from './components/main/sidebar/Sidebar'
import BreadcrumbNavigation from './components/main/breadcrumb/BreadcrumbNavigation'
import ErrorBanner from './components/main/error-banner/ErrorBanner'
import NoData from './components/main/no-data/NoData'
import SuccessBanner from './components/main/success-banner/SuccessBanner'
import AppModeChecker from './components/app-mode-checker/AppModeChecker'

const authService = AuthService.getInstance()

class App extends Component {
  async componentDidMount() {
    if (checkIfTokensExists()) {
      if (!authService.isUserInfoAvailable()) {
        try {
          const userInfo = await authService.getSessionFromAPI()
          localStorage.setItem('user', JSON.stringify(userInfo.data))
        } catch (error) {
          throw error
        }
      }
    }
  }

  render(): JSX.Element {
    return (
      <BrowserRouter>
        <div>
          <Loader />
          <Route path="/login" component={Login} />
          <Route path="/mode" component={ModeSelection} />
          <Header />
          <Container id="main-container" fluid>
            <Row>
              <Col sm={2} lg={2} md={2} xl={2} className="sidebar no-padding">
                <Sidebar />
              </Col>
              <Col sm={10} lg={10} md={10} xl={10} className="no-padding">
                <Container fluid className="form-container">
                  <AppModeChecker />
                  <BreadcrumbNavigation />
                  <ErrorBanner />
                  <SuccessBanner />
                  <NoData />
                  {map(routes, (route, index) => (
                    <AuthProtectedRoute {...route} key={index} />
                  ))}
                </Container>
              </Col>
            </Row>
          </Container>
        </div>
      </BrowserRouter>
    )
  }
}

export default App
