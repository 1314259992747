import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { Container, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'

import { AppState } from '../../../store'

import {
  faClipboardCheck,
  faArrowRight,
  faCheck,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { NoDataDisplayStateEnum } from '../../../shared/models'
import {
  userTaggingRoute,
  userFindingRoute,
  findingNoDataActions,
  defaultActions,
  taggingNoDataActions,
  taggingReviewRoute,
  findingReviewNoDataActions,
  taggingReviewNoDataActions,
  finalReviewNoDataActions,
  NoDataPageAction,
  findingReviewRoute,
  finalReviewRoute,
} from '../../../shared/constants'

import './NoData.css'

interface State {}

interface DispatchProps {}

type Props = AppState & DispatchProps

const mapStateToProps = (state: AppState) => ({ ...state })
const mapDispatchToProps = () => ({})

class NoData extends Component<Props, State> {
  getPageActions(page): NoDataPageAction {
    switch (page) {
      case userFindingRoute.eventKey:
        return findingNoDataActions
      case userTaggingRoute.eventKey:
        return taggingNoDataActions
      case findingReviewRoute.eventKey:
        return findingReviewNoDataActions
      case taggingReviewRoute.eventKey:
        return taggingReviewNoDataActions
      case finalReviewRoute.eventKey:
        return finalReviewNoDataActions
      default:
        return defaultActions
    }
  }

  render(): JSX.Element {
    const {
      noData: { state, page },
    } = this.props

    if (state === NoDataDisplayStateEnum.Hide) {
      return <div />
    }

    const {
      next,
      finish,
      nextActionLabel,
      finishActionLabel,
    } = this.getPageActions(page)

    return (
      <Container
        fluid
        className=" align-middle text-center"
        id="no-data-container"
      >
        <FontAwesomeIcon className="xl margin-top-75" icon={faClipboardCheck} />
        <h3 className="align-middle margin-top-50">
          There is no more data to process.
        </h3>
        <Row className="margin-top-75">
          <Col sm={2} />
          <Col sm={4}>
            <Link to={finish} className="btn btn-purple finish">
              <FontAwesomeIcon icon={faCheck} /> {finishActionLabel}
            </Link>
          </Col>
          <Col sm={4}>
            <Link to={next} className="btn btn-purple next">
              <FontAwesomeIcon icon={faArrowRight} /> {nextActionLabel}
            </Link>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoData)
