import axios, { AxiosResponse } from 'axios'

import {
  getValueForKeyInLocalStorage,
  clearLocalStorage,
} from '../local-storage-service'
import { idToken } from '../../shared/constants'

import {
  clearErrors,
  setErrors,
  decreaseNumberOfActiveRequests,
  increaseNumberOfActiveRequests,
} from '../../store/actions'

import { ADD_REQUEST, CANCEL_REQUESTS } from '../../store/types'
import { store } from '../../store'
import { getEnvironment } from '../auth-service'

axios.interceptors.request.use(req => {
  const token = getValueForKeyInLocalStorage(idToken)
  req.headers['Authorization'] = `Bearer ${token}`
  clearErrors()
  increaseNumberOfActiveRequests()
  return req
})

axios.interceptors.response.use(
  response => {
    decreaseNumberOfActiveRequests()
    return response.data
  },
  ({ response }) => handleError(response)
)

const addActiveRequest = cancelSource => {
  store.dispatch({
    type: ADD_REQUEST,
    payload: { request: cancelSource },
  })
}

const clearRequests = () => {
  store.dispatch({
    type: CANCEL_REQUESTS,
  })
}

const handleError = (errResponse: AxiosResponse): void => {
  decreaseNumberOfActiveRequests()

  if (!errResponse) {
    // TODO handle server unavailable
    return
  }

  switch (errResponse.status) {
    case 401:
      return clearLocalStorage()
    default:
      setErrors(errResponse.data)
      throw errResponse
  }
}

const getApiUrl = async (): Promise<string | null> => {
  const environment = await getEnvironment()
  if (environment) {
    return environment.API_URL
  }
  if (localStorage.getItem('API_URL')) {
    return localStorage.getItem('API_URL')
  }
  return ''
}

const request = (method, url, source) => {
  return axios({ url, method, timeout: 600000, cancelToken: source.token })
}

export const list = async (url: string) => {
  return await axios.get(url)
}

export const get = async (url: string, id?: number) => {
  let api = await getApiUrl()
  const source = axios.CancelToken.source()
  addActiveRequest(source)
  const apiUrl = id ? `${api}${url}/${id}` : `${api}${url}`
  return await request('get', apiUrl, source)
}

export const post = async (url: string, payload: any) => {
  const apiUrl = await getApiUrl()
  const source = axios.CancelToken.source()
  addActiveRequest(source)
  return await axios.post(`${apiUrl}${url}`, payload, {
    timeout: 600000,
    cancelToken: source.token,
  })
}

export const put = async (url: string, payload: any) => {
  const apiUrl = await getApiUrl()
  const source = axios.CancelToken.source()
  addActiveRequest(source)
  return await axios.put(`${apiUrl}${url}`, payload, {
    timeout: 600000,
    cancelToken: source.token,
  })
}

export const patch = async (url: string, id: number | string, payload: any) => {
  const apiUrl = await getApiUrl()
  const source = axios.CancelToken.source()
  addActiveRequest(source)
  return await axios.patch(`${apiUrl}${url}/${id}`, payload, {
    timeout: 600000,
    cancelToken: source.token,
  })
}

export const remove = async (url: string, id: number) => {
  const apiUrl = await getApiUrl()
  const source = axios.CancelToken.source()
  addActiveRequest(source)
  return await axios.delete(`${apiUrl}${url}/${id}`, {
    timeout: 600000,
    cancelToken: source.token,
  })
}

export const cancelActiveRequests = async () => {
  const state = store.getState()
  for (const cancelToken of state.activeRequests.activeRequests) {
    await cancelToken.cancel()
  }
  clearRequests()
}
