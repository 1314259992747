export const storeInLocalStorage = (key: string, value: any): void => {
  try {
    localStorage.setItem(key, value)
  } catch (error) {
    localStorage.setItem(key, value)
  }
}

export const getValueForKeyInLocalStorage = (
  key: string
): string | number | boolean | object | null => {
  try {
    return JSON.parse(localStorage.getItem(key) as string)
  } catch (error) {
    return localStorage.getItem(key)
  }
}

export const removeFromLocalStorage = (key: string): void => {
  localStorage.removeItem(key)
}

export const clearLocalStorage = (): void => {
  localStorage.clear()
}
