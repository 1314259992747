import {
  homeRoute,
  userTaggingRoute,
  userFindingRoute,
  taggingReviewRoute,
  findingReviewRoute,
} from './routes'

export interface NoDataPageAction {
  finish: string
  next: string
  finishActionLabel: string
  nextActionLabel: string
}

export const findingNoDataActions: NoDataPageAction = {
  finish: homeRoute.path,
  next: userTaggingRoute.path,
  finishActionLabel: 'Finish Work',
  nextActionLabel: 'Go To Tagging Screen',
}

export const taggingNoDataActions: NoDataPageAction = {
  finish: homeRoute.path,
  next: userFindingRoute.path,
  finishActionLabel: 'Finish Work',
  nextActionLabel: 'Go To Finding Screen',
}

export const findingReviewNoDataActions: NoDataPageAction = {
  finish: homeRoute.path,
  next: taggingReviewRoute.path,
  finishActionLabel: 'Finish Work',
  nextActionLabel: 'Go To Tagging Review Screen',
}

export const taggingReviewNoDataActions: NoDataPageAction = {
  finish: homeRoute.path,
  next: findingReviewRoute.path,
  finishActionLabel: 'Finish Work',
  nextActionLabel: 'Go To Finding Review Screen',
}

export const finalReviewNoDataActions: NoDataPageAction = {
  ...findingReviewNoDataActions,
}

export const defaultActions: NoDataPageAction = {
  finish: '',
  next: '',
  finishActionLabel: '',
  nextActionLabel: '',
}
