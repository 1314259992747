import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Container, Form, Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import DiscardReason from '../../shared/DiscardReason'
import CompanyInfoDistributor from '../../shared/company-info/distributor/CompanyInfoDistributor'
import { btnSaveText, btnLoadingText } from '../../../shared/constants'

import {
  getNextFindingTask,
  saveFinding,
  validateWebsitesUrlFormat,
  validateDiscardReason,
  cancelActiveRequests,
} from '../../../services'

import { userFindingRoute } from '../../../shared/constants'
import {
  SidebarActiveItem,
  FrontEndError,
  Company,
  DistributorDiscardReasonEnum,
  NoDataDisplayStateEnum,
  distributorFindingDiscardReasons,
  AppModeEnum,
} from '../../../shared/models'

import {
  updateSidebarActiveItem,
  setErrors,
  toggleNoDataPage,
} from '../../../store/actions'
import { NoDataState } from '../../../store/types'
import {
  getWebSocket,
  sendMessage,
} from '../../../services/base/websocket-service'

interface State {
  website: string
  discardReason?: DistributorDiscardReasonEnum
  company?: Company
  companyId: string
  isSubmitting: boolean
  btnSaveText: string
  taggingId: string
}

interface DispatchProps {
  updateSidebarActiveItem: (key: SidebarActiveItem) => void
  toggleNoDataPageDisplayState: (state: NoDataDisplayStateEnum) => void
  handleEvent: (eventToTrigger: string, eventParams?: object) => void
  modalOpen: boolean
}

interface StateProps {
  noData: NoDataState
}

type Props = StateProps & DispatchProps

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = dispatch => ({
  updateSidebarActiveItem: (key: SidebarActiveItem) => {
    dispatch(updateSidebarActiveItem(key))
  },
  toggleNoDataPageDisplayState: (state: NoDataDisplayStateEnum) => {
    dispatch(toggleNoDataPage(userFindingRoute.eventKey, state))
  },
})

class UserFindingDistributor extends Component<Props, State> {
  ws: WebSocket | null
  constructor(props) {
    super(props)
    this.ws = null
    this.state = {
      website: '',
      company: {} as Company,
      isSubmitting: false,
      btnSaveText,
      taggingId: '',
      companyId: '',
    }
  }

  async componentDidMount(): Promise<void> {
    await cancelActiveRequests()
    this.props.updateSidebarActiveItem(userFindingRoute.eventKey)
    const user = JSON.parse(localStorage.getItem('user') as string)
    this.ws = await getWebSocket(
      `refresh?userId=${user.userId}&section=userFinding&comp_type=distributor`
    )
    if (this.ws) {
      this.ws.onmessage = () => {
        this.reload()
      }
    }
    const companyResult = await getNextFindingTask(AppModeEnum.Distributors)
    const company = companyResult ? companyResult.data : {}
    if (!company) {
      this.props.toggleNoDataPageDisplayState(NoDataDisplayStateEnum.Show)
    } else {
      this.setState({
        company,
        taggingId: company.taggingId,
        companyId: company.companyId,
      })
    }
  }

  componentWillUnmount(): void {
    this.props.toggleNoDataPageDisplayState(NoDataDisplayStateEnum.Hide)
    if (this.ws) {
      this.ws.close()
    }
  }

  componentDidUpdate(prevProps): void {
    if (prevProps.modalOpen !== this.props.modalOpen) {
      this.setState({ isSubmitting: this.props.modalOpen })
    }
  }

  setWebsite({ target: { value: website } }): void {
    this.setState({
      website,
    })
  }

  setDiscardReason(discardReason: number): void {
    this.setState({
      discardReason,
    })
  }

  validateWebsite(): boolean {
    return !!validateWebsitesUrlFormat(this.state.website)
  }

  validateDiscardReason(): boolean {
    if (!validateDiscardReason(this.state.discardReason)) {
      setErrors(
        new FrontEndError('discardReason', 'Discard Reason is required.')
      )
      return false
    }

    return true
  }

  disableButton(): void {
    this.setState({
      isSubmitting: true,
      btnSaveText: btnLoadingText,
    })
  }

  async reload(): Promise<void> {
    const companyResult = await getNextFindingTask(AppModeEnum.Distributors)
    const company = companyResult ? companyResult.data : null
    if (!company) {
      this.props.toggleNoDataPageDisplayState(NoDataDisplayStateEnum.Show)
    } else {
      this.setState({
        taggingId: company.taggingId,
        companyId: company.companyId,
        company,
        website: '',
        discardReason: undefined,
        isSubmitting: false,
        btnSaveText,
      })
    }
  }

  async saveAndNext(): Promise<void> {
    if (!this.validateDiscardReason()) {
      return
    }

    const { website, discardReason, companyId, taggingId } = this.state
    const hasValidDiscardReasonForWebsiteUrlValidation =
      !!(
        String(discardReason) ===
        String(DistributorDiscardReasonEnum.NotDiscarded)
      ) ||
      !!(
        String(discardReason) ===
        String(DistributorDiscardReasonEnum.IsASubsidiary)
      )

    if (
      hasValidDiscardReasonForWebsiteUrlValidation &&
      !this.validateWebsite()
    ) {
      this.props.handleEvent('handleToggleModalInvalidWebsiteUrl')
      return
    }

    this.disableButton()

    const result = await saveFinding(
      { website, discardReason, companyId },
      taggingId,
      AppModeEnum.Distributors
    )
    if (result) {
      await this.reload()
      if (this.ws) {
        sendMessage(this.ws, {
          newTask: true,
        })
      }
    }
  }

  render(): JSX.Element {
    const {
      noData: { state },
    } = this.props

    if (state === NoDataDisplayStateEnum.Show) {
      return <div />
    }

    const {
      company,
      website,
      discardReason,
      isSubmitting,
      btnSaveText,
    } = this.state

    if (!company || !company.identifier) {
      return <div />
    }

    return (
      <Form className="form-container">
        <Container fluid className="margin-top-75 padding-bottom-30">
          <Row>
            <Col sm={9}>
              <CompanyInfoDistributor
                company={company || {}}
                isFinding={true}
              />
              <Container fluid>
                <Form.Group as={Row}>
                  <Col sm={2}>
                    <Form.Label>XBS Website</Form.Label>
                  </Col>

                  <Col sm={10}>
                    <Form.Control
                      onChange={event => this.setWebsite(event)}
                      type="text"
                      placeholder="XBS Website"
                      value={website}
                    />
                  </Col>
                </Form.Group>
              </Container>
            </Col>
            <Col sm={3}>
              <DiscardReason
                discardReasons={distributorFindingDiscardReasons}
                selected={discardReason}
                handleChange={reason => this.setDiscardReason(reason)}
              />
              <hr />
              <Button
                className="btn btn-purple btn-full-width"
                onClick={() => this.saveAndNext()}
                disabled={isSubmitting}
              >
                <FontAwesomeIcon icon={faArrowRight} /> {btnSaveText}
              </Button>
            </Col>
          </Row>
          <hr />
        </Container>
      </Form>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserFindingDistributor)
