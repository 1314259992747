import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { formatUrl } from '../../../../services'
import { SourceTypeDatabaseEnum } from '../../../../shared/models'

const CompanyInfoReviewDistributor = ({
  company,
  xbsWebsite = '',
  isFinding = false,
}) => (
  <div>
    <Row>
      <Col sm={4}>
        <Form.Group as={Row}>
          <Col sm={2}>
            <Form.Label>Country</Form.Label>
          </Col>
          <Col sm={10}>
            <p className="font-weight-bold">{company ? company.country : ''}</p>
          </Col>
        </Form.Group>
      </Col>
      <Col sm={8}>
        <Form.Group as={Row} className="col-sm-12">
          <Col sm={2}>
            <Form.Label>Company Name</Form.Label>
          </Col>
          <Col sm={3}>
            <p className="font-weight-bold">{company ? company.name : ''}</p>
          </Col>
          <Col sm={2}>
            <Form.Label>Identifier</Form.Label>
          </Col>
          <Col sm={2}>
            <p className="font-weight-bold">
              {company ? company.identifier : ''}
            </p>
          </Col>
          <Col sm={2}>
            <Form.Label>Identifier Source</Form.Label>
          </Col>
          <Col sm={1}>
            <p className="font-weight-bold">
              {company ? SourceTypeDatabaseEnum[company.identifierSource] : ''}
            </p>
          </Col>
        </Form.Group>
      </Col>
    </Row>
    <Row>
      {/* <Col sm={isFinding ? 4 : 6}>
        <Form.Group as={Row}>
          <Col sm={4}>
            <Form.Label>Website (DandB)</Form.Label>
          </Col>
          <Col sm={8}>
            <a
              className="font-weight-bold"
              href={
                company
                  ? company.url
                    ? formatUrl(company.url)
                    : formatUrl(testXbsWebsite)
                  : ''
              }
              target="_blank"
            >
              {company ? (company.url ? company.url : testXbsWebsite) : ''}
            </a>
          </Col>
        </Form.Group>
      </Col> */}
      {isFinding ? (
        <Col sm={12}>
          <Form.Group as={Row}>
            <Col sm={1} className="">
              <Form.Label>Description</Form.Label>
            </Col>
            <Col sm={11} className="scroll-box">
              <p className="font-weight-bold">
                {company ? company.description : ''}
              </p>
            </Col>
          </Form.Group>
        </Col>
      ) : (
        <Col sm={12}>
          <Form.Group as={Row}>
            <Col sm={4}>
              <Form.Label>Website (XBS)</Form.Label>
            </Col>
            <Col sm={8}>
              <a
                rel="noreferrer"
                className="font-weight-bold"
                target="_blank"
                href={formatUrl(xbsWebsite)}
              >
                {xbsWebsite}
              </a>
            </Col>
          </Form.Group>
        </Col>
      )}
    </Row>
  </div>
)

export default CompanyInfoReviewDistributor
