import React from 'react'
import { Row } from 'react-bootstrap'

const SystemQueueTable = ({ systemQueues }) => {
  return (
    <Row className="text-cente  col-sm-12r">
      <div className="col-sm-3" />
      <div className="col-sm-8">
        <table className="table table-striped">
          <tbody>
            <tr>
              <th className="align-middle padding-left-10" colSpan={3}>
                Finding Test
              </th>
              <td className="align-middle text-center">
                {systemQueues.finding_test}
              </td>
            </tr>
            <tr>
              <th className="align-middle padding-left-10" colSpan={3}>
                Tagging Test
              </th>
              <td className="align-middle text-center">
                {systemQueues.tagging_test}
              </td>
            </tr>
            <tr>
              <th className="align-middle padding-left-10" colSpan={3}>
                Find Review
              </th>
              <td className="align-middle text-center">
                {systemQueues.find_review}
              </td>
            </tr>
            <tr>
              <th className="align-middle padding-left-10" colSpan={3}>
                Tag Review
              </th>
              <td className="align-middle text-center">
                {systemQueues.tag_review}
              </td>
            </tr>
            <tr>
              <th className="align-middle padding-left-10" colSpan={3}>
                Final Review
              </th>
              <td className="align-middle text-center">
                {systemQueues.final_review}
              </td>
            </tr>
            <tr>
              <th className="align-middle padding-left-10" colSpan={3}>
                Find Reserve
              </th>
              <td className="align-middle text-center">
                {systemQueues.find_reserve}
              </td>
            </tr>
            <tr>
              <th className="align-middle padding-left-10" colSpan={3}>
                Tag Reserve
              </th>
              <td className="align-middle text-center">
                {systemQueues.tag_reserve}
              </td>
            </tr>
            <tr>
              <th className="align-middle padding-left-10" colSpan={3}>
                Finding Finished
              </th>
              <td className="align-middle text-center">
                {systemQueues.finding_finished}
              </td>
            </tr>
            <tr>
              <th className="align-middle padding-left-10" colSpan={3}>
                Finding Discarded
              </th>
              <td className="align-middle text-center">
                {systemQueues.finding_discarded}
              </td>
            </tr>
            <tr>
              <th className="align-middle padding-left-10" colSpan={3}>
                Tagging Finished
              </th>
              <td className="align-middle text-center">
                {systemQueues.tagging_finished}
              </td>
            </tr>
            <tr>
              <th className="align-middle padding-left-10" colSpan={3}>
                Tagging Discarded
              </th>
              <td className="align-middle text-center">
                {systemQueues.tagging_discarded}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Row>
  )
}

export default SystemQueueTable
