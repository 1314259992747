import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Modal as BoostrapModal, Table } from 'react-bootstrap'
import {
  AppModeEnum,
  DistributorDiscardReasonEnum,
  ServiceProviderDiscardReasonEnum,
  TaggingHistoryCompany,
  ShowValueAddedEnum,
} from '../../../../shared/models'
import moment from 'moment'
import { formatUrl } from '../../../../services'

interface Props {
  open: boolean
  handleEvent: (eventToTrigger: string, eventParams?: object) => void
  appMode: AppModeEnum
  taggingHistories: TaggingHistoryCompany
}

class Modal extends Component<Props> {
  renderTaggingHistories = () => {
    const { taggingHistories } = this.props
    return taggingHistories.history.map(history =>
      this.renderTaggingHistory(history)
    )
  }

  formatRevenueValue = revenue => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })

    return formatter.format(revenue)
  }

  renderTaggingHistory = history => {
    const date = moment(history.updatedAt).format('DD/MM/YY')

    return (
      <tr key={history.company_id} className="compendium-table-row">
        <td>{date}</td>
        <td>{history.importId}</td>
        {this.renderAppModeDependentHistoryInfo(history)}
      </tr>
    )
  }

  getMajor = (row, majorNumber: 1 | 2 | 3) => {
    const { majors } = row
    let major
    if (majors !== undefined) {
      major = majors[majorNumber - 1]
    }
    let majorName
    let minors
    if (majors === undefined || major === undefined) {
      majorName = ''
      minors = []
    } else {
      majorName = major.major
      minors = major.minors
    }

    return { name: majorName, minors }
  }

  renderAppModeDependentHistoryInfo = history => {
    const { appMode } = this.props
    const discardReason = history.discardReason || 0
    const majorOne = this.getMajor(history, 1)
    const majorTwo = this.getMajor(history, 2)
    const majorThree = this.getMajor(history, 3)

    if (appMode === AppModeEnum.ServiceProviders) {
      return (
        <>
          <td>{ServiceProviderDiscardReasonEnum[discardReason]}</td>
          <td>{majorOne.name}</td>
          <MinorClassificationTd minors={majorOne.minors} />
          <td>{majorTwo.name}</td>
          <MinorClassificationTd minors={majorTwo.minors} />
          <td>{majorThree.name}</td>
          <MinorClassificationTd minors={majorThree.minors} />
        </>
      )
    }

    if (appMode === AppModeEnum.Distributors) {
      const { showValueAdded } = history
      return (
        <>
          <td>{DistributorDiscardReasonEnum[discardReason]}</td>
          <td>{ShowValueAddedEnum[showValueAdded]}</td>
        </>
      )
    }
  }

  renderAppModeDependentHistoryHeaders = () => {
    const { appMode } = this.props

    if (appMode === AppModeEnum.ServiceProviders) {
      return (
        <>
          <th>Major 1</th>
          <th>Minors</th>
          <th>Major 2</th>
          <th>Minors</th>
          <th>Major 3</th>
          <th>Minors</th>
        </>
      )
    }

    if (appMode === AppModeEnum.Distributors) {
      return (
        <>
          <th>Show Value Added</th>
        </>
      )
    }
  }

  renderAppModeDependentInfo = () => {
    const { appMode } = this.props

    if (appMode === AppModeEnum.ServiceProviders) {
      return (
        <>
          {this.renderClassifications(1)}
          {this.renderClassifications(2)}
          {this.renderClassifications(3)}
        </>
      )
    }
  }

  renderClassifications = majorNumber => {
    const { taggingHistories } = this.props
    const { history } = taggingHistories
    if (history.length > 0) {
      const major = this.getMajor(history[0], majorNumber)
      return major.name !== '' ? (
        <div className="compendium-modal-classifications">
          <p>Major Classification {majorNumber}:</p>
          <ul>
            <li>
              {major.name}
              {major.minors.length !== 0 ? (
                <ul className="minor-classifications">
                  {major.minors.map(minor => (
                    <li key={minor}>{minor}</li>
                  ))}
                </ul>
              ) : null}
            </li>
          </ul>
        </div>
      ) : null
    }
  }

  renderDiscardExplanation = history => {
    if (history.length > 0) {
      const versions = history.map(e => e.version)
      const maxVersion = Math.max(...versions)
      const latestVersionItem = history.find(
        item => item.version === maxVersion
      )
      const isDiscarded =
        latestVersionItem.discardReason && latestVersionItem.discardExplanation
      return isDiscarded ? (
        <p>
          Discard Explanation:
          <span> {latestVersionItem.discardExplanation}</span>
        </p>
      ) : null
    }
    return null
  }

  render(): JSX.Element | null {
    const { taggingHistories } = this.props

    const { history } = taggingHistories
    const { handleEvent } = this.props

    return (
      <div>
        <BoostrapModal
          show={this.props.open}
          onHide={() => handleEvent('handleToggleModal')}
          size="lg"
          centered
          className="compendium-modal"
        >
          <BoostrapModal.Header closeButton>
            <BoostrapModal.Title>Comparable Information</BoostrapModal.Title>
          </BoostrapModal.Header>
          <BoostrapModal.Body>
            <div>
              <div className="compendium-modal-body">
                <div>
                  <p>
                    Comparable Name:
                    <span> {taggingHistories.compName}</span>
                  </p>
                  <p>
                    Comp Identifier Number:
                    <span> {taggingHistories.compIdentifier}</span>
                  </p>
                  {Object.keys(taggingHistories).length !== 0 &&
                    this.renderAppModeDependentInfo()}
                  <p>
                    Country:
                    <span> {taggingHistories.country}</span>
                  </p>
                  <p>
                    Primary SIC Code:
                    <span> {taggingHistories.sic}</span>
                  </p>
                  <p>
                    Database:
                    <span> {taggingHistories.sourceTypeName}</span>
                  </p>
                  <p>
                    Revenue:
                    <span>
                      {' '}
                      {this.formatRevenueValue(taggingHistories.revenue)}
                    </span>
                  </p>
                </div>
                <div className="modal-right-side">
                  <p>Data Provider Description</p>
                  <span>{taggingHistories.dataProvidedDescription}</span>
                  <p>Website Based Information</p>
                  <span>{taggingHistories.websiteBasedInfo}</span>
                  <p>Website</p>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={
                      taggingHistories.website
                        ? formatUrl(taggingHistories.website)
                        : ''
                    }
                  >
                    {taggingHistories.website ? taggingHistories.website : ''}
                  </a>
                  {this.renderDiscardExplanation(history)}
                  {this.props.appMode === AppModeEnum.Distributors && (
                    <>
                      <p>Show Value Added Description</p>
                      <span>{taggingHistories.showValueAddedDescription}</span>
                    </>
                  )}
                </div>
              </div>
              <div className="tagging-history">
                <p>Tagging History</p>
                <div className="compendium-modal-table-container">
                  <Table
                    striped
                    bordered
                    responsive="lg"
                    className="compendium-table modal-table"
                  >
                    <thead className="compendium-table-head">
                      <tr>
                        <th>Date</th>
                        <th>Import Id</th>
                        <th>Discard</th>
                        {this.renderAppModeDependentHistoryHeaders()}
                      </tr>
                    </thead>
                    <tbody>
                      {history.length > 0
                        ? this.renderTaggingHistories()
                        : null}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </BoostrapModal.Body>
          <BoostrapModal.Footer>
            <Button
              variant="primary"
              onClick={() => handleEvent('handleToggleModal')}
              className="compendium-button"
            >
              Close
            </Button>
          </BoostrapModal.Footer>
        </BoostrapModal>
      </div>
    )
  }
}

const MinorClassificationTd = ({ minors }: any) => (
  <td>
    {minors && minors.length > 0
      ? minors.map(minor => <p key={minor}>{minor}</p>)
      : null}
  </td>
)

export default Modal
