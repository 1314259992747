import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import DiscardReason from '../../shared/DiscardReason'
import ValueAddedDistributor from '../../shared/ValueAddedDistributor'
import CompanyInfoDistributor from '../../shared/company-info/distributor/CompanyInfoDistributor'
import {
  btnLoadingText,
  btnSaveText,
  userTaggingRoute,
} from '../../../shared/constants'
import {
  AppModeEnum,
  Company,
  DistributorDiscardReasonEnum,
  distributorTaggingDiscardReasons,
  FrontEndError,
  NoDataDisplayStateEnum,
  ShowValueAddedEnum,
  SidebarActiveItem,
} from '../../../shared/models'
import {
  setErrors,
  toggleNoDataPage,
  updateSidebarActiveItem,
} from '../../../store/actions'
import { AppState } from '../../../store'

import {
  cancelActiveRequests,
  getNextTaggingTask,
  saveTaggingTask,
  validateDiscardReason,
  validateShowValueAdded,
  validateStringLength,
} from '../../../services'
import {
  getWebSocket,
  sendMessage,
} from '../../../services/base/websocket-service'

interface State {
  description: string
  discardReason?: DistributorDiscardReasonEnum
  showValueAdded?: ShowValueAddedEnum
  showValueAddedDescription: string
  company: Company
  taskId?: number
  xbsWebsite?: string
  isSubmitting: boolean
  btnSaveText: string
  discardExplanation: string
}

interface DispatchProps {
  updateSidebarActiveItem: (key: SidebarActiveItem) => void
  toggleNoDataPageDisplayState: (state: NoDataDisplayStateEnum) => void
  handleEvent: (eventToTrigger: string, eventParams?: object) => void
}

type Props = AppState & DispatchProps

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = dispatch => ({
  updateSidebarActiveItem: (key: SidebarActiveItem) => {
    dispatch(updateSidebarActiveItem(key))
  },
  toggleNoDataPageDisplayState: (state: NoDataDisplayStateEnum) => {
    dispatch(toggleNoDataPage(userTaggingRoute.eventKey, state))
  },
})

class UserTaggingDistributor extends Component<Props, State> {
  ws: WebSocket | null
  constructor(props) {
    super(props)
    this.ws = null
    this.state = {
      description: '',
      showValueAddedDescription: '',
      company: {
        taggingId: '',
        identifier: '',
        identifierSource: '',
        name: '',
        country: '',
        city: '',
        postalCode: '',
        lineOfBusiness: '',
        description: '',
        url: '',
        sic: '',
        classifications: [],
      },
      isSubmitting: false,
      btnSaveText,
      discardExplanation: '',
    }
  }

  async componentDidMount(): Promise<void> {
    await cancelActiveRequests()
    this.props.updateSidebarActiveItem(userTaggingRoute.eventKey)
    const user = JSON.parse(localStorage.getItem('user') as string)
    this.ws = await getWebSocket(
      `refresh?userId=${user.userId}&section=userTagging&comp_type=distributors`
    )
    if (this.ws) {
      this.ws.onmessage = () => {
        this.reload()
      }
    }
    const taggingTaskResult = await getNextTaggingTask(AppModeEnum.Distributors)
    const taggingTask = taggingTaskResult ? taggingTaskResult.data : {}
    if (!taggingTask) {
      this.props.toggleNoDataPageDisplayState(NoDataDisplayStateEnum.Show)
    } else {
      this.setState({ company: taggingTask })
    }
  }

  componentWillUnmount(): void {
    this.props.toggleNoDataPageDisplayState(NoDataDisplayStateEnum.Hide)
    if (this.ws) {
      this.ws.close()
    }
  }

  setDescription({ target: { value: description } }): void {
    this.setState({
      description,
    })
  }

  setDiscardReason(discardReason: DistributorDiscardReasonEnum): void {
    this.setState({
      discardReason,
      showValueAdded: ShowValueAddedEnum.No,
      showValueAddedDescription: '',
    })
  }

  setDiscardExplanation({ target: { value: discardExplanation } }): void {
    this.setState({
      discardExplanation,
    })
  }

  setShowValueAdded(showValueAdded: ShowValueAddedEnum): void {
    this.setState({
      showValueAdded,
    })
  }

  setShowValueAddedDescription({
    target: { value: showValueAddedDescription },
  }): void {
    this.setState({
      showValueAddedDescription,
    })
  }

  validateDescription(): boolean {
    if (!validateStringLength(this.state.description, 1)) {
      setErrors(new FrontEndError('description', 'Description is required.'))
      return false
    }

    return true
  }

  validateValueAddedDistributor(): boolean {
    return Number(this.state.showValueAdded) === ShowValueAddedEnum.Yes
  }

  validateShowValueAddedDescription(): boolean {
    if (
      (typeof this.state.showValueAddedDescription === 'undefined' ||
        !validateStringLength(this.state.showValueAddedDescription, 1)) &&
      this.validateValueAddedDistributor()
    ) {
      setErrors(
        new FrontEndError(
          'showValueAddedDescription',
          'Show value added description is required.'
        )
      )
      return false
    }

    return true
  }

  validateDiscardReason(): boolean {
    if (!validateDiscardReason(this.state.discardReason)) {
      setErrors(
        new FrontEndError('discardReason', 'Discard Reason is required.')
      )
      return false
    }

    return true
  }

  validateDiscardExplanation(): boolean {
    if (
      Number(this.state.discardReason) !==
        DistributorDiscardReasonEnum.NotDiscarded &&
      (typeof this.state.discardExplanation === undefined ||
        !validateStringLength(this.state.discardExplanation, 1))
    ) {
      setErrors(
        new FrontEndError(
          'discardExplanation',
          'Discard Explanation is required.'
        )
      )
      return false
    }
    return true
  }

  validateShowValueAdded(): boolean {
    if (
      !validateShowValueAdded(this.state.showValueAdded) &&
      Number(this.state.discardReason) ===
        DistributorDiscardReasonEnum.NotDiscarded
    ) {
      setErrors(
        new FrontEndError('showValueAdded', 'Show Value Added is required.')
      )
      return false
    }
    return true
  }

  disableButton(): void {
    this.setState({
      isSubmitting: true,
      btnSaveText: btnLoadingText,
    })
  }

  async reload(): Promise<void> {
    const taggingTaskResult = await getNextTaggingTask(AppModeEnum.Distributors)
    const taggingTask = taggingTaskResult ? taggingTaskResult.data : null
    if (!taggingTask) {
      this.props.toggleNoDataPageDisplayState(NoDataDisplayStateEnum.Show)
    } else {
      this.setState({
        company: taggingTask,
        discardReason: undefined,
        discardExplanation: '',
        showValueAdded: undefined,
        description: '',
        showValueAddedDescription: '',
        isSubmitting: false,
        btnSaveText,
      })
    }
  }

  async saveAndNext(): Promise<void> {
    const {
      description,
      discardReason,
      discardExplanation,
      showValueAdded,
      showValueAddedDescription,
      company,
    } = this.state

    if (
      !this.validateDescription() ||
      !this.validateDiscardReason() ||
      !this.validateDiscardExplanation() ||
      !this.validateShowValueAdded() ||
      !this.validateShowValueAddedDescription()
    ) {
      return
    }

    this.disableButton()

    const result = await saveTaggingTask(
      {
        description,
        discardReason,
        discardExplanation,
        showValueAdded,
        showValueAddedDescription,
      },
      company.taggingId,
      AppModeEnum.Distributors
    )
    if (result) {
      await this.reload()
      if (this.ws) {
        sendMessage(this.ws, {
          newTask: true,
        })
      }
    }
  }

  render(): JSX.Element {
    const {
      noData: { state },
    } = this.props

    const {
      company,
      xbsWebsite,
      discardReason,
      showValueAdded,
      description,
      showValueAddedDescription,
      isSubmitting,
      btnSaveText,
      discardExplanation,
    } = this.state

    if (state === NoDataDisplayStateEnum.Show) {
      return <div />
    }

    if (!company || !company.identifier) {
      return <div />
    }

    return (
      <Form className="form-container">
        <Container fluid className="margin-top-75 padding-bottom-30">
          <Row className="align-middle">
            <Col sm={9}>
              <CompanyInfoDistributor
                company={company || {}}
                xbsWebsite={xbsWebsite}
                isFinding={false}
              />
            </Col>
            <Col sm={3}>
              <DiscardReason
                discardReasons={distributorTaggingDiscardReasons}
                selected={discardReason}
                handleChange={reason => this.setDiscardReason(reason)}
              />
              <Form.Group>
                <Form.Label className="font-weight-bold">
                  Discard Explanation
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="5"
                  placeholder="Discard Explanation"
                  value={discardExplanation}
                  onChange={event => this.setDiscardExplanation(event)}
                  onBlur={() => this.validateDiscardExplanation()}
                />
              </Form.Group>
              <ValueAddedDistributor
                readonly={
                  Number(discardReason) !==
                  DistributorDiscardReasonEnum.NotDiscarded
                }
                selected={showValueAdded}
                handleChange={showValueAdded =>
                  this.setShowValueAdded(showValueAdded)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm={9}>
              <Form.Group as={Row} className="tagging-xbs-description">
                <Col sm={2}>
                  <Form.Label>XBS Description</Form.Label>
                </Col>
                <Col sm={10}>
                  <Form.Control
                    as="textarea"
                    rows="5"
                    placeholder="XBS Description"
                    value={description}
                    onChange={event => this.setDescription(event)}
                    onBlur={() => this.validateDescription()}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <FormGroup>
                <Form.Label className="font-weight-bold">
                  Show Value Added
                </Form.Label>
                <Form.Control
                  disabled={Number(showValueAdded) !== ShowValueAddedEnum.Yes}
                  as="textarea"
                  rows="5"
                  placeholder="Show Value Added"
                  value={showValueAddedDescription}
                  onChange={event => this.setShowValueAddedDescription(event)}
                  onBlur={() => this.validateShowValueAddedDescription()}
                />
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <Row className="form-container-actions pr-3 justify-content-end">
            <Button
              className="btn btn-purple"
              onClick={() => this.saveAndNext()}
              disabled={isSubmitting}
            >
              <FontAwesomeIcon icon={faArrowRight} /> {btnSaveText}
            </Button>
          </Row>
        </Container>
      </Form>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTaggingDistributor)
