import { MajorClassification, MinorClassification } from '../../shared/models'

export const SET_CLASSIFICATIONS = 'SET_CLASSIFICATIONS'
export const SET_SELECTED_MINOR_CLASSIFICATIONS =
  'SET_SELECTED_MINOR_CLASSIFICATIONS'
export const SET_ALL_CLASSIFICATIONS = 'SET_ALL_CLASSIFICATIONS'
export const SET_AVAILABLE_MINOR_CLASSIFICATIONS =
  'SET_AVAILABLE_MINOR_CLASSIFICATIONS'
export const CLEAR_CLASSIFICATIONS = 'CLEAR_CLASSIFICATIONS'

export interface SelectedMajorClassification {
  classificationNumber: number
  name: string
  selectedMinorClassifications: AvailableMinorClassification[]
}

export interface AvailableMinorClassification extends MinorClassification {
  minorClassificationNumber: number
  doneBy?: string
}

export interface SetClassifications {
  type: typeof SET_CLASSIFICATIONS
  payload: SelectedMajorClassification[]
}

export interface SetSelectedMinorClassifications {
  type: typeof SET_CLASSIFICATIONS
  payload: SelectedMajorClassification[]
}

export interface SetAllClassifications {
  type: typeof SET_ALL_CLASSIFICATIONS
  payload: MajorClassification[]
}

export interface ClearClassifications {
  type: typeof CLEAR_CLASSIFICATIONS
}

export interface SetAvailableMinorClassifications {
  type: typeof SET_AVAILABLE_MINOR_CLASSIFICATIONS
  payload: AvailableMinorClassification[]
}

export interface ClassificationsState {
  selected: SelectedMajorClassification[]
  allClassifications: MajorClassification[]
  availableMinorClassifications: AvailableMinorClassification[]
  selectedMinorClassifications: MinorClassification[]
}
