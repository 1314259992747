import React from 'react'
import { Form } from 'react-bootstrap'
import { map } from 'lodash'

import {
  CompendiumStateEnum,
  CompendiumStateOptions,
} from '../../shared/models'

interface Props {
  handleChange: Function
  label?: string
  selected?: CompendiumStateEnum
  stateOptions?: CompendiumStateOptions[]
}

const CompendiumStateOption = ({
  handleChange,
  selected,
  stateOptions,
}: Props) => {
  return (
    <Form.Group className="state-options-container">
      {map(stateOptions, ({ label, value }) => {
        return (
          <Form.Check
            id={`${label}-compendium-state`}
            className="state-options-item"
            type="radio"
            label={label}
            key={value}
            value={value}
            checked={selected === value}
            onChange={() => handleChange(value)}
          />
        )
      })}
    </Form.Group>
  )
}

export default CompendiumStateOption
