import React, { Component } from 'react'
import { connect } from 'react-redux'

import './AuditTrail.css'
import { AppState } from '../../store'
import { AppModeEnum } from '../../shared/models'
import { SidebarActiveItem } from '../../shared/models'
import { updateSidebarActiveItem } from '../../store/actions'
import { auditTrail } from '../../shared/constants'
import AuditTrailDistributor from './distributor/AuditTrailDistributor'
import AuditTrailServiceProvider from './service-provider/AuditTrailServiceProvider'

interface DispatchProps {
  updateSidebarActiveItem: (key: SidebarActiveItem) => void
}

interface State {}

type Props = AppState & DispatchProps

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = dispatch => ({
  updateSidebarActiveItem: (key: SidebarActiveItem) => {
    dispatch(updateSidebarActiveItem(key))
  },
})

class AuditTrail extends Component<Props, State> {
  componentDidMount(): void {
    this.props.updateSidebarActiveItem(auditTrail.eventKey)
  }

  render(): JSX.Element {
    const {
      appMode: { activeMode },
    } = this.props

    if (activeMode === AppModeEnum.Distributors) {
      return <AuditTrailDistributor />
    } else {
      return <AuditTrailServiceProvider />
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditTrail)
