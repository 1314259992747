import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { Button, Form, Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import DiscardReason from '../../shared/DiscardReason'
import { btnSaveText, btnLoadingText } from '../../../shared/constants'
import { finalReviewRoute } from '../../../shared/constants'
import {
  SidebarActiveItem,
  FrontEndError,
  User,
  Company,
  NoDataDisplayStateEnum,
  AcceptedUserEnum,
  AppModeEnum,
  serviceProviderTaggingDiscardReasons,
  ServiceProviderDiscardReasonEnum,
} from '../../../shared/models'
import {
  updateSidebarActiveItem,
  setErrors,
  toggleNoDataPage,
  clearClassifications,
  setAllClassifications,
} from '../../../store/actions'
import { AppState } from '../../../store'

import {
  validateStringLength,
  getNextFinalReviewTask,
  saveFinalReview,
  getClassification,
  formatClassificationsPayload,
  formatUrl,
  setSelectedClassifications,
  cancelActiveRequests,
} from '../../../services'

import ClassificationPicker from '../../shared/classification-picker/ClassificationPicker'
// tslint:disable-next-line:max-line-length
import CompanyInfoReviewServiceProvider from '../../shared/company-info/service-provider/CompanyInfoReviewServiceProvider'

import TaskPerformersInfo from '../../shared/TaskPerformersInfo'
import {
  getWebSocket,
  sendMessage,
} from '../../../services/base/websocket-service'

interface State {
  xbsWebsite: string
  doneBy: User
  testedBy: User
  holdXbsDescription: string
  testXbsDescription: string
  discardReason: ServiceProviderDiscardReasonEnum
  holdDiscardReason: ServiceProviderDiscardReasonEnum
  testDiscardReason: ServiceProviderDiscardReasonEnum
  company: Company
  description: string
  accepted?: AcceptedUserEnum
  acceptedDiscardExplanation?: AcceptedUserEnum
  isSubmitting: boolean
  btnSaveText: string
  discardExplanation: string
  holdDiscardExplanation: string
  testDiscardExplanation: string
}

interface DispatchProps {
  updateSidebarActiveItem: (key: SidebarActiveItem) => void
  toggleNoDataPageDisplayState: (state: NoDataDisplayStateEnum) => void
}

type Props = AppState & DispatchProps

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = dispatch => ({
  updateSidebarActiveItem: (key: SidebarActiveItem) => {
    dispatch(updateSidebarActiveItem(key))
  },
  toggleNoDataPageDisplayState: (state: NoDataDisplayStateEnum) => {
    dispatch(toggleNoDataPage(finalReviewRoute.eventKey, state))
  },
})

class FinalReviewDistributor extends Component<Props, State> {
  ws: WebSocket | null
  constructor(props: Props) {
    super(props)
    this.ws = null
    this.state = {
      xbsWebsite: '',
      holdXbsDescription: '',
      testXbsDescription: '',
      discardReason: ServiceProviderDiscardReasonEnum.NotDiscarded,
      holdDiscardReason: ServiceProviderDiscardReasonEnum.NotDiscarded,
      testDiscardReason: ServiceProviderDiscardReasonEnum.NotDiscarded,
      description: '',
      doneBy: {} as User,
      testedBy: {} as User,
      isSubmitting: false,
      btnSaveText,
      company: {
        taggingId: '',
        identifier: '',
        identifierSource: '',
        name: '',
        country: '',
        city: '',
        postalCode: '',
        lineOfBusiness: '',
        description: '',
        url: '',
        sic: '',
        classifications: [],
      },
      discardExplanation: '',
      holdDiscardExplanation: '',
      testDiscardExplanation: '',
    }
  }

  async componentDidMount(): Promise<void> {
    await cancelActiveRequests()
    const user = JSON.parse(localStorage.getItem('user') as string)
    this.ws = await getWebSocket(
      `refresh?userId=${user.userId}&section=finalReview&comp_type=SP`
    )
    if (this.ws) {
      this.ws.onmessage = () => {
        this.reload()
      }
    }
    this.props.updateSidebarActiveItem(finalReviewRoute.eventKey)
    await this.fetchClassifications()
    const getFinalReviewTaskResult = await getNextFinalReviewTask(
      AppModeEnum.ServiceProviders
    )
    const nextTask = getFinalReviewTaskResult
      ? getFinalReviewTaskResult.data
      : {}
    if (!nextTask) {
      this.props.toggleNoDataPageDisplayState(NoDataDisplayStateEnum.Show)
    } else {
      this.setState({
        ...nextTask,
        discardReason: nextTask.holdDiscardReason,
      })

      if (this.state.company) {
        setSelectedClassifications(this.state.company.classifications)
      }
    }
  }

  componentWillUnmount(): void {
    this.props.toggleNoDataPageDisplayState(NoDataDisplayStateEnum.Hide)
    clearClassifications()
    if (this.ws) {
      this.ws.close()
    }
  }

  async fetchClassifications(): Promise<void> {
    clearClassifications()
    const classificationsResult = await getClassification()
    const classifications = classificationsResult
      ? classificationsResult.data
      : []
    setAllClassifications(classifications)
  }

  disableButton(): void {
    this.setState({
      isSubmitting: true,
      btnSaveText: btnLoadingText,
    })
  }

  initializeDiscardExplanations(data: Record<string, unknown>): void {
    data.holdDiscardExplanation = data.holdDiscardExplanation || ''
    data.testDiscardExplanation = data.testDiscardExplanation || ''
  }

  async reload(): Promise<void> {
    clearClassifications()
    const getFinalReviewTaskResult = await getNextFinalReviewTask(
      AppModeEnum.ServiceProviders
    )
    let nextTask = getFinalReviewTaskResult
      ? getFinalReviewTaskResult.data
      : null
    if (!nextTask) {
      this.props.toggleNoDataPageDisplayState(NoDataDisplayStateEnum.Show)
    } else {
      this.initializeDiscardExplanations(nextTask)
      this.setState({
        ...nextTask,
        discardReason: nextTask.holdDiscardReason,
        accepted: undefined,
        acceptedDiscardExplanation: undefined,
        description: '',
        isSubmitting: false,
        btnSaveText,
        discardExplanation: '',
      })
      if (this.state.company) {
        setSelectedClassifications(this.state.company.classifications)
      }
    }
  }
  async saveAndNext(): Promise<void> {
    if (
      !this.validateDescription() ||
      !this.validateMajorClassification() ||
      !this.validateMinorClassification() ||
      !this.validateDiscardExplanation()
    ) {
      return
    }

    const selectedClassifications = formatClassificationsPayload()
    const {
      description,
      discardReason,
      company,
      discardExplanation,
    } = this.state
    this.disableButton()
    const result = await saveFinalReview(
      {
        description,
        discardReason,
        discardExplanation,
        selectedClassifications,
      },
      company.taggingId,
      AppModeEnum.ServiceProviders
    )
    if (result) {
      await this.reload()
      if (this.ws) {
        sendMessage(this.ws, {
          newTask: true,
        })
      }
    }
  }

  setDescription(description: string): void {
    if (Number(this.state.accepted) === AcceptedUserEnum.Initial) {
      this.setState({
        holdXbsDescription: description,
        description,
      })
      return
    }
    this.setState({
      testXbsDescription: description,
      description,
    })
  }

  setDiscardReason(discardReason: ServiceProviderDiscardReasonEnum): void {
    this.setState({
      discardReason,
    })
  }

  setDiscardExplanation(discardExplanation: string): void {
    if (
      Number(this.state.acceptedDiscardExplanation) === AcceptedUserEnum.Initial
    ) {
      this.setState({
        holdDiscardExplanation: discardExplanation,
        discardExplanation,
      })
      return
    }
    this.setState({
      testDiscardExplanation: discardExplanation,
      discardExplanation,
    })
  }

  setAcceptedUser(accepted: AcceptedUserEnum): void {
    this.setState({
      accepted,
      description:
        Number(accepted) === AcceptedUserEnum.Initial
          ? this.state.holdXbsDescription
          : this.state.testXbsDescription,
    })
  }

  setAcceptedDiscardExplanation(
    acceptedDiscardExplanation: AcceptedUserEnum
  ): void {
    console.log(acceptedDiscardExplanation, '!!!!')
    this.setState({
      acceptedDiscardExplanation,
      discardExplanation:
        Number(acceptedDiscardExplanation) === AcceptedUserEnum.Initial
          ? this.state.holdDiscardExplanation
          : this.state.testDiscardExplanation,
    })
  }

  validateMajorClassification(): boolean {
    const {
      classifications: { selected },
    } = this.props
    const wontBeDiscarded =
      Number(this.state.discardReason) ===
      ServiceProviderDiscardReasonEnum.NotDiscarded
    if (isEmpty(selected) && wontBeDiscarded) {
      setErrors(
        new FrontEndError(
          'majorClassifications',
          'At least one Major Classification must be selected'
        )
      )
      return false
    }

    return true
  }

  validateMinorClassification(): boolean {
    const {
      classifications: { selectedMinorClassifications, selected },
    } = this.props
    const { discardReason } = this.state
    const majorsFiltered = selected.filter(major => major.name !== 'Other')
    if (
      Number(discardReason) === ServiceProviderDiscardReasonEnum.NotDiscarded &&
      majorsFiltered.length > 0 &&
      isEmpty(selectedMinorClassifications)
    ) {
      setErrors(
        new FrontEndError(
          'minorClassifications',
          'At least one Minor Classification must be selected'
        )
      )
      return false
    }
    return true
  }

  validateDescription(): boolean {
    if (!this.state.accepted) {
      setErrors(
        new FrontEndError('description', 'Description must be selected.')
      )
      return false
    }
    if (!validateStringLength(this.state.description, 1)) {
      setErrors(new FrontEndError('description', 'Description is required.'))
      return false
    }

    return true
  }

  validateDiscardExplanation(): boolean {
    if (
      Number(this.state.discardReason) !==
        ServiceProviderDiscardReasonEnum.NotDiscarded &&
      !this.state.acceptedDiscardExplanation
    ) {
      setErrors(
        new FrontEndError(
          'description',
          'Discard Explanation must be selected.'
        )
      )
      return false
    }
    return true
  }

  render(): JSX.Element {
    const {
      noData: { state },
    } = this.props

    if (state === NoDataDisplayStateEnum.Show) {
      return <div />
    }

    if (!this.state.company || !this.state.company.identifier) {
      return <div />
    }

    const {
      company,
      xbsWebsite,
      doneBy,
      testedBy,
      holdXbsDescription,
      testXbsDescription,
      discardReason,
      holdDiscardReason,
      testDiscardReason,
      accepted,
      acceptedDiscardExplanation,
      holdDiscardExplanation,
      testDiscardExplanation,
      isSubmitting,
      btnSaveText,
    } = this.state

    return (
      <Form className="form-container">
        <Container fluid className="margin-top-75 padding-bottom-30">
          <CompanyInfoReviewServiceProvider company={company} />
          <Row>
            <Col sm={4}>
              <Form.Group as={Row}>
                <Col sm={4}>
                  <Form.Label>Website (DandB)</Form.Label>
                </Col>
                <Col sm={8}>
                  <a
                    rel="noreferrer"
                    className="font-weight-bold"
                    target="_blank"
                    href={company ? formatUrl(company.url) : ''}
                  >
                    {company ? company.url : ''}
                  </a>
                </Col>
              </Form.Group>
            </Col>
            <Col sm={8}>
              <Form.Group as={Row}>
                <Col sm={4}>
                  <Form.Label>Website (XBS)</Form.Label>
                </Col>
                <Col sm={8}>
                  <a
                    rel="noreferrer"
                    className="font-weight-bold"
                    target="_blank"
                    href={formatUrl(xbsWebsite)}
                  >
                    {xbsWebsite}
                  </a>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm={4}>
              <DiscardReason
                discardReasons={serviceProviderTaggingDiscardReasons}
                readonly={true}
                selected={holdDiscardReason}
                handleChange={() => {}}
                label="Tagger Discard Reason"
              />
            </Col>
            <Col sm={4}>
              <DiscardReason
                discardReasons={serviceProviderTaggingDiscardReasons}
                readonly={true}
                selected={testDiscardReason}
                label="Tester Discard Reason"
                handleChange={() => {}}
              />
            </Col>
            <Col sm={4}>
              <DiscardReason
                discardReasons={serviceProviderTaggingDiscardReasons}
                handleChange={reason => this.setDiscardReason(reason)}
                selected={discardReason}
                label="Reviewer Discard Reason"
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm={6}>
              <Col sm={12}>
                <Form.Group>
                  <Row>
                    <Col sm={10}>
                      <Form.Label className="font-weight-bold">
                        Tagger Discard Explanation
                      </Form.Label>
                    </Col>
                    <Col sm={2}>
                      <Form.Check
                        id={`${
                          AcceptedUserEnum.Initial
                        }-tagger-discard-explanation`}
                        type="radio"
                        label="Best"
                        value={AcceptedUserEnum.Initial}
                        onChange={({ target: { value } }) => {
                          this.setAcceptedDiscardExplanation(value)
                        }}
                        checked={
                          Number(acceptedDiscardExplanation) ===
                          AcceptedUserEnum.Initial
                        }
                      />
                    </Col>
                  </Row>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    disabled={
                      !acceptedDiscardExplanation ||
                      Number(acceptedDiscardExplanation) ===
                        AcceptedUserEnum.Tester
                    }
                    value={holdDiscardExplanation}
                    onChange={({ target }) =>
                      this.setDiscardExplanation(target.value)
                    }
                    onBlur={() => this.validateDiscardExplanation()}
                  />
                </Form.Group>
              </Col>
            </Col>
            <Col sm={6}>
              <Col sm={12}>
                <Form.Group>
                  <Row>
                    <Col sm={10}>
                      <Form.Label className="font-weight-bold">
                        Tester Discard Explanation
                      </Form.Label>
                    </Col>
                    <Col sm={2}>
                      <Form.Check
                        id={`${
                          AcceptedUserEnum.Tester
                        }-tester-discard-explanation`}
                        type="radio"
                        label="Best"
                        value={AcceptedUserEnum.Tester}
                        onChange={({ target: { value } }) =>
                          this.setAcceptedDiscardExplanation(value)
                        }
                        checked={
                          Number(acceptedDiscardExplanation) ===
                          AcceptedUserEnum.Tester
                        }
                      />
                    </Col>
                  </Row>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    disabled={
                      !acceptedDiscardExplanation ||
                      Number(acceptedDiscardExplanation) ===
                        AcceptedUserEnum.Initial
                    }
                    value={testDiscardExplanation}
                    onChange={({ target }) =>
                      this.setDiscardExplanation(target.value)
                    }
                    onBlur={() => this.validateDiscardExplanation()}
                  />
                </Form.Group>
              </Col>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm={6}>
              <Col sm={12}>
                <Form.Group>
                  <Row>
                    <Col sm={10}>
                      <Form.Label className="font-weight-bold">
                        Tagger Description
                      </Form.Label>
                    </Col>
                    <Col sm={2}>
                      <Form.Check
                        id={`${AcceptedUserEnum.Initial}-tagger-description`}
                        type="radio"
                        label="Best"
                        value={AcceptedUserEnum.Initial}
                        onChange={({ target }) =>
                          this.setAcceptedUser(Number(target.value))
                        }
                        checked={Number(accepted) === AcceptedUserEnum.Initial}
                      />
                    </Col>
                  </Row>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    disabled={
                      !accepted || Number(accepted) === AcceptedUserEnum.Tester
                    }
                    value={holdXbsDescription}
                    onChange={({ target }) => this.setDescription(target.value)}
                    onBlur={() => this.validateDescription()}
                  />
                </Form.Group>
              </Col>
            </Col>
            <Col sm={6}>
              <Col sm={12}>
                <Form.Group>
                  <Row>
                    <Col sm={10}>
                      <Form.Label className="font-weight-bold">
                        Tester Description
                      </Form.Label>
                    </Col>
                    <Col sm={2}>
                      <Form.Check
                        id={`${AcceptedUserEnum.Tester}-tester-description`}
                        type="radio"
                        label="Best"
                        value={AcceptedUserEnum.Tester}
                        onChange={({ target }) =>
                          this.setAcceptedUser(Number(target.value))
                        }
                        checked={Number(accepted) === AcceptedUserEnum.Tester}
                      />
                    </Col>
                  </Row>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    disabled={
                      !accepted || Number(accepted) === AcceptedUserEnum.Initial
                    }
                    value={testXbsDescription}
                    onChange={({ target }) => this.setDescription(target.value)}
                    onBlur={() => this.validateDescription()}
                  />
                </Form.Group>
              </Col>
            </Col>
          </Row>
          <Form.Label className="font-weight-bold">
            Major Classification
          </Form.Label>
          <ClassificationPicker />
          <hr />
          <TaskPerformersInfo {...{ doneBy, testedBy }} />
          <hr />
          <Row className="form-container-actions pr-3 justify-content-end">
            <Button
              className="btn btn-purple"
              onClick={() => this.saveAndNext()}
              disabled={isSubmitting}
            >
              <FontAwesomeIcon icon={faArrowRight} /> {btnSaveText}
            </Button>
          </Row>
        </Container>
      </Form>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinalReviewDistributor)
