import React, { Component } from 'react'
import { StatsDownloadItem } from '../../../../../shared/models'

interface State {}

interface Props {
  stats: StatsDownloadItem
}

class CompendiumRepositoryStats extends Component<Props, State> {
  StatCircle = (name: string, value: number) => (
    <div className="stat-container">
      <div className={`stat-circle-container ${name}`}>{value}</div>
      <span>{name}</span>
    </div>
  )

  renderStatsCircles = () => {
    const { stats } = this.props
    return (
      <>
        <div className="stat-container">
          <div className={`stat-circle-container total`}>{stats.total}</div>
          <span>Reports</span>
        </div>
        <div className="stat-container">
          <div className={`stat-circle-container pending`}>{stats.pending}</div>
          <span>Pending</span>
        </div>
        <div className="stat-container">
          <div className={`stat-circle-container inProgress`}>
            {stats.inProgress}
          </div>
          <span>In Progress</span>
        </div>
        <div className="stat-container">
          <div className={`stat-circle-container completed`}>
            {stats.completed}
          </div>
          <span>Completed</span>
        </div>
      </>
    )
  }

  render(): JSX.Element {
    return (
      <div className="download-repository-stats-container">
        {this.renderStatsCircles()}
      </div>
    )
  }
}

export default CompendiumRepositoryStats
