import {post} from './base/base-http-service'
import {Batch, BatchCompsCount} from "../shared/models/batch"

interface BatchData {
  data: Batch[]
}

interface BatchCompsCountData {
  data: BatchCompsCount[]
}

export const getBatches = async (column, direction): Promise<BatchData> => {
  return post(`batch-process-report`, {column, direction})
}

export const getBatchDetails = async (primaryId): Promise<BatchCompsCountData> => {
  return post(`batch-process-report/companies-count`, {primaryId})
}


