import React from 'react'

const RegularUserRow = ({
  userName,
  availableFinding,
  availableTagging,
  findingToday,
  taggingToday,
  total,
}) => {
  return (
    <tr className="row-height-20">
      <td className="align-middle">{userName}</td>
      <td className="align-middle text-center">{availableFinding}</td>
      <td className="align-middle text-center">{availableTagging}</td>
      <td className="align-middle text-center">{findingToday}</td>
      <td className="align-middle text-center">{taggingToday}</td>
      <td className="text-center font-weight-bold align-middle">{total}</td>
    </tr>
  )
}

export default RegularUserRow
