import { AppModeEnum } from '../../shared/models'

export const UPDATE_APP_MODE = 'UPDATE_APP_MODE'

export interface AppModeUpdate {
  type: typeof UPDATE_APP_MODE
  payload: { activeMode: AppModeEnum }
}

export interface AppModeState {
  activeMode: AppModeEnum
}
