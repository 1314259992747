import React from 'react'
import { Row, Col } from 'react-bootstrap'
import MultipleSelect from './multiple-select/MultipleSelect'
import { Classification } from '../../../shared/models'

import './ClassificationPicker.css'
import {connect} from "react-redux"
import {AppState} from "../../../store"

interface Props {
  onlyMajor?: boolean
}
const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = () => ({})

type AllProps = AppState & Props

const ClassificationPicker = ({ onlyMajor = false, classifications }: AllProps) => {
  return (
    <Row className="col-sm-12 classification-picker">
      <Col className={onlyMajor ? 'col-sm-12 major' : 'col-sm-6 major'}>
        <MultipleSelect type={Classification.Major}/>
      </Col>
      {onlyMajor ? (
        <div />
      ) : (
        <Col className="col-sm-6">
          <MultipleSelect isDisabled={classifications.selected.length === 0} type={Classification.Minor} />
        </Col>
      )}
    </Row>
  )
}

export default  connect(
    mapStateToProps,
    mapDispatchToProps
)(ClassificationPicker)

