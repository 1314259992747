import {
  map,
  groupBy,
  keys,
  each,
  find,
  uniqBy,
  head,
  filter,
  isEmpty,
} from 'lodash'

import { get } from './base/base-http-service'
import {
  MajorClassification,
  FormatedClassification,
  User,
} from '../shared/models'

import { store } from '../store'
import {
  setMajorClassification,
  setMinorClassifications,
} from '../store/actions'
import { AvailableMinorClassification } from '../store/types'

interface ClassificationResponse {
  data: MajorClassification[]
}

interface FormatedMinorClassification {
  doneBy: User
  testedBy: User
  minorClassificationNumber: number
}

interface FormatedMajorClassification {
  classificationNumber: number
  minorClassifications: ApiMinorNotification[]
  doneBy: User
  testedBy: User
}

interface ApiMinorNotification {
  classificationNumber: number
  minorClassification: AvailableMinorClassification
  doneBy: User
  testedBy: User
}

const otherMinorClassification = 'Other' // minor classification is for display only, should not be sent to the BE

export const getClassification = async (): Promise<ClassificationResponse> => {
  return get(`major-classifications`)
}

export const formatClassificationsPayload = (): FormatedClassification[] => {
  const { selected } = store.getState().classifications

  return map(
    selected,
    ({ classificationNumber, selectedMinorClassifications }) => ({
      majorClassificationNumber: classificationNumber,
      minorClassifications: map(
        filter(
          selectedMinorClassifications,
          ({ name }) => name !== otherMinorClassification
        ),
        ({ minorClassificationNumber }) => minorClassificationNumber
      ),
    })
  )
}

const groupMajorClassificationsClassifications = (
  classificationNumber,
  classifications,
  classification
): void => {
  const existingClassification = find(classifications, {
    classificationNumber: +classificationNumber,
  }) as any
  existingClassification.doneBy.push(classification.doneBy)
  existingClassification.testedBy.push(classification.testedBy)
  existingClassification.minorClassifications.push({
    doneBy: classification.doneBy,
    testedBy: classification.testedBy,
    minorClassification: formatMinorClassification(classification),
  })
}

const formatMinorClassification = (
  classification
): FormatedMinorClassification | null => {
  if (!classification.minorClassification) {
    return null
  }

  return {
    doneBy: classification.doneBy,
    testedBy: classification.testedBy,
    minorClassificationNumber:
      classification.minorClassification.classificationNumber,
  }
}

const formatClassifications = (
  classifications
): FormatedMajorClassification[] => {
  return map(
    classifications,
    ({ classificationNumber, doneBy, testedBy, minorClassifications }) => ({
      classificationNumber,
      minorClassifications: filter(
        minorClassifications,
        ({ minorClassification }) => !!minorClassification
      ),
      doneBy: head(uniqBy(doneBy as User[], 'userId')) as User,
      testedBy: head(uniqBy(testedBy as User[], 'userId')) as User,
    })
  )
}

const getClassificationDoneBy = (doneBy, testedBy): string => {
  return !doneBy && !testedBy
    ? 'Tagger'
    : !!doneBy && !!testedBy
    ? 'Both'
    : !!doneBy
    ? 'Tagger'
    : 'Tester'
}

const setSelectedClassificationsInStore = (classifications): void => {
  each(
    formatClassifications(classifications),
    ({ doneBy, testedBy, classificationNumber, minorClassifications }) => {
      const majorClassificationDoneBy = getClassificationDoneBy(
        doneBy,
        testedBy
      )
      setMajorClassification(classificationNumber, majorClassificationDoneBy)
      each(
        minorClassifications,
        ({
          doneBy,
          testedBy,
          minorClassification: { minorClassificationNumber },
        }) => {
          const minorClassificationDoneBy = getClassificationDoneBy(
            doneBy,
            testedBy
          )
          setMinorClassifications(
            classificationNumber,
            minorClassificationNumber,
            minorClassificationDoneBy
          )
        }
      )
    }
  )
}

export const setSelectedClassifications = (companyClassifications): void => {
  if (isEmpty(companyClassifications)) {
    return
  }
  const grouped = groupBy(
    companyClassifications,
    'majorClassification.classificationNumber'
  ) as any
  const classifications = map(keys(grouped), key => ({
    classificationNumber: +key,
    doneBy: [],
    testedBy: [],
    minorClassifications: [],
  }))

  each(keys(grouped), key => {
    each(grouped[key], c =>
      groupMajorClassificationsClassifications(key, classifications, c)
    )
  })

  setSelectedClassificationsInStore(classifications)
}
