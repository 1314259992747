import { AppModeState, AppModeUpdate, UPDATE_APP_MODE } from '../types'
import { AppModeEnum } from '../../shared/models'

import { getValueForKeyInLocalStorage } from '../../services'

const initialState: AppModeState = {
  activeMode:
    (getValueForKeyInLocalStorage('activeMode') as AppModeEnum) ||
    AppModeEnum.NotSelected,
}

export function appModeReducer(
  state = initialState,
  action: AppModeUpdate
): AppModeState {
  switch (action.type) {
    case UPDATE_APP_MODE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}
