import React, { Component } from 'react'
import { connect } from 'react-redux'

import './TaggingCompendium.css'
import { AppState } from '../../store'
import { AppModeEnum } from '../../shared/models'
import TaggingCompendiumDistributor from './distributor/TaggingCompendiumDistributor'
import TaggingCompendiumServiceProvider from './service-provider/TaggingCompendiumServiceProvider'
import { SidebarActiveItem } from '../../shared/models'
import { updateSidebarActiveItem } from '../../store/actions'
import { taggingCompendium } from '../../shared/constants'

interface DispatchProps {
  updateSidebarActiveItem: (key: SidebarActiveItem) => void
}

interface State {}

type Props = AppState & DispatchProps

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = dispatch => ({
  updateSidebarActiveItem: (key: SidebarActiveItem) => {
    dispatch(updateSidebarActiveItem(key))
  },
})

class TaggingCompendium extends Component<Props, State> {
  componentDidMount(): void {
    this.props.updateSidebarActiveItem(taggingCompendium.eventKey)
  }

  render(): JSX.Element {
    const {
      appMode: { activeMode },
    } = this.props

    if (activeMode === AppModeEnum.Distributors) {
      return <TaggingCompendiumDistributor />
    } else {
      return <TaggingCompendiumServiceProvider />
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaggingCompendium)
