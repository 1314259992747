import React from 'react'
import styles from '../Stepper.module.css'

const Step = ( props ) => (
    <div className={`${styles["Stepper__step"]} `}>
        <div className={styles["Stepper__indicator"]}>
            <span
                onClick={()=> {
                    if(props.isComplete) {
                        props.onClick()
                    }}
                }
                className={
                    `${styles["Stepper__info"]}
                    ${styles[props.isComplete ?
                        "step__is-complete":
                        "step__not-complete"
                        ]}
                    ${styles[props.isActive ? 
                        "is-active":
                        "not-active"
                        ]}`
                }
            >
                {props.indicator}
            </span>
        </div>
        <div
            className={
                `${styles["Stepper__label"]} 
                ${styles[props.isActive ? 
                    "Stepper__label-active":
                    "Stepper__label-active-not-active"
                    ]}`
            }
        >
            {props.title}
        </div>
        <div
            className={
                `${styles["Stepper__panel"]} 
                ${styles[props.isActive ? 
                    "step__is-active":
                    "step__not-active"
                    ]}`
            }
        >
            {props.children}
        </div>
    </div>)

export default Step