import React from 'react'
import { Form } from 'react-bootstrap'
import { map } from 'lodash'

import {
  DistributorDiscardReasonEnum,
  ServiceProviderDiscardReasonEnum,
  LabeledDiscardReason,
} from '../../shared/models'

interface Props {
  handleChange: Function
  readonly?: boolean
  label?: string
  selected?: DistributorDiscardReasonEnum | ServiceProviderDiscardReasonEnum
  discardReasons?: LabeledDiscardReason[]
}

const DiscardReason = ({
  label,
  handleChange,
  selected,
  discardReasons,
  readonly,
}: Props) => {
  const selectStringCasted = String(selected)
  return (
    <Form.Group>
      <Form.Label className="font-weight-bold">
        {label || 'Discard Reason'}
      </Form.Label>
      <Form.Group>
        {map(discardReasons, ({ label, reason }) => {
          return (
            <Form.Check
              id={`${reason}-${readonly}-discard-reason`}
              disabled={readonly}
              type="radio"
              label={label}
              key={reason}
              value={reason}
              checked={parseInt(selectStringCasted) === reason}
              onChange={({ target: { value } }) => handleChange(value)}
            />
          )
        })}
      </Form.Group>
    </Form.Group>
  )
}

export default DiscardReason
