import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Nav, Button } from 'react-bootstrap'
import { map } from 'lodash'

import { AppState } from '../../../store'
import {
  updateAppMode,
  updateSidebarActiveItem,
  setSuccess,
} from '../../../store/actions'
import { SidebarState, AppModeState } from '../../../store/types'
import {
  SidebarActiveItem,
  AppModeEnum,
  AppModeObject,
} from '../../../shared/models'
import { routes } from '../../../shared/constants'

import SidebarLink from './components/SidebarLink'

import './Sidebar.css'

interface State {}

interface DispatchProps {
  updateSidebarActiveItem: (key: SidebarActiveItem) => void
  updateAppMode: (activeMode: AppModeEnum) => void
}

interface StateProps {
  sidebar: SidebarState
  appMode: AppModeState
}

type Props = StateProps & DispatchProps

const mapStateToProps = (state: AppState) => ({ ...state })
const mapDispatchToProps = dispatch => ({
  updateSidebarActiveItem: (key: SidebarActiveItem) => {
    dispatch(updateSidebarActiveItem(key))
  },
  updateAppMode: (activeMode: AppModeEnum) => {
    dispatch(updateAppMode(activeMode))
  },
})

class Sidebar extends Component<Props, State> {
  changeAppMode = () => {
    this.props.updateAppMode(
      AppModeObject[this.props.appMode.activeMode].opposite
    )
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.appMode.activeMode !== prevProps.appMode.activeMode &&
      prevProps.appMode.activeMode !== AppModeEnum.NotSelected
    ) {
      window.location.reload()
    }
  }

  componentDidMount() {
    setSuccess(
      `Your current app mode is: ${
        AppModeObject[this.props.appMode.activeMode].text
      }`
    )
  }

  render(): JSX.Element {
    const {
      sidebar: { activeItem },
      appMode: { activeMode },
      updateSidebarActiveItem,
    } = this.props
    return (
      <Nav
        activeKey={activeItem}
        className="flex-column"
        onSelect={key => updateSidebarActiveItem(key)}
      >
        {map(routes, (route, index) => (
          <SidebarLink {...route} key={index} />
        ))}
        <hr className="Sidebar-divider" />
        <div className="Sidebar-app-mode-container">
          <p className="Sidebar-app-mode-text">
            Current App Mode: <br />
            {AppModeObject[activeMode].text}
          </p>
          <Button className="btn App-mode-btn" onClick={this.changeAppMode}>
            Take me to {AppModeObject[AppModeObject[activeMode].opposite].text}
          </Button>
        </div>
      </Nav>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar)
