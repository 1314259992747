import { store } from '../index'
import { DECREASE_ACTIVE_REQUESTS, INCREASE_ACTIVE_REQUESTS } from '../types'

export const decreaseNumberOfActiveRequests = () => {
  let { loader } = store.getState()
  store.dispatch({
    type: DECREASE_ACTIVE_REQUESTS,
    payload: { numberOfActiveRequests: loader.numberOfActiveRequests - 1 },
  })
}

export const increaseNumberOfActiveRequests = () => {
  let { loader } = store.getState()
  store.dispatch({
    type: INCREASE_ACTIVE_REQUESTS,
    payload: { numberOfActiveRequests: loader.numberOfActiveRequests + 1 },
  })
}
