import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import { DownloadItem } from '../../../../../shared/models'

interface State {
  selectedItem?: DownloadItem
}

interface Props {
  items: DownloadItem[]
  handleDownload: Function
}

class CompendiumReportRepositoryTable extends Component<Props, State> {
  renderItems = (): JSX.Element[] => {
    const { items } = this.props
    return items.map(item => this.renderRow(item))
  }

  renderRow = (item: DownloadItem): JSX.Element => {
    const { handleDownload } = this.props
    return (
      <tr key={item.reportId} className="download-repository-table-row">
        <td>{moment(item.creationDate).format('YYYY-MM-DD HH:mm:ss')}</td>
        <td>{item.status}</td>
        <td>{moment(item.creationDate).format('YYYY-MM-DD')}</td>
        <td className="download-repository-actions">
          {item.url ? (
            <span onClick={() => handleDownload(item.url)}>
              <FontAwesomeIcon
                icon={faDownload}
                className="download-repository-download-icon"
              />
            </span>
          ) : null}
        </td>
      </tr>
    )
  }

  render(): JSX.Element {
    return (
      <div className="container-table-download-repository">
        <Table responsive="lg" className="download-repository-table">
          <thead className="download-repository-table-head">
            <tr>
              <th>Report title</th>
              <th>Status</th>
              <th>Due Date</th>
              <th className="download-repository-actions">Actions</th>
            </tr>
          </thead>
          <tbody className="download-repository-table-body">
            {this.renderItems()}
          </tbody>
        </Table>
      </div>
    )
  }
}

export default CompendiumReportRepositoryTable
