import React from 'react'
import { Form, Col, Row } from 'react-bootstrap'
import { SourceTypeDatabaseEnum } from '../../../../shared/models'

const CompanyInfoReviewServiceProvider = ({ company }) => (
  <Row>
    <Col sm={4}>
      <Form.Group as={Row}>
        <Col sm={4}>
          <Form.Label>Country</Form.Label>
        </Col>
        <Col sm={8}>
          <p className="font-weight-bold">{company ? company.country : ''}</p>
        </Col>
        <Col sm={4}>
          <Form.Label>Company Name</Form.Label>
        </Col>
        <Col sm={8}>
          <p className="font-weight-bold">{company ? company.name : ''}</p>
        </Col>
      </Form.Group>
    </Col>
    <Col sm={8}>
      <Form.Group as={Row} className="col-sm-12">
        <Col sm={2}>
          <Form.Label>Identifier</Form.Label>
        </Col>
        <Col sm={2}>
          <p className="font-weight-bold">
            {company ? company.identifier : ''}
          </p>
        </Col>
        <Col sm={2}>
          <Form.Label>Identifier Source</Form.Label>
        </Col>
        <Col sm={2}>
          <p className="font-weight-bold">
            {company ? SourceTypeDatabaseEnum[company.identifierSource] : ''}
          </p>
        </Col>
        <Col sm={2}>
          <Form.Label>Sic</Form.Label>
        </Col>
        <Col sm={2}>
          <p className="font-weight-bold">{company ? company.sic : ''}</p>
        </Col>
      </Form.Group>
    </Col>
  </Row>
)

export default CompanyInfoReviewServiceProvider
