import React, { Component } from 'react'
import { connect } from 'react-redux'

import { AppModeEnum } from '../../shared/models'

import '../../shared/style/tabs.css'
import './DistributeReserve.css'

import { AppState } from '../../store'
import DistributorDistributeReserve from './distributor/DistributorDistributeReserve'
import ServiceProviderDistributeReserve from './service-provider/ServiceProviderDistributeReserve'

interface State {}

interface DispatchProps {}

type Props = AppState & DispatchProps

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = () => ({})

class DistributeReserve extends Component<Props, State> {
  render(): JSX.Element {
    const {
      appMode: { activeMode },
    } = this.props

    if (activeMode === AppModeEnum.Distributors) {
      return <DistributorDistributeReserve />
    } else {
      return <ServiceProviderDistributeReserve />
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributeReserve)
