import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Container, Col, Row, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'

import DiscardReason from '../../shared/DiscardReason'
import { btnSaveText, btnLoadingText } from '../../../shared/constants'
import { findingReviewRoute } from '../../../shared/constants'
import {
  SidebarActiveItem,
  Company,
  User,
  FrontEndError,
  NoDataDisplayStateEnum,
  AcceptedUserEnum,
  AppModeEnum,
  serviceProviderFindingDiscardReasons,
  ServiceProviderDiscardReasonEnum,
} from '../../../shared/models'
import {
  updateSidebarActiveItem,
  setErrors,
  toggleNoDataPage,
} from '../../../store/actions'
import { AppState } from '../../../store'

import {
  getNextFindingReviewTask,
  validateDiscardReason,
  saveFindingReview,
  validateStringLength,
  formatUrl,
  cancelActiveRequests,
} from '../../../services'
// tslint:disable-next-line:max-line-length
import CompanyInfoReviewServiceProvider from '../../shared/company-info/service-provider/CompanyInfoReviewServiceProvider'

import TaskPerformersInfo from '../../shared/TaskPerformersInfo'
import {getWebSocket, sendMessage} from "../../../services/base/websocket-service";

interface State {
  doneBy: User
  testedBy: User
  holdXbsWebsite: string
  testXbsWebsite: string
  holdDiscardReason: ServiceProviderDiscardReasonEnum
  testDiscardReason: ServiceProviderDiscardReasonEnum
  company?: Company
  website: string
  taggingId: string
  companyId: string
  discardReason?: ServiceProviderDiscardReasonEnum
  accepted?: AcceptedUserEnum
  isSubmitting: boolean
  btnSaveText: string
}

interface DispatchProps {
  updateSidebarActiveItem: (key: SidebarActiveItem) => void
  toggleNoDataPageDisplayState: (state: NoDataDisplayStateEnum) => void
}

type Props = AppState & DispatchProps

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = dispatch => ({
  updateSidebarActiveItem: (key: SidebarActiveItem) => {
    dispatch(updateSidebarActiveItem(key))
  },
  toggleNoDataPageDisplayState: (state: NoDataDisplayStateEnum) => {
    dispatch(toggleNoDataPage(findingReviewRoute.eventKey, state))
  },
})

class FindingReviewServiceProvider extends Component<Props, State> {
  ws: WebSocket | null
  constructor(props) {
    super(props)
    this.ws = null
    this.state = {
      website: '',
      holdXbsWebsite: '',
      testXbsWebsite: '',
      holdDiscardReason: ServiceProviderDiscardReasonEnum.NotDiscarded,
      testDiscardReason: ServiceProviderDiscardReasonEnum.NotDiscarded,
      doneBy: {} as User,
      testedBy: {} as User,
      isSubmitting: false,
      btnSaveText,
      taggingId: '',
      companyId: '',
    }
  }

  async componentDidMount(): Promise<void> {
    await cancelActiveRequests()
    const user = JSON.parse(localStorage.getItem('user') as string)
    this.ws = await getWebSocket(`refresh?userId=${user.userId}&section=findingReview&comp_type=SP`)
    if(this.ws) {
      this.ws.onmessage = () => {
        this.reload()
      }
    }
    this.props.updateSidebarActiveItem(findingReviewRoute.eventKey)
    const nextFindingReviewTaskResult = await getNextFindingReviewTask(
      AppModeEnum.ServiceProviders
    )
    const nextTask = nextFindingReviewTaskResult
      ? nextFindingReviewTaskResult.data
      : {}
    if (!nextTask) {
      this.props.toggleNoDataPageDisplayState(NoDataDisplayStateEnum.Show)
    } else {
      this.setState({ ...nextTask })
    }
  }

  componentWillUnmount(): void {
    this.props.toggleNoDataPageDisplayState(NoDataDisplayStateEnum.Hide)

    if(this.ws){
      this.ws.close()
    }
  }

  validateDiscardReason(): boolean {
    if (!validateDiscardReason(this.state.discardReason)) {
      setErrors(
        new FrontEndError(
          'discardReason',
          'Reviewer Discard Reason is required.'
        )
      )
      return false
    }

    return true
  }

  validateWebsite(): boolean {
    if (!this.state.accepted) {
      setErrors(new FrontEndError('website', 'Website must be selected.'))
      return false
    }
    if (!validateStringLength(this.state.website || '')) {
      setErrors(
        new FrontEndError(
          'website',
          'Website is required and must be at least 4 characters long.'
        )
      )
      return false
    }

    return true
  }

  setWebsite(website: string): void {
    if (Number(this.state.accepted) === AcceptedUserEnum.Initial) {
      this.setState({
        holdXbsWebsite: website,
        website,
      })
      return
    }
    this.setState({
      testXbsWebsite: website,
      website,
    })
  }

  setDiscardReason(discardReason: ServiceProviderDiscardReasonEnum): void {
    this.setState({
      discardReason,
    })
  }

  setAcceptedUser(accepted: AcceptedUserEnum): void {
    this.setState({
      accepted,
      website:
        Number(accepted) === AcceptedUserEnum.Initial
          ? this.state.holdXbsWebsite
          : this.state.testXbsWebsite,
    })
  }

  disableButton(): void {
    this.setState({
      isSubmitting: true,
      btnSaveText: btnLoadingText,
    })
  }

  async reload(): Promise<void> {
    const nextFindingReviewTaskResult = await getNextFindingReviewTask(
        AppModeEnum.ServiceProviders
    )
    const nextTask = nextFindingReviewTaskResult
        ? nextFindingReviewTaskResult.data
        : null
    if (!nextTask) {
      this.props.toggleNoDataPageDisplayState(NoDataDisplayStateEnum.Show)
    } else {
      nextTask.testXbsWebsite = nextTask.testXbsWebsite || ''
      nextTask.holdXbsWebsite = nextTask.holdXbsWebsite || ''
      this.setState({
        ...nextTask,
        ...{
          discardReason: null,
          accepted: null,
          website: '',
          isSubmitting: false,
          btnSaveText,
        },
      })
    }
  }
  async saveAndNext(): Promise<void> {
    if (!this.validateDiscardReason()) {
      return
    }
    if (
      String(this.state.discardReason) !==
        String(ServiceProviderDiscardReasonEnum.NoWebsite) &&
      !this.validateWebsite()
    ) {
      return
    }

    this.disableButton()

    const { discardReason, taggingId, companyId } = this.state
    let { website } = this.state
    if (!website) website = ''
    const result = await saveFindingReview(
      { discardReason, website, companyId },
      taggingId,
      AppModeEnum.ServiceProviders
    )
    if (result) {
      await this.reload()
      if(this.ws){
        sendMessage(this.ws,{
          newTask: true,
        })
      }
    }
  }

  render(): JSX.Element {
    const {
      noData: { state },
    } = this.props

    if (state === NoDataDisplayStateEnum.Show) {
      return <div />
    }

    if (!this.state.company || !this.state.company.identifier) {
      return <div />
    }

    const {
      holdXbsWebsite,
      testXbsWebsite,
      holdDiscardReason,
      testDiscardReason,
      discardReason,
      company,
      doneBy,
      testedBy,
      accepted,
      isSubmitting,
      btnSaveText,
    } = this.state

    return (
      <Form className="form-container">
        <Container fluid className="margin-top-75 padding-bottom-30">
          <CompanyInfoReviewServiceProvider company={company} />
          <Row>
            <Form.Group as={Row} className="col-sm-12 no-padding">
              <Col sm={6}>
                <Col sm={12}>
                  <Form.Label>Line Of Business</Form.Label>
                </Col>
                <Col sm={12} className="scroll-box">
                  <p className="font-weight-bold">
                    {company ? company.lineOfBusiness : ''}
                  </p>
                </Col>
              </Col>

              <Col sm={6}>
                <Col sm={12}>
                  <Form.Label>Description</Form.Label>
                </Col>
                <Col sm={12} className="scroll-box">
                  <p className="font-weight-bold">
                    {company ? company.description : ''}
                  </p>
                </Col>
              </Col>
            </Form.Group>
          </Row>
          <hr />
          <Row>
            <Col sm>
              <Form.Group>
                <Row>
                  <Col sm={10}>
                    <Form.Label className="font-weight-bold">
                      Finder XBS Website{' '}
                      {holdXbsWebsite ? (
                        <a
                          rel="noreferrer"
                          href={formatUrl(holdXbsWebsite)}
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </a>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                  </Col>
                  <Col sm={2}>
                    <Form.Check
                      id={`${AcceptedUserEnum.Initial}-finder-website`}
                      type="radio"
                      label="Best"
                      value={AcceptedUserEnum.Initial}
                      onChange={({ target }) =>
                        this.setAcceptedUser(Number(target.value))
                      }
                      checked={Number(accepted) === AcceptedUserEnum.Initial}
                    />
                  </Col>
                </Row>
                <Form.Control
                  disabled={
                    !accepted || Number(accepted) === AcceptedUserEnum.Tester
                  }
                  value={holdXbsWebsite}
                  onChange={({ target }) => this.setWebsite(target.value)}
                  onBlur={() => this.validateWebsite()}
                />
              </Form.Group>
            </Col>
            <Col sm>
              <Form.Group>
                <Row>
                  <Col sm={10}>
                    <Form.Label className="font-weight-bold">
                      Tester XBS Website{' '}
                      {testXbsWebsite ? (
                        <a
                          rel="noreferrer"
                          href={formatUrl(testXbsWebsite)}
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </a>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                  </Col>
                  <Col sm={2}>
                    <Form.Check
                      id={`${AcceptedUserEnum.Initial}-tester-website`}
                      type="radio"
                      label="Best"
                      value={AcceptedUserEnum.Tester}
                      onChange={({ target }) =>
                        this.setAcceptedUser(Number(target.value))
                      }
                      checked={Number(accepted) === AcceptedUserEnum.Tester}
                    />
                  </Col>
                </Row>
                <Form.Control
                  disabled={
                    !accepted || Number(accepted) === AcceptedUserEnum.Initial
                  }
                  value={testXbsWebsite}
                  onChange={({ target }) => this.setWebsite(target.value)}
                  onBlur={() => this.validateWebsite()}
                />
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm={4}>
              <DiscardReason
                discardReasons={serviceProviderFindingDiscardReasons}
                readonly={true}
                selected={holdDiscardReason}
                handleChange={() => {}}
                label="Finder Discard Reason"
              />
            </Col>
            <Col sm={4}>
              <DiscardReason
                discardReasons={serviceProviderFindingDiscardReasons}
                readonly={true}
                selected={testDiscardReason}
                label="Tester Discard Reason"
                handleChange={() => {}}
              />
            </Col>
            <Col sm={4}>
              <Row>
                <DiscardReason
                  discardReasons={serviceProviderFindingDiscardReasons}
                  handleChange={reason => this.setDiscardReason(reason)}
                  selected={discardReason}
                  label="Reviewer Discard Reason"
                />
              </Row>
            </Col>
          </Row>
          <hr />
          <TaskPerformersInfo {...{ doneBy, testedBy }} />
          <hr />
          <Row className="form-container-actions pr-3 justify-content-end">
            <Button
              className="btn btn-purple"
              onClick={() => this.saveAndNext()}
              disabled={isSubmitting}
            >
              <FontAwesomeIcon icon={faArrowRight} /> {btnSaveText}
            </Button>
          </Row>
        </Container>
      </Form>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FindingReviewServiceProvider)
