export const SET_ERRORS = 'SET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export interface SetErrors {
  type: typeof SET_ERRORS
  payload: any
}

export interface clearErrors {
  type: typeof CLEAR_ERRORS
}

export interface ErrorState {
  errors: any
}
