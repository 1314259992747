import React from 'react'
import { Container, Form, Col, Row } from 'react-bootstrap'
import { SourceTypeDatabaseEnum } from '../../../../shared/models/'

const CompanyInfoServiceProvider = ({
  company,
  isFinding,
  xbsWebsite = '',
}) => (
  <Container fluid className="company-info align-middle">
    <Row>
      <Form.Group as={Row} className="col-sm-12">
        <Col sm={2}>
          <Form.Label>Country</Form.Label>
        </Col>
        <Col sm={4}>
          <p className="font-weight-bold">{company.country}</p>
        </Col>
        <Col sm={2}>
          <Form.Label>Business Name</Form.Label>
        </Col>
        <Col sm={4}>
          <p className="font-weight-bold">{company.name}</p>
        </Col>
      </Form.Group>
    </Row>
    <Row>
      <Form.Group as={Row} className="col-sm-12">
        <Col sm={2}>
          <Form.Label>Identifier</Form.Label>
        </Col>
        <Col sm={2}>
          <p className="font-weight-bold">{company.identifier}</p>
        </Col>
        <Col sm={2}>
          <Form.Label>Identifier Source</Form.Label>
        </Col>
        <Col sm={2}>
          <p className="font-weight-bold">
            {SourceTypeDatabaseEnum[company.identifierSource]}
          </p>
        </Col>
        <Col sm={2}>
          <Form.Label>Sic</Form.Label>
        </Col>
        <Col sm={2}>
          <p className="font-weight-bold">{company.sic}</p>
        </Col>
      </Form.Group>
    </Row>
    <Row>
      <Form.Group as={Row} className="col-sm-12 no-padding">
        <Col sm={6}>
          <Col sm={12}>
            <Form.Label>Line Of Business</Form.Label>
          </Col>
          <Col sm={12} className="scroll-box">
            <p className="font-weight-bold">{company.lineOfBusiness}</p>
          </Col>
        </Col>

        <Col sm={6}>
          <Col sm={12}>
            <Form.Label>Description</Form.Label>
          </Col>
          <Col sm={12} className="scroll-box">
            <p className="font-weight-bold">{company.description}</p>
          </Col>
        </Col>
      </Form.Group>
    </Row>
    {isFinding ? (
      <div />
    ) : (
      <Row>
        <Form.Group as={Row} className="col-sm-12">
          <Col sm={2}>
            <Form.Label>Website</Form.Label>
          </Col>
          <Col sm={10}>
            <a
              rel="noreferrer"
              className="font-weight-bold"
              href={company.url || xbsWebsite}
              target="_blank"
            >
              {company.url || xbsWebsite}
            </a>
          </Col>
        </Form.Group>
      </Row>
    )}
  </Container>
)

export default CompanyInfoServiceProvider
