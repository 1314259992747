import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Container, Col, Row, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import DiscardReason from '../../shared/DiscardReason'
import ValueAddedDistributor from '../../shared/ValueAddedDistributor'
import CompanyInfoReviewDistributor from '../../shared/company-info/distributor/CompanyInfoReviewDistributor'
import { btnSaveText, btnLoadingText } from '../../../shared/constants'
import { taggingReviewRoute } from '../../../shared/constants'
import {
  SidebarActiveItem,
  Company,
  ShowValueAddedEnum,
  User,
  FrontEndError,
  NoDataDisplayStateEnum,
  AcceptedUserEnum,
  distributorTaggingDiscardReasons,
  AppModeEnum,
  DistributorDiscardReasonEnum,
} from '../../../shared/models'

import {
  updateSidebarActiveItem,
  setErrors,
  toggleNoDataPage,
} from '../../../store/actions'
import { AppState } from '../../../store'

import {
  getNextTaggingReviewTask,
  validateDiscardReason,
  validateShowValueAdded,
  validateStringLength,
  saveTaggingReview,
  cancelActiveRequests,
} from '../../../services'
import TaskPerformersInfo from '../../shared/TaskPerformersInfo'
import {
  getWebSocket,
  sendMessage,
} from '../../../services/base/websocket-service'

interface State {
  xbsWebsite: string
  doneBy: User
  testedBy: User
  holdXbsDescription: string
  testXbsDescription: string
  discardExplanation: string
  holdDiscardExplanation: string
  testDiscardExplanation: string
  holdDiscardReason: DistributorDiscardReasonEnum
  testDiscardReason: DistributorDiscardReasonEnum
  holdShowValueAdded: ShowValueAddedEnum
  testShowValueAdded: ShowValueAddedEnum
  holdShowValueAddedDescription: string
  testShowValueAddedDescription: string
  showValueAddedDescription: string
  company: Company
  taskId?: number
  description: string
  discardReason?: DistributorDiscardReasonEnum
  showValueAdded?: ShowValueAddedEnum
  accepted?: AcceptedUserEnum
  acceptedDiscardExplanation?: AcceptedUserEnum
  showValueAddedDescriptionAccepted?: AcceptedUserEnum
  isSubmitting: boolean
  btnSaveText: string
}

interface DispatchProps {
  updateSidebarActiveItem: (key: SidebarActiveItem) => void
  toggleNoDataPageDisplayState: (state: NoDataDisplayStateEnum) => void
}

type Props = AppState & DispatchProps

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = dispatch => ({
  updateSidebarActiveItem: (key: SidebarActiveItem) => {
    dispatch(updateSidebarActiveItem(key))
  },
  toggleNoDataPageDisplayState: (state: NoDataDisplayStateEnum) => {
    dispatch(toggleNoDataPage(taggingReviewRoute.eventKey, state))
  },
})

class TaggingReviewDistributor extends Component<Props, State> {
  ws: WebSocket | null

  constructor(props) {
    super(props)
    this.ws = null
    this.state = {
      xbsWebsite: '',
      holdDiscardReason: DistributorDiscardReasonEnum.NotDiscarded,
      testDiscardReason: DistributorDiscardReasonEnum.NotDiscarded,
      holdShowValueAdded: ShowValueAddedEnum.No,
      testShowValueAdded: ShowValueAddedEnum.No,
      holdShowValueAddedDescription: '',
      testShowValueAddedDescription: '',
      showValueAddedDescription: '',
      holdXbsDescription: '',
      testXbsDescription: '',
      description: '',
      doneBy: {} as User,
      testedBy: {} as User,
      isSubmitting: false,
      btnSaveText,
      company: {
        taggingId: '',
        identifier: '',
        identifierSource: '',
        name: '',
        country: '',
        city: '',
        postalCode: '',
        lineOfBusiness: '',
        description: '',
        url: '',
        sic: '',
        classifications: [],
      },
      discardExplanation: '',
      holdDiscardExplanation: '',
      testDiscardExplanation: '',
    }
  }

  async componentDidMount(): Promise<void> {
    await cancelActiveRequests()
    const user = JSON.parse(localStorage.getItem('user') as string)
    this.ws = await getWebSocket(
      `refresh?userId=${
        user.userId
      }&section=taggingReview&comp_type=distributor`
    )
    if (this.ws) {
      this.ws.onmessage = () => {
        this.reload()
      }
    }
    this.props.updateSidebarActiveItem(taggingReviewRoute.eventKey)
    const nextTaggingRewiewTaskResult = await getNextTaggingReviewTask(
      AppModeEnum.Distributors
    )
    const nextTask = nextTaggingRewiewTaskResult
      ? nextTaggingRewiewTaskResult.data
      : {}
    if (!nextTask) {
      this.props.toggleNoDataPageDisplayState(NoDataDisplayStateEnum.Show)
    } else {
      this.setState({
        ...nextTask,
      })
    }
  }

  componentWillUnmount(): void {
    this.props.toggleNoDataPageDisplayState(NoDataDisplayStateEnum.Hide)
    if (this.ws) {
      this.ws.close()
    }
  }

  validateDescription(): boolean {
    if (!this.state.accepted) {
      setErrors(
        new FrontEndError('description', 'Description must be selected.')
      )
      return false
    }
    if (!validateStringLength(this.state.description, 1)) {
      setErrors(new FrontEndError('description', 'Description is required.'))
      return false
    }

    return true
  }

  validateDiscardReason(): boolean {
    if (!validateDiscardReason(this.state.discardReason)) {
      setErrors(
        new FrontEndError(
          'discardReason',
          'Reviewer Discard Reason is required.'
        )
      )
      return false
    }

    return true
  }

  validateDiscardExplanation(): boolean {
    if (
      Number(this.state.discardReason) !==
        DistributorDiscardReasonEnum.NotDiscarded &&
      !this.state.acceptedDiscardExplanation
    ) {
      setErrors(
        new FrontEndError(
          'description',
          'Discard Explanation must be selected.'
        )
      )
      return false
    }
    return true
  }

  validateShowValueAdded(): boolean {
    if (!validateShowValueAdded(this.state.showValueAdded)) {
      setErrors(
        new FrontEndError(
          'showValueAdded',
          'Reviewer Show Value Added is required.'
        )
      )
      return false
    }

    return true
  }

  validateShowValueAddedDescription(): boolean {
    if (Number(this.state.showValueAdded) === ShowValueAddedEnum.No) {
      return true
    }
    if (!this.state.showValueAddedDescription) {
      setErrors(
        new FrontEndError(
          'description',
          'Show value added description must be selected.'
        )
      )
      return false
    }
    if (!validateStringLength(this.state.showValueAddedDescription, 1)) {
      setErrors(
        new FrontEndError(
          'description',
          'Show value added description is required.'
        )
      )
      return false
    }
    return true
  }

  initializeValueAddedDescriptions(data: Record<string, unknown>): void {
    if (!data.holdShowValueAddedDescription) {
      data.holdShowValueAddedDescription = ''
    }
    if (!data.testShowValueAddedDescription) {
      data.testShowValueAddedDescription = ''
    }
  }

  initializeDiscardExplanations(data: Record<string, unknown>): void {
    data.holdDiscardExplanation = data.holdDiscardExplanation || ''
    data.testDiscardExplanation = data.testDiscardExplanation || ''
  }

  disableButton(): void {
    this.setState({
      isSubmitting: true,
      btnSaveText: btnLoadingText,
    })
  }

  async reload(): Promise<void> {
    const nextTaggingRewiewTaskResult = await getNextTaggingReviewTask(
      AppModeEnum.Distributors
    )
    let nextTask = nextTaggingRewiewTaskResult
      ? nextTaggingRewiewTaskResult.data
      : null
    if (!nextTask) {
      this.props.toggleNoDataPageDisplayState(NoDataDisplayStateEnum.Show)
    } else {
      this.initializeValueAddedDescriptions(nextTask)
      this.initializeDiscardExplanations(nextTask)
      this.setState({
        ...nextTask,
        discardReason: undefined,
        showValueAdded: undefined,
        accepted: undefined,
        acceptedDiscardExplanation: undefined,
        showValueAddedDescriptionAccepted: undefined,
        description: '',
        showValueAddedDescription: '',
        isSubmitting: false,
        btnSaveText,
        discardExplanation: '',
      })
    }
  }

  async saveAndNext(): Promise<void> {
    if (
      !this.validateDescription() ||
      !this.validateDiscardReason() ||
      !this.validateDiscardExplanation() ||
      !this.validateShowValueAdded() ||
      !this.validateShowValueAddedDescription()
    ) {
      return
    }

    const {
      description,
      discardReason,
      discardExplanation,
      showValueAdded,
      holdShowValueAddedDescription,
      testShowValueAddedDescription,
      showValueAddedDescription,
      company,
      // taskId,
    } = this.state
    this.disableButton()
    const result = await saveTaggingReview(
      {
        description,
        discardReason,
        discardExplanation,
        showValueAdded,
        holdShowValueAddedDescription,
        testShowValueAddedDescription,
        showValueAddedDescription,
      },
      company.taggingId,
      AppModeEnum.Distributors
    )
    if (result) {
      await this.reload()
      if (this.ws) {
        sendMessage(this.ws, {
          newTask: true,
        })
      }
    }
  }

  setDescription(description: string): void {
    if (Number(this.state.accepted) === AcceptedUserEnum.Initial) {
      this.setState({
        holdXbsDescription: description,
        description,
      })
      return
    }
    this.setState({
      testXbsDescription: description,
      description,
    })
  }

  setDiscardReason(discardReason: DistributorDiscardReasonEnum): void {
    this.setState({
      discardReason,
    })
  }

  setDiscardExplanation(discardExplanation: string): void {
    if (
      Number(this.state.acceptedDiscardExplanation) === AcceptedUserEnum.Initial
    ) {
      this.setState({
        holdDiscardExplanation: discardExplanation,
        discardExplanation,
      })
      return
    }
    this.setState({
      testDiscardExplanation: discardExplanation,
      discardExplanation,
    })
  }

  setShowValueAdded(showValueAdded: ShowValueAddedEnum): void {
    this.setState({
      showValueAdded,
    })
  }

  setAcceptedShowValueAddedUser(accepted: AcceptedUserEnum): void {
    this.setState({
      showValueAddedDescriptionAccepted: accepted,
      showValueAddedDescription:
        Number(accepted) === AcceptedUserEnum.Initial
          ? this.state.holdShowValueAddedDescription
          : this.state.testShowValueAddedDescription,
    })
  }

  setHoldShowValueAddedDescription(
    holdShowValueAddedDescription: string
  ): void {
    this.setState({
      holdShowValueAddedDescription,
      showValueAddedDescription:
        Number(this.state.showValueAddedDescriptionAccepted) ===
        AcceptedUserEnum.Initial
          ? holdShowValueAddedDescription
          : this.state.showValueAddedDescription,
    })
  }

  setTestShowValueAddedDescription(
    testShowValueAddedDescription: string
  ): void {
    this.setState({
      testShowValueAddedDescription,
      showValueAddedDescription:
        Number(this.state.showValueAddedDescriptionAccepted) ===
        AcceptedUserEnum.Tester
          ? testShowValueAddedDescription
          : this.state.showValueAddedDescription,
    })
  }

  setAcceptedUser(accepted: AcceptedUserEnum): void {
    this.setState({
      accepted,
      description:
        Number(accepted) === AcceptedUserEnum.Initial
          ? this.state.holdXbsDescription
          : this.state.testXbsDescription,
    })
  }

  setAcceptedDiscardExplanation(
    acceptedDiscardExplanation: AcceptedUserEnum
  ): void {
    this.setState({
      acceptedDiscardExplanation,
      discardExplanation:
        Number(acceptedDiscardExplanation) === AcceptedUserEnum.Initial
          ? this.state.holdDiscardExplanation
          : this.state.testDiscardExplanation,
    })
  }

  render(): JSX.Element {
    const {
      noData: { state },
    } = this.props

    if (state === NoDataDisplayStateEnum.Show) {
      return <div />
    }

    if (!this.state.company || !this.state.company.identifier) {
      return <div />
    }

    const {
      xbsWebsite,
      holdXbsDescription,
      testXbsDescription,
      holdDiscardReason,
      testDiscardReason,
      holdShowValueAdded,
      testShowValueAdded,
      holdShowValueAddedDescription,
      testShowValueAddedDescription,
      showValueAddedDescriptionAccepted,
      discardReason,
      showValueAdded,
      company,
      doneBy,
      testedBy,
      accepted,
      acceptedDiscardExplanation,
      holdDiscardExplanation,
      testDiscardExplanation,
      isSubmitting,
      btnSaveText,
    } = this.state

    return (
      <Form className="form-container">
        <Container fluid className="margin-top-75 padding-bottom-30">
          <CompanyInfoReviewDistributor
            {...{ company, xbsWebsite, testXbsWebsite: xbsWebsite }}
          />
          <hr />
          <Row>
            <Col sm>
              <Form.Group>
                <Row>
                  <Col sm={10}>
                    <Form.Label className="font-weight-bold">
                      Tagger XBS Info
                    </Form.Label>
                  </Col>
                  <Col sm={2}>
                    <Form.Check
                      id={`${AcceptedUserEnum.Initial}-tagger-website`}
                      type="radio"
                      label="Best"
                      value={AcceptedUserEnum.Initial}
                      onChange={({ target: { value } }) =>
                        this.setAcceptedUser(value)
                      }
                      checked={Number(accepted) === AcceptedUserEnum.Initial}
                    />
                  </Col>
                </Row>
                <Form.Control
                  as="textarea"
                  rows={4}
                  disabled={
                    !accepted || Number(accepted) === AcceptedUserEnum.Tester
                  }
                  value={holdXbsDescription}
                  onChange={({ target }) => this.setDescription(target.value)}
                  onBlur={() => this.validateDescription()}
                />
              </Form.Group>
            </Col>
            <Col sm>
              <Form.Group>
                <Row>
                  <Col sm={10}>
                    <Form.Label className="font-weight-bold">
                      Tester XBS Info
                    </Form.Label>
                  </Col>
                  <Col sm={2}>
                    <Form.Check
                      id={`${AcceptedUserEnum.Tester}-tester-website`}
                      type="radio"
                      label="Best"
                      value={AcceptedUserEnum.Tester}
                      onChange={({ target: { value } }) =>
                        this.setAcceptedUser(value)
                      }
                      checked={Number(accepted) === AcceptedUserEnum.Tester}
                    />
                  </Col>
                </Row>
                <Form.Control
                  as="textarea"
                  rows={4}
                  disabled={
                    !accepted || Number(accepted) === AcceptedUserEnum.Initial
                  }
                  value={testXbsDescription}
                  onChange={({ target }) => this.setDescription(target.value)}
                  onBlur={() => this.validateDescription()}
                />
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm={4}>
              <DiscardReason
                discardReasons={distributorTaggingDiscardReasons}
                readonly={true}
                selected={holdDiscardReason}
                handleChange={() => {}}
                label="Tagger Discard Reason"
              />
            </Col>
            <Col sm={4}>
              <DiscardReason
                discardReasons={distributorTaggingDiscardReasons}
                readonly={true}
                selected={testDiscardReason}
                label="Tester Discard Reason"
                handleChange={() => {}}
              />
            </Col>
            <Col sm={4}>
              <Row>
                <DiscardReason
                  discardReasons={distributorTaggingDiscardReasons}
                  handleChange={reason => this.setDiscardReason(reason)}
                  selected={discardReason}
                  label="Reviewer Discard Reason"
                />
              </Row>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm>
              <Form.Group>
                <Row>
                  <Col sm={10}>
                    <Form.Label className="font-weight-bold">
                      Tagger Discard Explanation
                    </Form.Label>
                  </Col>
                  <Col sm={2}>
                    <Form.Check
                      id={`${
                        AcceptedUserEnum.Initial
                      }-tagger-discard-explanation`}
                      type="radio"
                      label="Best"
                      value={AcceptedUserEnum.Initial}
                      onChange={({ target: { value } }) => {
                        console.log(value)
                        this.setAcceptedDiscardExplanation(value)
                      }}
                      checked={
                        Number(acceptedDiscardExplanation) ===
                        AcceptedUserEnum.Initial
                      }
                    />
                  </Col>
                </Row>
                <Form.Control
                  as="textarea"
                  rows={4}
                  disabled={
                    !acceptedDiscardExplanation ||
                    Number(acceptedDiscardExplanation) ===
                      AcceptedUserEnum.Tester
                  }
                  value={holdDiscardExplanation}
                  onChange={({ target }) =>
                    this.setDiscardExplanation(target.value)
                  }
                  onBlur={() => this.validateDiscardExplanation()}
                />
              </Form.Group>
            </Col>
            <Col sm>
              <Form.Group>
                <Row>
                  <Col sm={10}>
                    <Form.Label className="font-weight-bold">
                      Tester Discard Explanation
                    </Form.Label>
                  </Col>
                  <Col sm={2}>
                    <Form.Check
                      id={`${
                        AcceptedUserEnum.Initial
                      }-tester-discard-explanation`}
                      type="radio"
                      label="Best"
                      value={AcceptedUserEnum.Tester}
                      onChange={({ target: { value } }) =>
                        this.setAcceptedDiscardExplanation(value)
                      }
                      checked={
                        Number(acceptedDiscardExplanation) ===
                        AcceptedUserEnum.Tester
                      }
                    />
                  </Col>
                </Row>
                <Form.Control
                  as="textarea"
                  rows={4}
                  disabled={
                    !acceptedDiscardExplanation ||
                    Number(acceptedDiscardExplanation) ===
                      AcceptedUserEnum.Initial
                  }
                  value={testDiscardExplanation}
                  onChange={({ target }) =>
                    this.setDiscardExplanation(target.value)
                  }
                  onBlur={() => this.validateDiscardExplanation()}
                />
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm={4}>
              <ValueAddedDistributor
                readonly={true}
                selected={holdShowValueAdded}
                label="Tagger VAD"
                handleChange={() => {}}
              />
            </Col>
            <Col sm={4}>
              <ValueAddedDistributor
                readonly={true}
                selected={testShowValueAdded}
                label="Tester VAD"
                handleChange={() => {}}
              />
            </Col>
            <Col sm={4}>
              <ValueAddedDistributor
                label="Reviewer VAD"
                handleChange={showValueAdded =>
                  this.setShowValueAdded(showValueAdded)
                }
                selected={showValueAdded}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm>
              <Form.Group>
                <Row>
                  <Col sm={10}>
                    <Form.Label className="font-weight-bold">
                      Tagger Show Value Added
                    </Form.Label>
                  </Col>
                  <Col sm={2}>
                    <Form.Check
                      id={`${AcceptedUserEnum.Initial}-tagger-added-value`}
                      type="radio"
                      label="Best"
                      value={AcceptedUserEnum.Initial}
                      onChange={({ target: { value } }) =>
                        this.setAcceptedShowValueAddedUser(value)
                      }
                      checked={
                        Number(showValueAddedDescriptionAccepted) ===
                        AcceptedUserEnum.Initial
                      }
                    />
                  </Col>
                </Row>
                <Form.Control
                  as="textarea"
                  rows={4}
                  onChange={({ target }) =>
                    this.setHoldShowValueAddedDescription(target.value)
                  }
                  value={holdShowValueAddedDescription}
                />
              </Form.Group>
            </Col>
            <Col sm>
              <Form.Group>
                <Row>
                  <Col sm={10}>
                    <Form.Label className="font-weight-bold">
                      Tester Show Value Added
                    </Form.Label>
                  </Col>
                  <Col sm={2}>
                    <Form.Check
                      id={`${AcceptedUserEnum.Initial}-tester-added-value`}
                      type="radio"
                      label="Best"
                      value={AcceptedUserEnum.Tester}
                      onChange={({ target: { value } }) =>
                        this.setAcceptedShowValueAddedUser(value)
                      }
                      checked={
                        Number(showValueAddedDescriptionAccepted) ===
                        AcceptedUserEnum.Tester
                      }
                    />
                  </Col>
                </Row>
                <Form.Control
                  as="textarea"
                  rows={4}
                  onChange={({ target }) =>
                    this.setTestShowValueAddedDescription(target.value)
                  }
                  value={testShowValueAddedDescription}
                />
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <TaskPerformersInfo {...{ doneBy, testedBy }} />
          <hr />
          <Row className="form-container-actions pr-3 justify-content-end">
            <Button
              className="btn btn-purple"
              onClick={() => this.saveAndNext()}
              disabled={isSubmitting}
            >
              <FontAwesomeIcon icon={faArrowRight} /> {btnSaveText}
            </Button>
          </Row>
        </Container>
      </Form>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaggingReviewDistributor)
