import { UPDATE_ACTIVE_ITEM } from '../types'
import { SidebarActiveItem } from '../../shared/models'
import { clearErrors } from './errorActions'

export const updateSidebarActiveItem = (
  activeItem: SidebarActiveItem
) => dispatch => {
  clearErrors()
  dispatch({
    type: UPDATE_ACTIVE_ITEM,
    payload: { activeItem },
  })
}
