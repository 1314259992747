import {
  ErrorState,
  clearErrors,
  CLEAR_ERRORS,
  SET_ERRORS,
  SetErrors,
} from '../types'

const initialState: ErrorState = {
  errors: {},
}

export function errorReducer(
  state = initialState,
  action: SetErrors | clearErrors
): ErrorState {
  switch (action.type) {
    case SET_ERRORS: {
      return {
        ...state.errors,
        ...action.payload,
      }
    }
    case CLEAR_ERRORS: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}
