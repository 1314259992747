import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Col, Form, Button, Modal as BoostrapModal } from 'react-bootstrap'
import { keywordToDistributeComps } from '../../../../shared/constants'
import SelectBox from '../../../shared/SelectBox'
import { SelectValue } from '../../../../shared/models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faRedo } from '@fortawesome/free-solid-svg-icons'
import styles from './DistributeCompsModal.module.css'
import { getRegularUsers, formatUserDataForLabel } from '../../../../services'

interface State {
  createBatch: boolean
  distributeToUser: boolean
  users: SelectValue[]
  confirmationText: string
}
interface Props {
  open: boolean
  handleEvent: (eventToTrigger: string, eventParams?: object) => void
  setBatchName: (batchName: string) => void
  batchName: string
  setDestinationUserId: (destionationUserID: number) => void
  destinationUserId: number
}

class DistributeCompsModal extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      createBatch: false,
      distributeToUser: false,
      confirmationText: '',
    }
  }

  async getUsers(): Promise<void> {
    const regularUsersResult = await getRegularUsers()
    const regularUsers = regularUsersResult ? regularUsersResult.data : []
    this.setState({
      users: regularUsers.map(user => {
        return {
          label: formatUserDataForLabel(user),
          value: user.userId,
        }
      }),
    })
  }
  componentDidMount = async () => {
    await this.getUsers()
  }

  handleChangeConfirmationChange = e => {
    this.setState({
      confirmationText: e.target.value,
    })
  }

  changeCreateBatch = e => {
    this.setState({
      createBatch: e.target.checked,
    })
    if (!e.target.checked) {
      this.props.setBatchName('')
    }
  }

  changeDistributeToUser = e => {
    this.setState({
      distributeToUser: e.target.checked,
    })
    if (!e.target.checked) {
      this.props.setDestinationUserId(0)
    }
  }

  verifyBatchName = e => {
    this.props.setBatchName(e.target.value)
  }
  handleUserChange(destinationUserId: number): void {
    this.props.setDestinationUserId(destinationUserId)
  }

  handleExportClick = () => {
    const { handleEvent } = this.props
    this.setState(
      {
        createBatch: false,
        distributeToUser: false,
        confirmationText: '',
      },
      () => handleEvent('handleExportListedCompsClick')
    )
  }

  render(): JSX.Element | null {
    const { open, handleEvent, batchName, destinationUserId } = this.props
    const {
      users,
      createBatch,
      distributeToUser,
      confirmationText,
    } = this.state

    const emptyBatchName = createBatch && batchName === ''
    const emptyDestinationUserId = distributeToUser && destinationUserId === 0
    const confimationTextNotMatch =
      confirmationText !== keywordToDistributeComps

    const isDisabledConfirmButton =
      emptyBatchName || emptyDestinationUserId || confimationTextNotMatch

    return (
      <div>
        <BoostrapModal
          show={open}
          onHide={() => handleEvent('handleToggleisDistributeCompsModalOpen')}
          size="lg"
          centered
          className="compendium-modal"
        >
          <BoostrapModal.Header className="tagging-modal-header">
            <BoostrapModal.Title className="tagging-modal-header-title">
              Warning
            </BoostrapModal.Title>
          </BoostrapModal.Header>
          <BoostrapModal.Body>
            <div>
              Distributing the selection of filtered comps in the table will
              send them out to the tagging process to be completely retagged.
              Are you sure you want to proceed?
            </div>
            <Form.Group controlId="formBasicEmail">
              <div className={styles['checkbox-container']}>
                <Form.Text className={`${styles['checkbox-label']} text-muted`}>
                  Create <br /> Batch
                </Form.Text>
                <Form.Control
                  type="checkbox"
                  checked={createBatch}
                  onChange={this.changeCreateBatch}
                  className={styles['batch-checkbox']}
                />
              </div>
              <Form.Text className="text-muted">
                Please type the Batch Name
              </Form.Text>
              <Form.Control
                type="text"
                placeholder=""
                disabled={!createBatch}
                onChange={this.verifyBatchName}
                className="tagging-modal-input-border"
                value={batchName}
              />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <div className={styles['checkbox-container']}>
                <Form.Text className={`${styles['checkbox-label']} text-muted`}>
                  Distribute <br /> to tagger
                </Form.Text>
                <Form.Control
                  type="checkbox"
                  checked={distributeToUser}
                  onChange={this.changeDistributeToUser}
                  className={styles['batch-checkbox']}
                />
              </div>
              <Form.Text className="text-muted">
                Please choose the user to distribute
              </Form.Text>
              <Col>
                <SelectBox
                  values={users}
                  onSelect={({ value }) => this.handleUserChange(value)}
                  initialLabel="Select User"
                  inline={false}
                  disabled={!distributeToUser}
                  selected={String(destinationUserId) || ''}
                />
              </Col>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Text className="text-muted">
                Please type "{keywordToDistributeComps}" to continue
              </Form.Text>
              <Form.Control
                type="text"
                placeholder="Confirmation Text"
                onChange={this.handleChangeConfirmationChange}
                className="tagging-modal-input-border"
                value={confirmationText}
              />
            </Form.Group>
          </BoostrapModal.Body>
          <BoostrapModal.Footer>
            <Button
              variant="primary"
              className="tagging-modal-button"
              onClick={this.handleExportClick}
              disabled={isDisabledConfirmButton}
            >
              <FontAwesomeIcon icon={faCheck} /> Confirm
            </Button>

            <Button
              variant="primary"
              className="tagging-modal-button"
              onClick={() =>
                handleEvent('handleToggleisDistributeCompsModalOpen')
              }
            >
              <FontAwesomeIcon icon={faRedo} /> Cancel
            </Button>
          </BoostrapModal.Footer>
        </BoostrapModal>
      </div>
    )
  }
}

export default DistributeCompsModal
