import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, Row, Col, Button } from 'react-bootstrap'
import { Redirect } from 'react-router'

import { AppModeEnum } from '../../shared/models'
import { updateAppMode } from '../../store/actions'
import { AuthService } from '../../services/auth-service'

import './ModeSelection.css'
import { AppModeState } from '../../store/types'

interface State {
  loggedOut: boolean
}

interface DispatchProps {
  updateAppMode(activeMode: AppModeEnum): void
}

interface StateProps {
  appMode: AppModeState
}

type Props = StateProps & DispatchProps

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = dispatch => ({
  updateAppMode: (activeMode: AppModeEnum) => {
    dispatch(updateAppMode(activeMode))
  },
})

class ModeSelection extends Component<Props, State> {
  state: State = {
    loggedOut: false,
  }

  async logout(): Promise<void> {
    const authService = AuthService.getInstance()
    await authService.logout()
    this.setState(() => ({
      loggedOut: true,
    }))
    this.props.updateAppMode(AppModeEnum.NotSelected)
  }

  selectMode(modeSelected: AppModeEnum): void {
    this.props.updateAppMode(modeSelected)
  }

  render(): JSX.Element {
    if (this.state.loggedOut) {
      return <Redirect to="/login" />
    }

    if (this.props.appMode.activeMode !== AppModeEnum.NotSelected) {
      return <Redirect to="/" />
    }

    return (
      <div className="mode-selection">
        <Modal
          className="mode-selection-modal"
          show={true}
          onHide={() => {}}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton={false}>
            <Modal.Title>
              <h3>Select mode</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={6}>
                <Button
                  type="submit"
                  className="btn-block"
                  onClick={() => {
                    this.selectMode(AppModeEnum.Distributors)
                  }}
                >
                  Distributors
                </Button>
              </Col>
              <Col sm={6}>
                <Button
                  type="submit"
                  className="btn-block"
                  onClick={() => {
                    this.selectMode(AppModeEnum.ServiceProviders)
                  }}
                >
                  Service Providers
                </Button>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="nav-link" onClick={() => this.logout()}>
              Logout
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModeSelection)
