import { get } from './base/base-http-service'
import {
  SystemQueues,
  RegularUserQueue,
  ManagerQueue,
  AppModeEnum,
} from '../shared/models'

interface SystemQueuesResponse {
  data: SystemQueues
}

interface RegularUserQueuesResponse {
  data: RegularUserQueue[]
}

interface ManagerQueuesResponse {
  data: ManagerQueue[]
}

export const getSystemQueuesStatus = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected
): Promise<SystemQueuesResponse> => {
  return get(`${getBase(activeMode)}/system`)
}

export const getManagerQueuesStatus = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected
): Promise<ManagerQueuesResponse> => {
  return get(`${getBase(activeMode)}/manager`)
}

export const getUserQueuesStatus = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected
): Promise<RegularUserQueuesResponse> => {
  return get(`${getBase(activeMode)}/regular`)
}

const getBase = (activeMode: AppModeEnum): string => {
  if (activeMode === AppModeEnum.Distributors) {
    return `status/distributor`
  } else if (activeMode === AppModeEnum.ServiceProviders) {
    return `status/service-provider`
  }
  return ''
}
