import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'

interface Props {
  value: string
  label: string
  type?: string
  disabled: boolean
  onChange: Function
}

const FormInput = ({
  type = 'text',
  value,
  label,
  disabled,
  onChange,
}: Props): JSX.Element => (
  <Row className="manage-logins form-row">
    <Col sm={4}>
      <Form.Label className="font-weight-bold">{label}</Form.Label>
    </Col>
    <Col sm={8}>
      <Form.Control
        type={type}
        disabled={disabled}
        placeholder={label}
        value={value}
        onChange={({ target }) => onChange(target)}
      />
    </Col>
  </Row>
)

export default FormInput
