import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import Pagination from '../../../shared/pagination/Pagination'

import { TaggersDailyProgressReport } from '../../../../shared/models'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

type Props = {
  reportsData: {
    reports: TaggersDailyProgressReport[]
    totalReportsFound: number
  }
  handleEvent: (eventToTrigger: string, eventParams?: object) => void
}

class TaggersDailyReportTable extends Component<Props> {
  handlePagination = paginationParams => {
    const { handleEvent } = this.props

    handleEvent('handlePagination', paginationParams)
  }

  renderReportsRows = () => {
    const { reportsData } = this.props
    return reportsData.reports.map(this.renderRow)
  }

  formatDate = date => {
    if (date !== null && typeof date !== 'undefined' && date.length > 0) {
      const d = new Date(date)
      return moment(d).format('MM-DD-YYYY')
    } else {
      return date
    }
  }
  renderRow = report => {
    return (
      <tr key={report.reportId} className="paginated-table-row">
        <td>{this.formatDate(report.date)}</td>
        <td className="download-repository-actions">
          {report.url ? (
            <a href={report.url}>
              <span>
                <FontAwesomeIcon
                  icon={faDownload}
                  className="download-repository-download-icon"
                />
              </span>
            </a>
          ) : null}
        </td>
      </tr>
    )
  }

  renderPagination = () => {
    const { reportsData } = this.props

    if (reportsData.reports.length > 0) {
      return (
        <Pagination
          numberOfItems={reportsData.totalReportsFound}
          onPaginationChange={this.handlePagination}
        />
      )
    }
  }

  renderLastRow = () => {
    const { reportsData } = this.props

    if (reportsData.reports.length === 0) {
      return (
        <tr className="table-last-row">
          <td colSpan={12}>
            Whoops! There is no data in here. Try applying some filters.
          </td>
        </tr>
      )
    }

    return (
      <tr className="table-last-row">
        <td>
          Number of records found:
          <span className="number-of-comps-listed">
            {reportsData.totalReportsFound}
          </span>
        </td>
      </tr>
    )
  }

  render(): JSX.Element {
    return (
      <div className="container-paginated-table">
        <Table striped bordered responsive="lg" className="paginated-table">
          <thead className="paginated-table-head">
            <tr>
              <th>Report Date</th>
              <th />
            </tr>
          </thead>
          <tbody className="paginated-table-body">
            {this.renderReportsRows()}
            {this.renderLastRow()}
          </tbody>
        </Table>

        {this.renderPagination()}
      </div>
    )
  }
}

export default TaggersDailyReportTable
