import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { AuthService } from '../../services'
import './Login.css'
import { connect } from 'react-redux'
import { AppState } from '../../store'
import { ProductNames } from '@xbs/xbs-enums'

interface State {}

interface DispatchProps {}

type Props = AppState &
  DispatchProps & {
    location: {
      search: string
    }
  }

const mapStateToProps = (state: AppState) => ({ ...state })
const mapDispatchToProps = () => ({})

class Login extends Component<Props, State> {
  authService: AuthService

  constructor(props) {
    super(props)
    this.authService = AuthService.getInstance()
  }

  async componentDidMount(): Promise<void> {
    if (this.isRedirectFromLoginUI()) {
      this.authService.onLoginSuccess()
    } else {
      !this.authService.isLoggedIn() && this.authService.login()
    }
  }

  isRedirectFromLoginUI = (): boolean => {
    return this.authService.isRedirectFromLogin()
  }

  render(): JSX.Element {
    const isLoggedIn = this.authService.isLoggedIn()
    if (!isLoggedIn) {
      this.authService.tokenExpired()
      const productUuid = ProductNames.ByName.Tagging.Uuid
      localStorage.setItem('productUuid', productUuid)
      return <Redirect to="/login-ui/" />
    }
    return <Redirect to="/mode" />
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
