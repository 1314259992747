export enum QueueTypeEnum {
  // System queues, reservoirs/pools
  SystemInitialFinding = 'system_initial_finding',
  SystemTestFinding = 'system_test_finding',
  SystemInitialTagging = 'system_initial_tagging',
  SystemTestTagging = 'system_test_tagging',
  SystemFindingReview = 'system_finding_review',
  SystemTaggingReview = 'system_tagging_review',
  SystemTaggingFinalReview = 'system_tagging_final_review',
  SystemDiscarded = 'system_discarded',
  SystemFinished = 'system_finished',

  // User queues for Finding process
  FindingInitial = 'finding_initial',
  FindingTest = 'finding_test',
  FindingReview = 'finding_review',

  // User queues for Tagging process
  TaggingInitial = 'tagging_initial',
  TaggingTest = 'tagging_test',
  TaggingReview = 'tagging_review',
  TaggingFinalReview = 'tagging_final_review',
}
