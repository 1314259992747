import React, { Component, SyntheticEvent } from 'react'
import { Dropdown, InputGroup, ListGroup, Row } from 'react-bootstrap'
import {
  map,
  includes,
  filter,
  toLower,
  find,
  flattenDeep,
  uniqueId,
} from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { Classification, MinorClassification } from '../../../../shared/models'
import { connect } from 'react-redux'
import { AppState } from '../../../../store'
import {
  setMajorClassification,
  setMinorClassifications,
} from '../../../../store/actions'
import { SelectedMajorClassification } from '../../../../store/types'

interface State {
  // Bootstrap Dropdown `show` type is true|undefined
  open: true | undefined
  type: Classification
  query: string
  label: string
  inputFocused: boolean
  selectId: string
}

interface Props {
  type: Classification
  isSelected? : (selected: number) => void
  isDisabled: boolean
}

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = () => ({})

type AllProps = AppState & Props

class MultipleSelect extends Component<AllProps, State> {
  state: State

  constructor(props: AllProps) {
    super(props)
    const { type } = props

    this.state = {
      open: undefined,
      type,
      query: '',
      label: type === Classification.Major ? 'Major' : 'Minor',
      inputFocused: false,
      selectId: uniqueId(),
    }
  }

  componentDidMount(): void {
    this.closeDropdown = this.closeDropdown.bind(this)
    document.addEventListener('mouseup', this.closeDropdown)
  }

  componentWillUnmount(): void {
    document.removeEventListener('mouseup', this.closeDropdown)
  }

  toggleDropdown(event: SyntheticEvent): void {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      open: this.state.open ? undefined : true,
    })
    const searchField = document.getElementById(
      `dropdownSearch_` + this.state.selectId
    )
    if (searchField) {
      setTimeout(() => {
        searchField.focus()
      }, 50)
    }
  }

  closeDropdown(): void {
    const { open, inputFocused } = this.state

    setTimeout(() => {
      if (open && !inputFocused) {
        this.setState({
          open: undefined,
          query: '',
        })
      }
    })
  }

  setQuery({ value }): void {
    this.setState({
      query: value,
    })
  }

  filterOutOptions(
    { name, classificationNumber, minorClassificationNumber },
    type
  ): boolean {
    const { query } = this.state
    const {
      classifications: { selected },
    } = this.props

    const minorClassifications = flattenDeep(
      map(
        selected,
        ({ selectedMinorClassifications }) => selectedMinorClassifications
      )
    )

    if (type === Classification.Major) {
      return (
        !find(selected, { classificationNumber }) &&
        includes(toLower(`${name} ${classificationNumber}`), toLower(query))
      )
    }

    return (
      !find(minorClassifications, { minorClassificationNumber }) &&
      includes(toLower(`${name} ${minorClassificationNumber}`), toLower(query))
    )
  }

  async handleClassificationChange(
    majorClassificationNumber: number,
    type: Classification,
    minorClassificationNumber?: number
  ): Promise<void> {
    if (type === Classification.Major) {
      await setMajorClassification(majorClassificationNumber)
    } else if (minorClassificationNumber) {
      setMinorClassifications(
        majorClassificationNumber,
        minorClassificationNumber
      )
    }
  }

  getSelectedClassifications():
    | SelectedMajorClassification[]
    | MinorClassification[] {
    const {
      classifications: { selected, selectedMinorClassifications },
    } = this.props
    const { type } = this.state

    return type === Classification.Major
      ? selected
      : selectedMinorClassifications
  }

  setFocus(inputFocused: boolean): void {
    this.setState({
      inputFocused,
    })
  }

  render(): JSX.Element {
    const { open, type, query, label } = this.state
    const {
      classifications: { allClassifications, availableMinorClassifications },
    } = this.props

    const selected = this.getSelectedClassifications()

    const options =
      type === Classification.Major
        ? allClassifications
        : availableMinorClassifications

    return (
      <div className="col-sm-12 row">
        <div className={`${this.props.isDisabled ? "disabled" : ''}`}>
          <InputGroup
            className={
              selected.length === 3 && type !== Classification.Minor
                ? 'disabled'
                : ''
            }
          >
            <InputGroup.Append
              className={`${open ? 'collapsed' : 'expanded'} main`}
            >
              <InputGroup.Text
                className="expanded classification-type-label"
                onClick={event => this.toggleDropdown(event)}
              >
                {`${label} Classification`}
              </InputGroup.Text>
              <input
                id={`dropdownSearch_` + this.state.selectId}
                type="text"
                className="form-control"
                placeholder="Search..."
                value={query}
                onFocus={() => this.setFocus(true)}
                onBlur={() => this.setFocus(false)}
                onChange={({ target }) => this.setQuery(target)}
              />
              <InputGroup.Text
                className="right-arrow"
                onClick={event => this.toggleDropdown(event)}
              >
                <FontAwesomeIcon icon={faAngleDown} />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
          <Dropdown.Menu  show={open}>
          {map(
            filter(options as any, c => this.filterOutOptions(c, type)),
            (
              { classificationNumber, name, minorClassificationNumber },
              index
            ) => (
              <Row
                key={index}
                className="selected"
                onClick={() =>
                  this.handleClassificationChange(
                    classificationNumber,
                    type,
                    minorClassificationNumber
                  )
                }
              >
                <span className="code col-sm-4 ">
                  {minorClassificationNumber || classificationNumber}
                </span>
                <span className="label col-sm-8 ">{name}</span>
              </Row>
            )
          )}
        </Dropdown.Menu>
        </div>

        <ListGroup variant="flush" className="selected-list">
          {map(
            selected as any,
            (
              { classificationNumber, name, minorClassificationNumber, doneBy },
              index
            ) => (
              <Row className="selected-list-item" key={index}>
                {doneBy ? (
                  <span className="col-sm-2 code">{doneBy}</span>
                ) : (
                  <span />
                )}
                <span className="code col-sm-2">
                  {minorClassificationNumber || classificationNumber}
                </span>
                <span className={doneBy ? `label col-sm-7` : `label col-sm-9`}>
                  {name}
                </span>
                <span
                  className="remove col-sm-1"
                  onClick={() =>
                    this.handleClassificationChange(
                      classificationNumber,
                      type,
                      minorClassificationNumber
                    )
                  }
                >
                  <FontAwesomeIcon icon={faWindowClose} />
                </span>
              </Row>
            )
          )}
        </ListGroup>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultipleSelect)
