import {
  SidebarState,
  SidebarActiveItemUpdate,
  UPDATE_ACTIVE_ITEM,
} from '../types'
import { SidebarActiveItem } from '../../shared/models'

const initialState: SidebarState = {
  activeItem: SidebarActiveItem.Home,
}

export function sidebarReducer(
  state = initialState,
  action: SidebarActiveItemUpdate
): SidebarState {
  switch (action.type) {
    case UPDATE_ACTIVE_ITEM: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}
