import { Component } from 'react'

import { SidebarActiveItem } from '../models'
import { regularUserRole, managerRole } from './user-roles'

import Home from '../../components/home/HomeComponent'
import UserTagging from '../../components/user-tagging/UserTagging'
import UserFinding from '../../components/user-finding/UserFinding'
import FinalReview from '../../components/final-review/FinalReview'
import FindingReview from '../../components/finding-review/FindingReview'
import TaggingReview from '../../components/tagging-review/TaggingReview'
import ManageLogins from '../../components/manage-logins/ManageLogins'
import DistributeReserve from '../../components/distribute-reserve/DistributeReserve'
import RedistributeQueue from '../../components/redistribute-queue/RedistributeQueue'
import SystemManagerDashboard from '../../components/system-manager-dashboard/SystemManagerDashboard'
import TaggingCompendium from '../../components/tagging-compendium/TaggingCompendium'
import AuditTrail from '../../components/audit-trail/AuditTrail'
import ReportsRepository from '../../components/reports-repository/ReportsRepository'

interface Route {
  label: string
  path: string
  eventKey: SidebarActiveItem
  accessLevelRoles: string[]
  component: Component | Function
  exact?: boolean
}

export const homeRoute: Route = {
  label: 'Home',
  path: '/',
  eventKey: SidebarActiveItem.Home,
  accessLevelRoles: [managerRole, regularUserRole],
  component: Home,
  exact: true,
}

export const userTaggingRoute: Route = {
  label: 'User Tagging',
  path: '/user-tagging',
  eventKey: SidebarActiveItem.UserTagging,
  accessLevelRoles: [regularUserRole],
  component: UserTagging,
}

export const userFindingRoute: Route = {
  label: 'User Finding',
  path: '/user-finding',
  eventKey: SidebarActiveItem.UserFinding,
  accessLevelRoles: [regularUserRole],
  component: UserFinding,
}

export const finalReviewRoute: Route = {
  label: 'Final Review',
  path: '/final-review',
  eventKey: SidebarActiveItem.FinalReview,
  accessLevelRoles: [managerRole],
  component: FinalReview,
}

export const findingReviewRoute: Route = {
  label: 'Finding Review',
  path: '/finding-review',
  eventKey: SidebarActiveItem.FindingReview,
  accessLevelRoles: [managerRole],
  component: FindingReview,
}

export const taggingReviewRoute: Route = {
  label: 'Tagging Review',
  path: '/tagging-review',
  eventKey: SidebarActiveItem.TaggingReview,
  accessLevelRoles: [managerRole],
  component: TaggingReview,
}

export const manageLoginsRoute: Route = {
  label: 'Manage Logins',
  path: '/manage-logins',
  eventKey: SidebarActiveItem.ManageLogins,
  accessLevelRoles: [managerRole],
  component: ManageLogins,
}

export const distributeReserveRoute: Route = {
  label: 'Distribute Reserve',
  path: '/distribute-reserve',
  eventKey: SidebarActiveItem.DistributeReserve,
  accessLevelRoles: [managerRole],
  component: DistributeReserve,
}

export const redistributeQueueRoute: Route = {
  label: 'Redistribute Queue',
  path: '/redistribute-queue',
  eventKey: SidebarActiveItem.RedistributeQueue,
  accessLevelRoles: [managerRole],
  component: RedistributeQueue,
}

export const systemManagerDashboardRoute: Route = {
  label: 'System Manager Dashboard',
  path: '/system-manager-dashboard',
  eventKey: SidebarActiveItem.SystemManagerDashboard,
  accessLevelRoles: [managerRole, regularUserRole],
  component: SystemManagerDashboard,
}

export const taggingCompendium: Route = {
  label: 'Tagging Compendium',
  path: '/tagging-compendium',
  eventKey: SidebarActiveItem.TaggingCompendium,
  accessLevelRoles: [managerRole],
  component: TaggingCompendium,
}

export const auditTrail: Route = {
  label: 'Audit Trail',
  path: '/audit-trail',
  eventKey: SidebarActiveItem.AuditTrail,
  accessLevelRoles: [managerRole],
  component: AuditTrail,
}

export const reportsRepository: Route = {
  label: 'Download Repository',
  path: '/download-repository',
  eventKey: SidebarActiveItem.ReportsRepository,
  accessLevelRoles: [managerRole],
  component: ReportsRepository,
}

export const routes: Route[] = [
  homeRoute,
  userFindingRoute,
  userTaggingRoute,
  findingReviewRoute,
  taggingReviewRoute,
  finalReviewRoute,
  manageLoginsRoute,
  distributeReserveRoute,
  redistributeQueueRoute,
  systemManagerDashboardRoute,
  taggingCompendium,
  auditTrail,
  reportsRepository,
]
