import React, { Component } from 'react'
import { connect } from 'react-redux'

import { AppState } from '../../store'
import { AppModeEnum } from '../../shared/models'

import UserTaggingDistributor from './distributor/UserTaggingDistributor'
import UserTaggingServiceProvider from './service-provider/UserTaggingServiceProvider'
import Modal from './components/Modal'

interface State {
  modalOpen: boolean
  handleEvent: (eventToTrigger: string, eventParams?: object) => void
  modalContent: string
}

interface DispatchProps {}

type Props = AppState & DispatchProps

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = () => ({})

class UserTagging extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      handleEvent: this.handleEvent,
      modalContent: 'websiteUrlConflictNoWebsiteDiscardReason',
    }
  }

  handleEvent = (eventToTrigger, eventParams) => {
    const events = {
      handleToggleModalNoWebsiteButOneWasProvided: this
        .handleToggleModalNoWebsiteButOneWasProvided,
    }
    return events[eventToTrigger](eventParams)
  }

  handleToggleModalNoWebsiteButOneWasProvided = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      modalContent: 'websiteUrlConflictNoWebsiteDiscardReason',
    })
  }

  render(): JSX.Element {
    const {
      appMode: { activeMode },
    } = this.props

    const { modalOpen, handleEvent, modalContent } = this.state

    return (
      <>
        <Modal
          isOpen={modalOpen}
          handleEvent={handleEvent}
          modalContent={modalContent}
        />
        {activeMode === AppModeEnum.Distributors ? (
          <UserTaggingDistributor
            isOpen={modalOpen}
            handleEvent={handleEvent}
          />
        ) : (
          <UserTaggingServiceProvider
            isOpen={modalOpen}
            handleEvent={handleEvent}
          />
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTagging)
