export const tokenKey = 'jwt'
export const idToken = 'idToken'
export const accessToken = 'accessToken'
export const refreshToken = 'refreshToken'
export const redirectFlag = 'redirectFlag'
export const expires = 'expires'
export const userKey = 'user'
export const username = 'username'
export const errors = {
    WSURLNotFound: {
        Message: "WebSockets url not exist",
        Code:"WSURLNotFound",
    },
}