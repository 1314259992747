import React, { Component } from 'react'
import {
  CountryStatisticsReport,
  CountryStatisticsReportFilter,
} from '../../../../shared/models'
import moment from 'moment'

import {
  cancelActiveRequests,
  listCountryStatisticsReportService,
} from '../../../../services'
import CountryStatisticsReportFilters from '../components/Filters'
import Table from '../components/Table'

interface State {
  reportsData: {
    reports: CountryStatisticsReport[]
    totalReportsFound: number
  }
  filterParams: CountryStatisticsReportFilter
  paginationParams: {
    offset: number
    limit: number
  }
}

class CountryStatisticsReportRepositoryServiceProvider extends Component<
  {},
  State
> {
  downloadRepoRef: React.RefObject<unknown>

  constructor(props) {
    super(props)
    this.state = {
      filterParams: {
        reportInitDate: null,
        reportFinalDate: null,
      },
      reportsData: {
        reports: [],
        totalReportsFound: 0,
      },
      paginationParams: {
        offset: 0,
        limit: 10,
      },
    }
    this.downloadRepoRef = React.createRef()
  }

  componentDidMount = async (): Promise<void> => {
    await cancelActiveRequests()
    await this.handleApplyFiltersClick()
  }

  performFilterRequest = async () => {
    const payload = this.formatFilterRequest()
    const countryStatisticsReportResult = await listCountryStatisticsReportService(
      payload
    )
    const countryStatisticsReports = countryStatisticsReportResult
      ? countryStatisticsReportResult
      : {}
    this.setFilterResponse(countryStatisticsReports)
  }

  formatFilterRequest = () => {
    const { filterParams, paginationParams } = this.state
    const reportInitDate = filterParams.reportInitDate
    const reportFinalDate = filterParams.reportFinalDate
    const { offset, limit } = paginationParams

    return {
      reportInitDate,
      reportFinalDate,
      offset,
      limit,
    }
  }

  setFilterResponse = filterResponse => {
    if (filterResponse) {
      const { totalReportsFound, reports } = filterResponse.data
      this.setState({
        reportsData: {
          ...this.state.reportsData,
          reports: reports,
          totalReportsFound: totalReportsFound,
        },
      })
    }
  }

  resetAllFilters = () => {
    this.setState({
      reportsData: {
        reports: [],
        totalReportsFound: 0,
      },
      filterParams: {
        reportInitDate: null,
        reportFinalDate: null,
      },
      paginationParams: {
        limit: 10,
        offset: 0,
      },
    })
  }

  handleResetFilterClick = () => {
    this.resetAllFilters()
  }

  handlePagination = paginationParams => {
    this.setState({ paginationParams }, async () => {
      await this.performFilterRequest()
    })
  }

  handleApplyFiltersClick = async () => {
    await this.performFilterRequest()
  }

  private dateSelection = selectedDates => {
    return {
      initDate: moment(selectedDates.startDate)
        .utc()
        .format(),
      endDate: moment(selectedDates.endDate)
        .utc()
        .format(),
    }
  }

  handleReportDateSelection = selectedDates => {
    const { filterParams } = this.state
    const { initDate, endDate } = this.dateSelection(selectedDates)
    this.setState({
      filterParams: {
        ...filterParams,
        reportInitDate: initDate,
        reportFinalDate: endDate,
      },
      paginationParams: {
        limit: 10,
        offset: 0,
      },
    })
  }

  handleEvent = (eventToTrigger, eventParams) => {
    const events = {
      handlePagination: this.handlePagination,
      handleResetFilterClick: this.handleResetFilterClick,
      handleApplyFiltersClick: this.handleApplyFiltersClick,
      handleReportDateSelection: this.handleReportDateSelection,
    }

    return events[eventToTrigger](eventParams)
  }

  render(): JSX.Element {
    const { reportsData, filterParams } = this.state

    return (
      <>
        <CountryStatisticsReportFilters
          handleEvent={this.handleEvent}
          filterParams={filterParams}
        />
        <Table reportsData={reportsData} handleEvent={this.handleEvent} />
      </>
    )
  }
}

export default CountryStatisticsReportRepositoryServiceProvider
