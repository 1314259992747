import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Container, Row, Col, Tab, Nav } from 'react-bootstrap'

import { manageLoginsRoute } from '../../shared/constants'
import { SidebarActiveItem } from '../../shared/models'
import { updateSidebarActiveItem } from '../../store/actions'

import './ManageLogins.css'
import UserForm from './components/UserForm'
import { AppState } from '../../store'

interface State {}

interface DispatchProps {
  updateSidebarActiveItem: (key: SidebarActiveItem) => void
}

type Props = AppState & DispatchProps

const mapStateToProps = ({ errors }) => ({ errors })
const mapDispatchToProps = dispatch => ({
  updateSidebarActiveItem: (key: SidebarActiveItem) => {
    dispatch(updateSidebarActiveItem(key))
  },
})

class ManageLogins extends Component<Props, State> {
  componentDidMount(): void {
    this.props.updateSidebarActiveItem(manageLoginsRoute.eventKey)
  }

  render(): JSX.Element {
    return (
      <Form className="form-container">
        <Container fluid className="margin-top-75 padding-bottom-30">
          <Row>
            <Col sm={2} />
            <Col sm={8}>
              <Tab.Container
                id="tabs-container-manage-logins"
                defaultActiveKey="1"
              >
                <Row className="custom-tabs manage-logins">
                  <Col sm={2} />
                  <Col sm={8}>
                    <Nav variant="pills" as="ul">
                      <Nav.Item className="manage-logins-tab col-sm-6">
                        <Nav.Link as="li" eventKey="1">
                          Create New User
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="manage-logins-tab col-sm-6">
                        <Nav.Link as="li" eventKey="2">
                          Edit Existing User
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <hr />
                  </Col>
                  <Col sm={12}>
                    <Tab.Content className="align-middle manage-logins">
                      <Tab.Pane eventKey="1">
                        <UserForm />
                      </Tab.Pane>
                      <Tab.Pane eventKey="2">
                        <UserForm isEdit={true} />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </Form>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageLogins)
