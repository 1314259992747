import React, { Component } from 'react'
import { connect } from 'react-redux'
import { map, isEmpty, find } from 'lodash'

import { Col, Row, Button, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'

import SelectBox from '../../shared/SelectBox'

import {
  getRegularUsers,
  formatUserDataForLabel,
  validateField,
  getManagerUsers,
  saveUser,
  cancelActiveRequests,
} from '../../../services'

import {
  SelectValue,
  User,
  LoggedUser,
  FrontEndError,
} from '../../../shared/models'
import { setErrors, setSuccess } from '../../../store/actions'
import { AppState } from '../../../store'
import { regularUserRole, managerRole } from '../../../shared/constants'
import FormInput from './FormInput'

interface State {
  users: UserOption[]
  managers: UserOption[]
  isEdit: boolean
  role?: any
  firstName: string
  lastName: string
  username: string
  email: string
  userId?: number | null
}

interface DispatchProps {}

interface StateProps {
  isEdit: boolean
}

type UserOption = SelectValue & User & LoggedUser

type Props = StateProps & AppState & DispatchProps

const mapStateToProps = (state: AppState) => ({ ...state })
const mapDispatchToProps = () => ({})

class UserForm extends Component<Props, State> {
  initialUserState = {
    userId: null,
    firstName: '',
    lastName: '',
    username: '',
    email: '',
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      users: [],
      managers: [],
      isEdit: props.isEdit,
      ...this.initialUserState,
    }
  }

  async componentDidMount(): Promise<void> {
    await cancelActiveRequests()
  }

  async getUsers(): Promise<void> {
    const regularUsersResult = await getRegularUsers()
    const regularUsers = regularUsersResult ? regularUsersResult.data : []
    this.setState({
      ...this.initialUserState,
      users: map(regularUsers, user => this.getFormatedUserOption(user)),
    })
  }

  async getManagers(): Promise<void> {
    const managerUsersResult = await getManagerUsers()
    const managerUsers = managerUsersResult ? managerUsersResult.data : []
    this.setState({
      ...this.initialUserState,
      managers: map(managerUsers, user => this.getFormatedUserOption(user)),
    })
  }

  async getUsersAndManagers(): Promise<void> {
    if (this.state.isEdit) {
      await this.getUsers()
      await this.getManagers()
    }
  }

  async getInitialData(): Promise<void> {
    this.setState({
      role: null,
    })

    this.getUsersAndManagers()
  }

  getFormatedUserOption(user): UserOption {
    return {
      label: formatUserDataForLabel(user),
      value: user.userId,
      userId: user.userId,
      role: user.role,
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    }
  }

  handleUserChange(userId: number): void {
    const { role, users, managers } = this.state

    const user =
      role === regularUserRole
        ? (find(users, { value: +userId }) as UserOption)
        : (find(managers, { value: +userId }) as UserOption)

    if (user) {
      const { userId, firstName, lastName, username, email } = user
      this.setState(this.initialUserState)

      this.setState({
        firstName,
        lastName,
        email,
        userId,
        username,
      })
    }
  }

  handleFirstNameChange(firstName: string): void {
    this.setState({ firstName })
  }

  handleLastNameChange(lastName: string): void {
    this.setState({ lastName })
  }

  handleUserNameChange(username: string): void {
    this.setState({ username })
  }

  handleEmailChange(email: string): void {
    this.setState({ email })
  }

  async handleRoleChange(role): Promise<void> {
    await this.getUsersAndManagers()
    this.setState({ role, ...this.initialUserState })
  }

  async saveUser(): Promise<void> {
    const {
      firstName,
      lastName,
      username,
      email,
      role,
      isEdit,
      userId,
    } = this.state

    const xbsEmailPattern = /^\w+([.-]?\w+)+@exactera.com/
    const fitXBSEmailPattern = xbsEmailPattern.test(String(email).toLowerCase())
    const isRoleValid = validateField(role, 'user Role')
    const isFirstNameValid = validateField(firstName, 'first Name')
    const isLastNameValid = validateField(lastName, 'last Name')
    const isUsernameValid = validateField(username, 'user Name')
    const isEmailValid = validateField(email, 'email')
    const isValidForm =
      fitXBSEmailPattern &&
      isRoleValid &&
      isFirstNameValid &&
      isLastNameValid &&
      isEmailValid &&
      isUsernameValid
    if (isValidForm || isEdit) {
      const userPayload = {
        firstName,
        lastName,
        username,
        email,
        role,
      }

      const result = await saveUser(userPayload, userId)
      if (result) {
        await this.getInitialData()
        setSuccess('User saved successfully')
      }
    }
    if (!fitXBSEmailPattern) {
      setErrors(
        new FrontEndError(
          'invalidEmail',
          `This is not a valid XBS email address. 
                    The email must be registered with the organization for single sign on.`
        )
      )
    }
  }

  getUserSelectOptions(): SelectValue[] {
    const { role, users, managers } = this.state
    return !role ? [] : role === regularUserRole ? users : managers
  }
  render(): JSX.Element {
    const {
      role,
      userId,
      isEdit,
      firstName,
      lastName,
      username,
      email,
    } = this.state

    const userOptions = this.getUserSelectOptions()
    return (
      <Col sm={12}>
        <Form.Group>
          <Row className="manage-logins form-row">
            <Col sm={4}>
              <Form.Label className="font-weight-bold">User Role</Form.Label>
            </Col>
            <Col sm={4}>
              <Form.Check
                id={`${regularUserRole}-${isEdit}-user-role`}
                disabled={false}
                type="radio"
                label="Regular"
                key={regularUserRole}
                value={regularUserRole}
                checked={role === regularUserRole}
                onChange={({ target: { value } }) =>
                  this.handleRoleChange(value)
                }
              />
            </Col>
            <Col sm={4}>
              <Form.Check
                id={`${managerRole}-${isEdit}-user-role`}
                disabled={false}
                type="radio"
                label="Manager"
                key={managerRole}
                value={managerRole}
                checked={role === managerRole}
                onChange={({ target: { value } }) =>
                  this.handleRoleChange(value)
                }
              />
            </Col>
          </Row>

          {isEdit ? (
            <Row className="manage-logins form-row">
              <Col sm={12}>
                <SelectBox
                  values={userOptions}
                  onSelect={({ value }) => this.handleUserChange(value)}
                  formElementLabel="Select User"
                  initialLabel="Select User"
                  inline={true}
                  selected={String(userId) || ''}
                  disabled={isEmpty(userOptions)}
                />
              </Col>
            </Row>
          ) : (
            <Row className="manage-logins form-row" />
          )}

          <hr />
          <FormInput
            disabled={!role || !role || (isEdit && !userId)}
            label="First Name"
            value={firstName}
            onChange={({ value }) => this.handleFirstNameChange(value)}
          />

          <FormInput
            disabled={!role || (isEdit && !userId)}
            label="Last Name"
            value={lastName}
            onChange={({ value }) => this.handleLastNameChange(value)}
          />

          <FormInput
            disabled={!role || (isEdit && !userId)}
            label="Username"
            value={username}
            onChange={({ value }) => this.handleUserNameChange(value)}
          />

          <FormInput
            disabled={!role || (isEdit && !userId)}
            label="Email"
            value={email || ''}
            onChange={({ value }) => this.handleEmailChange(value)}
          />
        </Form.Group>
        <Row className="justify-content-end">
          <Button
            className="btn btn-purple right"
            onClick={() => this.saveUser()}
          >
            <FontAwesomeIcon icon={faSave} /> Save
          </Button>
        </Row>
      </Col>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserForm)
