import { get } from './base/base-http-service'
import { AppModeEnum } from '../shared/models'

export const getGeneratedDownloads = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return await get(`${getBase(activeMode)}-download-repository/reports`)
}

const getBase = (activeMode: AppModeEnum): string => {
  if (activeMode === AppModeEnum.Distributors) {
    return `distributor`
  } else if (activeMode === AppModeEnum.ServiceProviders) {
    return `service-provider`
  }
  return ''
}
