import { CLEAR_ERRORS, SET_ERRORS } from '../types'
import { store } from '../index'

export const setErrors = (errors: any) => {
  store.dispatch({
    type: SET_ERRORS,
    payload: { ...errors },
  })
}

export const clearErrors = () => {
  store.dispatch({
    type: CLEAR_ERRORS,
  })
}
