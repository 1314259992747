import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { btnSaveText, btnLoadingText } from '../../../shared/constants'

import { userTaggingRoute } from '../../../shared/constants'
import {
  SidebarActiveItem,
  Company,
  FrontEndError,
  NoDataDisplayStateEnum,
  serviceProviderTaggingDiscardReasons,
  ServiceProviderDiscardReasonEnum,
  AppModeEnum,
} from '../../../shared/models'
import {
  updateSidebarActiveItem,
  setErrors,
  toggleNoDataPage,
  setAllClassifications,
  clearClassifications,
} from '../../../store/actions'
import { AppState } from '../../../store'

import {
  getNextTaggingTask,
  saveTaggingTask,
  validateDiscardReason,
  validateStringLength,
  getClassification,
  formatClassificationsPayload,
  cancelActiveRequests,
} from '../../../services'

import DiscardReason from '../../shared/DiscardReason'
import CompanyInfoServiceProvider from '../../shared/company-info/service-provider/CompanyInfoServiceProvider'
import ClassificationPicker from '../../shared/classification-picker/ClassificationPicker'
import {
  getWebSocket,
  sendMessage,
} from '../../../services/base/websocket-service'

interface State {
  description: string
  discardReason?: ServiceProviderDiscardReasonEnum
  company: Company
  taskId?: number
  xbsWebsite?: string
  isSubmitting: boolean
  btnSaveText: string
  discardExplanation: string
}

interface DispatchProps {
  updateSidebarActiveItem: (key: SidebarActiveItem) => void
  toggleNoDataPageDisplayState: (state: NoDataDisplayStateEnum) => void
  handleEvent: (eventToTrigger: string, eventParams?: object) => void
}

type Props = AppState & DispatchProps

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = dispatch => ({
  updateSidebarActiveItem: (key: SidebarActiveItem) => {
    dispatch(updateSidebarActiveItem(key))
  },
  toggleNoDataPageDisplayState: (state: NoDataDisplayStateEnum) => {
    dispatch(toggleNoDataPage(userTaggingRoute.eventKey, state))
  },
})

class UserTaggingServiceProvider extends Component<Props, State> {
  ws: WebSocket | null
  constructor(props) {
    super(props)
    this.ws = null
    this.state = {
      description: '',
      company: {
        taggingId: '',
        identifier: '',
        identifierSource: '',
        name: '',
        country: '',
        city: '',
        postalCode: '',
        lineOfBusiness: '',
        description: '',
        url: '',
        sic: '',
        classifications: [],
      },
      isSubmitting: false,
      btnSaveText,
      discardExplanation: '',
    }
  }

  async componentDidMount(): Promise<void> {
    await cancelActiveRequests()
    const user = JSON.parse(localStorage.getItem('user') as string)
    this.ws = await getWebSocket(
      `refresh?userId=${user.userId}&section=userTagging&comp_type=SP`
    )
    if (this.ws) {
      this.ws.onmessage = () => {
        this.reload()
      }
    }
    this.props.updateSidebarActiveItem(userTaggingRoute.eventKey)
    const [taggingTaskResult] = await Promise.all([
      getNextTaggingTask(AppModeEnum.ServiceProviders),
      this.fetchClassifications(),
    ])
    const taggingTask = taggingTaskResult ? taggingTaskResult.data : {}
    if (!taggingTask) {
      this.props.toggleNoDataPageDisplayState(NoDataDisplayStateEnum.Show)
    } else {
      this.setState({ company: taggingTask })
    }
  }

  componentWillUnmount(): void {
    this.props.toggleNoDataPageDisplayState(NoDataDisplayStateEnum.Hide)
    clearClassifications()
    if (this.ws) {
      this.ws.close()
    }
  }

  async fetchClassifications(): Promise<void> {
    clearClassifications()
    const classificationsResult = await getClassification()
    const classifications = classificationsResult
      ? classificationsResult.data
      : []
    setAllClassifications(classifications)
  }

  setDescription({ target: { value: description } }): void {
    this.setState({
      description,
    })
  }

  setDiscardReason(discardReason: ServiceProviderDiscardReasonEnum): void {
    this.setState({
      discardReason,
    })
  }

  setDiscardExplanation({ target: { value: discardExplanation } }): void {
    this.setState({
      discardExplanation,
    })
  }

  validateDescription(): boolean {
    if (!validateStringLength(this.state.description, 1)) {
      setErrors(new FrontEndError('description', 'Description is required.'))
      return false
    }

    return true
  }

  validateDiscardReason(): boolean {
    if (!validateDiscardReason(this.state.discardReason)) {
      setErrors(
        new FrontEndError('discardReason', 'Discard Reason is required.')
      )
      return false
    }

    return true
  }

  validateDiscardExplanation(): boolean {
    if (
      Number(this.state.discardReason) !==
        ServiceProviderDiscardReasonEnum.NotDiscarded &&
      (typeof this.state.discardExplanation === undefined ||
        !validateStringLength(this.state.discardExplanation, 1))
    ) {
      setErrors(
        new FrontEndError(
          'discardExplanation',
          'Discard Explanation is required.'
        )
      )
      return false
    }
    return true
  }

  validateMajorClassification(): boolean {
    const {
      classifications: { selected },
    } = this.props

    if (isEmpty(selected)) {
      setErrors(
        new FrontEndError(
          'majorClassifications',
          'At least one Major Classification must be selected'
        )
      )
      return false
    }

    return true
  }

  validateMinorClassification(): boolean {
    const {
      classifications: { selectedMinorClassifications, selected },
    } = this.props
    const { discardReason } = this.state
    const { NotDiscarded } = ServiceProviderDiscardReasonEnum
    const majorsFiltered = selected.filter(major => major.name !== 'Other')
    if (
      Number(discardReason) === NotDiscarded &&
      majorsFiltered.length > 0 &&
      isEmpty(selectedMinorClassifications)
    ) {
      setErrors(
        new FrontEndError(
          'minorClassifications',
          'At least one Minor Classification must be selected'
        )
      )
      return false
    }
    return true
  }

  disableButton(): void {
    this.setState({
      isSubmitting: true,
      btnSaveText: btnLoadingText,
    })
  }

  async reload(): Promise<void> {
    clearClassifications()
    const taggingTaskResult = await getNextTaggingTask(
      AppModeEnum.ServiceProviders
    )
    const taggingTask = taggingTaskResult ? taggingTaskResult.data : null
    if (!taggingTask) {
      this.props.toggleNoDataPageDisplayState(NoDataDisplayStateEnum.Show)
    } else {
      this.setState({
        company: taggingTask,
        discardReason: undefined,
        discardExplanation: '',
        description: '',
        isSubmitting: false,
        btnSaveText,
      })
    }
  }

  async saveAndNext(): Promise<void> {
    const {
      description,
      discardReason,
      company,
      discardExplanation,
    } = this.state
    const shouldvalidateMajor =
      Number(discardReason) === ServiceProviderDiscardReasonEnum.NotDiscarded
    const validateMajor =
      !this.validateMajorClassification() || !this.validateMinorClassification()
    if (shouldvalidateMajor && validateMajor) {
      return
    }

    if (
      !this.validateDescription() ||
      !this.validateDiscardReason() ||
      !this.validateDiscardExplanation()
    ) {
      return
    }

    this.disableButton()

    const selectedClassifications = formatClassificationsPayload()
    const result = await saveTaggingTask(
      {
        description,
        discardReason,
        selectedClassifications,
        discardExplanation,
      },
      company.taggingId,
      AppModeEnum.ServiceProviders
    )
    if (result) {
      await this.reload()
      if (this.ws) {
        sendMessage(this.ws, {
          newTask: true,
        })
      }
    }
  }

  render(): JSX.Element {
    const {
      noData: { state },
    } = this.props

    const {
      company,
      xbsWebsite,
      description,
      discardReason,
      discardExplanation,
      isSubmitting,
      btnSaveText,
    } = this.state

    if (state === NoDataDisplayStateEnum.Show) {
      return <div />
    }

    if (!company || !company.identifier) {
      return <div />
    }

    return (
      <Form className="form-container">
        <Container fluid className="margin-top-75 padding-bottom-30">
          <Row className="align-middle">
            <Col sm={12}>
              <CompanyInfoServiceProvider
                company={company || {}}
                xbsWebsite={xbsWebsite}
                isFinding={false}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={9}>
              <Form.Group as={Row} className="tagging-xbs-description">
                <Col sm={2}>
                  <Form.Label>XBS Description</Form.Label>
                </Col>
                <Col sm={10}>
                  <Form.Control
                    as="textarea"
                    rows="5"
                    placeholder="XBS Description"
                    value={description}
                    onChange={event => this.setDescription(event)}
                    onBlur={() => this.validateDescription()}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <DiscardReason
                discardReasons={serviceProviderTaggingDiscardReasons}
                selected={discardReason}
                handleChange={reason => this.setDiscardReason(reason)}
              />
              <Form.Group>
                <Form.Label className="font-weight-bold">
                  Discard Explanation
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="5"
                  placeholder="Discard Explanation"
                  value={discardExplanation}
                  onChange={event => this.setDiscardExplanation(event)}
                  onBlur={() => this.validateDiscardExplanation()}
                />
              </Form.Group>
            </Col>
          </Row>

          <ClassificationPicker />

          <hr />
          <Row className="form-container-actions pr-3 justify-content-end">
            <Button
              className="btn btn-purple"
              onClick={() => this.saveAndNext()}
              disabled={isSubmitting}
            >
              <FontAwesomeIcon icon={faArrowRight} /> {btnSaveText}
            </Button>
          </Row>
        </Container>
      </Form>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTaggingServiceProvider)
