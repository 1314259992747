import React, { Component } from 'react'
import Dropdown from './Dropdown'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { AppModeEnum } from '../../../../shared/models'
import DataRange from '../../../audit-trail/audit-trail-table/components/DataRangePicker'

interface State {}

interface Props {
  appMode: AppModeEnum
  handleEvent: Function
  filterParams: any
  filterData: any
}

class Filters extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderDropdownTooltip = () => {
    const { filterParams } = this.props
    if (filterParams.companySearch.length >= 3) {
      return <Tooltip id="hideTooltip" />
    }

    return (
      <Tooltip id="dropdownTooltip">
        Type at least <strong>3</strong> characters
      </Tooltip>
    )
  }

  render(): JSX.Element {
    const { handleEvent, filterParams, filterData } = this.props

    return (
      <div>
        <div className="filter-button-container">
          <div className="filter-button-middle-container">
            <Dropdown
              dropdownTitle="Database"
              dropdownData={filterData.sourceTypes}
              selectedItems={filterParams.sourceTypes}
              onChange={sourceTypeSelected =>
                handleEvent('handleSourceTypeSelection', sourceTypeSelected)
              }
              shouldShowLockIcon={false}
              isMultiple={true}
              isSearchable={false}
              alignPopover="left"
            />
            <Dropdown
              dropdownTitle="Country"
              dropdownData={filterData.countries}
              selectedItems={filterParams.country}
              onChange={countrySelected =>
                handleEvent('handleCountrySelection', countrySelected)
              }
              shouldShowLockIcon={false}
              isMultiple={false}
              isSearchable={false}
              alignPopover="left"
            />
            <Dropdown
              dropdownTitle="Revenue"
              dropdownData={filterData.revenue}
              selectedItems={filterParams.revenue}
              onChange={revenueSelected =>
                handleEvent('handleRevenueSelection', revenueSelected)
              }
              shouldShowLockIcon={false}
              isMultiple={false}
              isSearchable={false}
              alignPopover="left"
            />
            <Dropdown
              dropdownTitle="SICs"
              dropdownData={filterData.sics}
              selectedItems={filterParams.sics}
              onChange={sicSelected =>
                handleEvent('handleSicSelection', sicSelected)
              }
              shouldShowLockIcon={false}
              isMultiple
              isSearchable={false}
              alignPopover="center"
            />
            {this.props.appMode === AppModeEnum.ServiceProviders ? (
              <Dropdown
                dropdownTitle="Majors"
                dropdownData={filterData.majors}
                selectedItems={filterParams.majors}
                onChange={majorSelected =>
                  handleEvent('handleMajorSelection', majorSelected)
                }
                shouldShowLockIcon={false}
                isMultiple
                isSearchable={false}
                alignPopover="center"
              />
            ) : null}

            {this.props.appMode === AppModeEnum.ServiceProviders ? (
              <Dropdown
                dropdownTitle="Minors"
                dropdownData={filterData.minors}
                selectedItems={filterParams.minors}
                onChange={minorSelected =>
                  handleEvent('handleMinorSelection', minorSelected)
                }
                shouldShowLockIcon={false}
                isMultiple
                isSearchable={false}
                alignPopover="center"
              />
            ) : null}
            <DataRange
              dataRangeTitle="Finished On"
              startDate={filterParams.dateStart}
              endDate={filterParams.dateEnd}
              handleEvent={handleEvent}
              handleDateSelectionEvent="handleFinishedDate"
            />

            <Dropdown
              isLarge={true}
              alternativeLabel="Has defined URL"
              dropdownTitle="Has-Defined-URL"
              dropdownData={filterData.definedUrl}
              selectedItems={filterParams.definedUrl}
              onChange={definedUrlSelected =>
                handleEvent('handleDefinedUrlSelection', definedUrlSelected)
              }
              shouldShowLockIcon={false}
              isMultiple={false}
              isSearchable={false}
              alignPopover="left"
            />

            <Dropdown
              isLarge={true}
              alternativeLabel="Show Deprecated Companies"
              dropdownTitle="Show-Deprecated-Companies"
              dropdownData={filterData.showDeprecated}
              selectedItems={filterParams.showDeprecated}
              onChange={showDeprecatedSelected =>
                handleEvent('handleDeprecatedCompsSelection', showDeprecatedSelected)
              }
              shouldShowLockIcon={false}
              isMultiple={false}
              isSearchable={false}
              alignPopover="left"
            />

            <OverlayTrigger
              placement="bottom"
              trigger="focus"
              overlay={this.renderDropdownTooltip()}
            >
              <div>
                <Dropdown
                  dropdownTitle="Companies"
                  dropdownData={filterData.companies}
                  selectedItems={filterParams.companies}
                  searchValue={filterParams.companySearch}
                  onChange={compSelected =>
                    handleEvent('handleCompSelection', compSelected)
                  }
                  handleEvent={handleEvent}
                  shouldShowLockIcon
                  isMultiple
                  isSearchable
                  alignPopover="center"
                />
              </div>
            </OverlayTrigger>
          </div>

          <div className="filter-button-end-container">
            <div
              className="button"
              onClick={() => handleEvent('handleApplyFiltersClick')}
            >
              Apply Filter
            </div>
            <div
              className="button"
              onClick={() => handleEvent('handleResetFilterClick')}
            >
              Reset Filter
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Filters
