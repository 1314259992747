export enum DistributorDiscardReasonEnum {
  NotDiscarded = 0,
  NoWebsite = 1,
  Manufacturer = 2,
  OtherMisclassified = 3,
  OutOfBusiness = 4,
  Franchise = 5,
  InsufficientInfo = 6,
  IsASubsidiary = 10,
  SignificantGovernmentContracts = 11,
  OperatesAsAServiceProvider = 12,
  SignificantServicesRevenue = 13,
}

export enum ServiceProviderDiscardReasonEnum {
  NotDiscarded = 0,
  NoWebsite = 1,
  Manufacturer = 2,
  OtherMisclassified = 3,
  OutOfBusiness = 4,
  Franchise = 5,
  InsufficientInfo = 6,
  TooDiverse = 7,
  SellsProprietarySoftware = 8,
  ConsortiumOrPartnership = 9,
  IsASubsidiary = 10,
  SignificantGovernmentContracts = 11,
}
