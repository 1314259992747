import {getEnvironment} from "../auth-service"
import {errors} from "../../shared/constants"

const getWSUrl = async (): Promise<string | null> => {
    const environment = await getEnvironment()
    if (environment) {
        return environment.WS_URL
    }
    if (localStorage.getItem('WS_URL')) {
        return localStorage.getItem('WS_URL')
    }
    throw new Error(errors.WSURLNotFound.Message)
}

export const sendMessage = (socket, message) => {
    socket.send(JSON.stringify({
        authorization: localStorage.getItem('idToken'),
        ...message,
    }))
}

export const getWebSocket = async (path) => {
    const url = await getWSUrl()
    return new WebSocket(`${url}${path}`)
}
