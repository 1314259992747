import { UPDATE_APP_MODE } from '../types'
import { AppModeEnum } from '../../shared/models'
import { storeInLocalStorage } from '../../services'

export const updateAppMode = (activeMode: AppModeEnum) => dispatch => {
  storeInLocalStorage('activeMode', activeMode)
  dispatch({
    type: UPDATE_APP_MODE,
    payload: { activeMode },
  })
}
