import { CLEAR_SUCCESS, SET_SUCCESS } from '../types'
import { store } from '../index'

export const setSuccess = (message, autoClose = true): void => {
  store.dispatch({
    type: SET_SUCCESS,
    payload: { message, autoClose },
  })
}

export const clearSuccess = (): void => {
  store.dispatch({
    type: CLEAR_SUCCESS,
  })
}
