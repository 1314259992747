export interface DownloadItem {
  reportId: string
  status: StatsDownloadEnum
  creationDate: string
  url: string
}

export interface StatsDownloadItem {
  total: number
  pending: number
  inProgress: number
  completed: number
}

export enum StatsDownloadEnum {
  Total = 'Total',
  Pending = 'Pending',
  InProgress = 'In Progress',
  Completed = 'Completed',
}
