import React, { Component } from 'react'
import { connect } from 'react-redux'
import { map } from 'lodash'
import { Col, Row, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'

import SelectBox from '../shared/SelectBox'
import { AppState } from '../../store'
import { setSuccess } from '../../store/actions'
import { SelectValue } from '../../shared/models'
import { btnDistributeText, btnLoadingText } from '../../shared/constants'
import {
  getRegularUsers,
  getManagerUsers,
  formatUserDataForLabel,
  validateField,
  cancelActiveRequests,
  distributionGetCountriesFromSystem,
  distributionGetBatchesFromSystem,
  distributionGetSicsFromSystem,
  distributionGetSicsByCountryFromSystem,
  distributeReserveToUser,
  distributeReserveToManager,
  taskCountOptionsForUserAtFinding,
  taskCountOptionsForUserAtTagging,
} from '../../services'

interface State {
  countries: SelectValue[]
  users: SelectValue[]
  destinationUserId?: number | null
  domicileId?: number | null
  sics: customSelectValue[]
  selectedBatch: string | 0
  batches: customSelectValue[]
  selectedSics: string[]
  taskCountOptions: SelectValue[]
  taskCountOptionSelected: number
  isSubmitting: boolean
  btnDistributeText: string
}

interface customSelectValue extends SelectValue {
  quantity?: number
}

interface DispatchProps {}

interface StateProps {
  companyType: boolean
  originCompanyStatus: string
  needReview: boolean
}

type Props = StateProps & AppState & DispatchProps

const mapStateToProps = (state: AppState) => ({ ...state })
const mapDispatchToProps = () => ({})
class DistributeReserve extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const taskCountOptionsBase =
      this.props.originCompanyStatus === 'finding-unassigned'
        ? taskCountOptionsForUserAtFinding()
        : taskCountOptionsForUserAtTagging()
    this.state = {
      countries: [],
      sics: [],
      domicileId: 0,
      selectedSics: [],

      batches: [],
      selectedBatch: 0,

      taskCountOptions: taskCountOptionsBase,
      taskCountOptionSelected: 0,

      users: [],
      destinationUserId: null,
      isSubmitting: false,
      btnDistributeText,
    }
  }

  async componentDidMount(): Promise<void> {
    await cancelActiveRequests()
    await this.getInitialData()
  }

  setTaskCountsOptions(number): void {
    // add new value and set the new value
    const taskCountOptionsBase =
      this.props.originCompanyStatus === 'finding-unassigned'
        ? taskCountOptionsForUserAtFinding()
        : taskCountOptionsForUserAtTagging()
    let NewtaskCountOptionsBase = taskCountOptionsBase.filter(
      element => element.value < number
    )
    NewtaskCountOptionsBase.push({
      value: number,
      label: number,
    })
    this.setState({
      taskCountOptions: NewtaskCountOptionsBase,
      taskCountOptionSelected: number,
    })
  }

  async getInitialData(): Promise<void> {
    this.setState({
      domicileId: 0,
      selectedSics: [],
      selectedBatch: 0,
      destinationUserId: null,
    })
    await Promise.all([
      this.getCountries(),
      this.getUsers(),
      this.getAllSics(),
      this.getBatches(),
    ])
  }
  async getUsers(): Promise<void> {
    const isDistributeToUsers =
      this.props.originCompanyStatus === 'finding-unassigned' ||
      this.props.originCompanyStatus === 'tagging-unassigned'
    const regularUsersResult = isDistributeToUsers
      ? await getRegularUsers()
      : await getManagerUsers()
    const regularUsers = regularUsersResult ? regularUsersResult.data : []
    this.setState({
      users: map(regularUsers, user => {
        return {
          label: formatUserDataForLabel(user),
          value: user.userId,
        }
      }),
    })
  }
  async getAllSics(): Promise<void> {
    const sicsResult = await distributionGetSicsFromSystem(
      this.props.companyType,
      this.props.originCompanyStatus,
      this.props.needReview
    )

    const sicsData = sicsResult ? sicsResult.data : []
    let newSics: customSelectValue[] = []
    let newTotalCompanies: number = 0

    sicsData.forEach(sic => {
      newSics.push({
        value: sic.sic,
        label: `${sic.sic} (${sic.sicCompanies})`,
        quantity: sic.sicCompanies,
      })
      newTotalCompanies += sic.sicCompanies
    })

    this.setTaskCountsOptions(newTotalCompanies)
    this.setState({
      sics: newSics,
      domicileId: 0,
      selectedBatch: 0,
      selectedSics: [],
    })
  }

  async getCountries(): Promise<void> {
    const countriesForSystemResult = await distributionGetCountriesFromSystem(
      this.props.companyType,
      this.props.originCompanyStatus,
      this.props.needReview
    )

    const countries = countriesForSystemResult
      ? countriesForSystemResult.data
      : []

    this.setState({
      countries: map(countries, country => {
        return {
          value: country.id,
          label: `${country.name} (${country.companiesCount})`,
        }
      }),
    })
  }
  async getBatches(): Promise<void> {
    const batchesForSystemResult = await distributionGetBatchesFromSystem(
      this.props.companyType,
      this.props.originCompanyStatus,
      this.props.needReview
    )

    const batches = batchesForSystemResult ? batchesForSystemResult.data : []

    this.setState({
      batches: map(batches, item => {
        return {
          value: item.batchId,
          label: `${item.batchName} (${item.companiesCount})`,
          quantity: parseInt(item.companiesCount),
        }
      }),
    })
  }

  handleUserChange(destinationUserId: number): void {
    this.setState({ destinationUserId })
  }
  async handleCountryChange(domicileId: number): Promise<void> {
    if (domicileId === 0) {
      this.getAllSics()
      return
    }
    const sicsResult = await distributionGetSicsByCountryFromSystem(
      this.props.companyType,
      this.props.originCompanyStatus,
      this.props.needReview,
      domicileId
    )

    const sicsData = sicsResult ? sicsResult.data : []

    let newSics: customSelectValue[] = []
    let newTotalCompanies: number = 0

    sicsData.forEach(sic => {
      newSics.push({
        value: sic.sic,
        label: `${sic.sic} (${sic.sicCompanies})`,
        quantity: sic.sicCompanies,
      })
      newTotalCompanies += sic.sicCompanies
    })

    this.setTaskCountsOptions(newTotalCompanies)
    this.setState({
      sics: newSics,
      selectedSics: [],
      domicileId,
    })
  }
  async handleBatchChange(batchId: string | 0): Promise<void> {
    let { batches } = this.state
    if (batchId === 0) {
      this.getAllSics()
      return
    }
    let newTotalCompanies: number = 0
    const batchFound = batches.find(batch => batch.value === batchId)
    if (batchFound && batchFound.quantity) {
      newTotalCompanies = batchFound.quantity
    }

    this.setTaskCountsOptions(newTotalCompanies)
    this.setState({
      selectedBatch: batchId,
    })
  }

  handleSicChange(sic: string): void {
    let { selectedSics, sics } = this.state

    if (selectedSics.includes(sic)) {
      selectedSics = selectedSics.filter(sicItem => sicItem !== sic)
    } else {
      selectedSics.push(sic)
    }
    let newTotalCompanies: number = 0

    let mapSics = {}
    sics.forEach(selectedSicItem => {
      mapSics[selectedSicItem.value] = selectedSicItem.quantity
    })

    selectedSics.forEach(selectedSicItem => {
      newTotalCompanies = newTotalCompanies + mapSics[selectedSicItem]
    })

    this.setTaskCountsOptions(newTotalCompanies)
    this.setState({
      selectedSics,
    })
  }

  handleTaskCountChange(taskCount: number): void {
    this.setState({ taskCountOptionSelected: taskCount })
  }

  disableButton(): void {
    this.setState({
      isSubmitting: true,
      btnDistributeText: btnLoadingText,
    })
  }

  async distributeReserve(): Promise<void> {
    const {
      destinationUserId,
      domicileId,
      taskCountOptionSelected,
      selectedSics,
      selectedBatch,
    } = this.state

    if (validateField(destinationUserId, 'user')) {
      this.disableButton()

      let result: any = {}
      const isDistributeToUsers =
        this.props.originCompanyStatus === 'finding-unassigned' ||
        this.props.originCompanyStatus === 'tagging-unassigned'
      if (isDistributeToUsers) {
        result = await distributeReserveToUser({
          companyType: this.props.companyType,
          originCompanyStatus: this.props.originCompanyStatus,
          needReview: this.props.needReview,
          destinationUserId,
          selectedSics,
          taskCount: taskCountOptionSelected,
          domicileId,
          selectedBatch,
        })
      } else {
        result = await distributeReserveToManager({
          companyType: this.props.companyType,
          originCompanyStatus: this.props.originCompanyStatus,
          needReview: this.props.needReview,
          destinationUserId,
          selectedSics,
          taskCount: taskCountOptionSelected,
          domicileId,
          selectedBatch,
        })
      }

      if (result) {
        setSuccess(
          `Successfully Distributed ${result.data.distributedCount} Tasks`
        )
        await this.getInitialData()
        this.setState({
          isSubmitting: false,
          btnDistributeText,
        })
      }
    }
  }

  clearSelectedOptions(): void {
    const { domicileId, selectedBatch } = this.state
    if (domicileId) {
      this.handleCountryChange(domicileId)
    } else {
      this.handleBatchChange(selectedBatch)
    }
    this.setState({
      selectedSics: [],
    })
  }

  render(): JSX.Element {
    const {
      countries,
      sics,
      domicileId,
      selectedSics,

      batches,
      selectedBatch,

      taskCountOptions,
      taskCountOptionSelected,

      users,
      destinationUserId,
      isSubmitting,
      btnDistributeText,
    } = this.state
    return (
      <Col sm={12}>
        <SelectBox
          values={users}
          onSelect={({ value }) => this.handleUserChange(value)}
          formElementLabel="Distribute to User"
          initialLabel="Select User"
          inline={true}
          selected={String(destinationUserId) || ''}
        />

        <SelectBox
          values={countries}
          onSelect={({ value }) =>
            this.handleCountryChange(value === '' ? 0 : parseInt(value))
          }
          formElementLabel="Country Name"
          initialLabel="Select Country"
          inline={true}
          disabled={selectedBatch !== 0}
          selected={String(domicileId) || ''}
        />

        <SelectBox
          values={batches}
          onSelect={({ value }) =>
            this.handleBatchChange(value === '' ? 0 : value)
          }
          formElementLabel="Batch"
          initialLabel="Select Batch"
          inline={true}
          disabled={domicileId !== 0}
          selected={String(selectedBatch) || ''}
        />

        {(domicileId === 0 && selectedBatch === 0) || domicileId !== 0 ? (
          <SelectBox
            values={sics}
            onSelect={({ value }) => this.handleSicChange(value)}
            formElementLabel="SIC"
            initialLabel=""
            inline={true}
            multiple={true}
            selected={selectedSics || ''}
          />
        ) : null}
        {selectedSics.length > 0 && (
          <p
            className="clear-all-selected"
            onClick={() => this.clearSelectedOptions()}
          >
            Clear all
          </p>
        )}

        <SelectBox
          values={taskCountOptions}
          disabled={taskCountOptionSelected === 0}
          onSelect={({ value }) => this.handleTaskCountChange(value)}
          formElementLabel={`Number To Move (${taskCountOptionSelected})`}
          initialLabel={null}
          inline={true}
          selected={String(taskCountOptionSelected) || ''}
        />
        <Row className="justify-content-end">
          <Button
            className="btn btn-purple right"
            onClick={() => this.distributeReserve()}
            disabled={isSubmitting}
          >
            <FontAwesomeIcon icon={faSave} /> {btnDistributeText}
          </Button>
        </Row>
      </Col>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributeReserve)
