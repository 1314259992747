import React from 'react'
import { Container, Form, Col, Row } from 'react-bootstrap'
import { formatUrl } from '../../../../services'
import { SourceTypeDatabaseEnum } from '../../../../shared/models'

const CompanyInfoDistributor = ({ company, isFinding, xbsWebsite = '' }) => (
  <Container fluid className="company-info align-middle">
    <Row>
      <Form.Group as={Row} className="col-sm-12">
        <Col sm={2}>
          <Form.Label>Country</Form.Label>
        </Col>
        <Col sm={10}>
          <p className="font-weight-bold">{company.country}</p>
        </Col>
      </Form.Group>
    </Row>
    <Row>
      <Form.Group as={Row} className="col-sm-12">
        <Col sm={2}>
          <Form.Label>Company Name</Form.Label>
        </Col>
        <Col sm={3}>
          <p className="font-weight-bold">{company.name}</p>
        </Col>
        <Col sm={2}>
          <Form.Label>Identifier</Form.Label>
        </Col>
        <Col sm={2}>
          <p className="font-weight-bold">{company.identifier}</p>
        </Col>
        <Col sm={2}>
          <Form.Label>Identifier Source</Form.Label>
        </Col>
        <Col sm={1}>
          <p className="font-weight-bold">
            {SourceTypeDatabaseEnum[company.identifierSource]}
          </p>
        </Col>
      </Form.Group>
    </Row>
    <Row>
      <Form.Group as={Row} className="col-sm-12">
        <Col sm={2}>
          <Form.Label>City Name</Form.Label>
        </Col>
        <Col sm={3}>
          <p className="font-weight-bold">{company.city}</p>
        </Col>
        {isFinding ? (
          <div className="col-sm-5">
            <Row>
              <Col sm={5}>
                <Form.Label>Postal Code</Form.Label>
              </Col>
              <Col sm={7}>
                <p className="font-weight-bold">{company.postalCode}</p>
              </Col>
            </Row>
          </div>
        ) : (
          <div />
        )}
      </Form.Group>
    </Row>
    <Row>
      <Form.Group as={Row} className="col-sm-12">
        <Col sm={2}>
          <Form.Label>Line Of Business</Form.Label>
        </Col>
        <Col sm={10} className="scroll-box">
          <p className="font-weight-bold">{company.lineOfBusiness}</p>
        </Col>
      </Form.Group>
    </Row>
    <Row>
      <Form.Group as={Row} className="col-sm-12">
        <Col sm={2}>
          <Form.Label>Description</Form.Label>
        </Col>
        <Col sm={10} className="scroll-box">
          <p className="font-weight-bold">{company.description}</p>
        </Col>
      </Form.Group>
    </Row>
    {isFinding ? (
      <div />
    ) : (
      <Row>
        <Form.Group as={Row} className="col-sm-12">
          <Col sm={2}>
            <Form.Label>Website</Form.Label>
          </Col>
          <Col sm={10}>
            <a
              rel="noreferrer"
              className="font-weight-bold"
              href={formatUrl(company.url) || formatUrl(xbsWebsite)}
              target="_blank"
            >
              {company.url || xbsWebsite}
            </a>
          </Col>
        </Form.Group>
      </Row>
    )}
  </Container>
)

export default CompanyInfoDistributor
