import {
  DistributorDiscardReasonEnum,
  ServiceProviderDiscardReasonEnum,
} from './enums/discard-reason-enum'
import { AppModeEnum } from './enums'

export interface DiscardReasonProvider {
  get(appMode: AppModeEnum): LabeledDiscardReason
}

export interface LabeledDiscardReason {
  label: string
  reason: DistributorDiscardReasonEnum | ServiceProviderDiscardReasonEnum
}

const notDiscarded: DiscardReasonProvider = {
  get: (appMode: AppModeEnum): LabeledDiscardReason => {
    return {
      label: 'Do Not Discard',
      reason:
        appMode === AppModeEnum.Distributors
          ? DistributorDiscardReasonEnum.NotDiscarded
          : ServiceProviderDiscardReasonEnum.NotDiscarded,
    } as LabeledDiscardReason
  },
}

const noWebsite: DiscardReasonProvider = {
  get: (appMode: AppModeEnum): LabeledDiscardReason => {
    return {
      label: 'No Website',
      reason:
        appMode === AppModeEnum.Distributors
          ? DistributorDiscardReasonEnum.NoWebsite
          : ServiceProviderDiscardReasonEnum.NoWebsite,
    } as LabeledDiscardReason
  },
}

const manufacturer: DiscardReasonProvider = {
  get: (appMode: AppModeEnum): LabeledDiscardReason => {
    return {
      label: 'Full-Fledged Manufacturer',
      reason:
        appMode === AppModeEnum.Distributors
          ? DistributorDiscardReasonEnum.Manufacturer
          : ServiceProviderDiscardReasonEnum.Manufacturer,
    } as LabeledDiscardReason
  },
}

const otherMisclassified: DiscardReasonProvider = {
  get: (appMode: AppModeEnum): LabeledDiscardReason => {
    return {
      label: 'Other Misclassified',
      reason:
        appMode === AppModeEnum.Distributors
          ? DistributorDiscardReasonEnum.OtherMisclassified
          : ServiceProviderDiscardReasonEnum.OtherMisclassified,
    } as LabeledDiscardReason
  },
}

const outOfBusiness: DiscardReasonProvider = {
  get: (appMode: AppModeEnum): LabeledDiscardReason => {
    return {
      label: 'Out Of Business',
      reason:
        appMode === AppModeEnum.Distributors
          ? DistributorDiscardReasonEnum.OutOfBusiness
          : ServiceProviderDiscardReasonEnum.OutOfBusiness,
    } as LabeledDiscardReason
  },
}

const franchiseNonProfit: DiscardReasonProvider = {
  get: (appMode: AppModeEnum): LabeledDiscardReason => {
    return {
      label: 'Franchise, Non Profit',
      reason:
        appMode === AppModeEnum.Distributors
          ? DistributorDiscardReasonEnum.Franchise
          : ServiceProviderDiscardReasonEnum.Franchise,
    } as LabeledDiscardReason
  },
}

const insufficientInfo: DiscardReasonProvider = {
  get: (appMode: AppModeEnum): LabeledDiscardReason => {
    return {
      label: 'Insufficient Info',
      reason:
        appMode === AppModeEnum.Distributors
          ? DistributorDiscardReasonEnum.InsufficientInfo
          : ServiceProviderDiscardReasonEnum.InsufficientInfo,
    } as LabeledDiscardReason
  },
}

const tooDiverse: DiscardReasonProvider = {
  get: (): LabeledDiscardReason => {
    return {
      label: 'Too Diverse',
      reason: ServiceProviderDiscardReasonEnum.TooDiverse,
    } as LabeledDiscardReason
  },
}

const sellsProprietarySoftware: DiscardReasonProvider = {
  get: (): LabeledDiscardReason => {
    return {
      label: 'Sells Proprietary Software',
      reason: ServiceProviderDiscardReasonEnum.SellsProprietarySoftware,
    } as LabeledDiscardReason
  },
}

const operatesAsConsortium: DiscardReasonProvider = {
  get: (): LabeledDiscardReason => {
    return {
      label: 'Operates as a Consortium',
      reason: ServiceProviderDiscardReasonEnum.ConsortiumOrPartnership,
    } as LabeledDiscardReason
  },
}

const isASubsidiary: DiscardReasonProvider = {
  get: (appMode: AppModeEnum): LabeledDiscardReason => {
    return {
      label: 'Is a Subsidiary',
      reason:
        appMode === AppModeEnum.ServiceProviders
          ? ServiceProviderDiscardReasonEnum.IsASubsidiary
          : DistributorDiscardReasonEnum.IsASubsidiary,
    } as LabeledDiscardReason
  },
}

const significantGovernmentContracts: DiscardReasonProvider = {
  get: (appMode: AppModeEnum): LabeledDiscardReason => {
    return {
      label: 'Significant Government Contracts',
      reason:
        appMode === AppModeEnum.ServiceProviders
          ? ServiceProviderDiscardReasonEnum.SignificantGovernmentContracts
          : DistributorDiscardReasonEnum.SignificantGovernmentContracts,
    } as LabeledDiscardReason
  },
}

const operatesAsAServiceProvider: DiscardReasonProvider = {
  get: (): LabeledDiscardReason => {
    return {
      label: 'Operates as a Service Provider',
      reason: DistributorDiscardReasonEnum.OperatesAsAServiceProvider,
    } as LabeledDiscardReason
  },
}

const significantServicesRevenue: DiscardReasonProvider = {
  get: (): LabeledDiscardReason => {
    return {
      label: 'Significant Services Revenue',
      reason: DistributorDiscardReasonEnum.SignificantServicesRevenue,
    } as LabeledDiscardReason
  },
}

export const distributorTaggingDiscardReasons: LabeledDiscardReason[] = [
  notDiscarded.get(AppModeEnum.Distributors),
  noWebsite.get(AppModeEnum.Distributors),
  manufacturer.get(AppModeEnum.Distributors),
  otherMisclassified.get(AppModeEnum.Distributors),
  outOfBusiness.get(AppModeEnum.Distributors),
  franchiseNonProfit.get(AppModeEnum.Distributors),
  insufficientInfo.get(AppModeEnum.Distributors),
  isASubsidiary.get(AppModeEnum.Distributors),
  significantGovernmentContracts.get(AppModeEnum.Distributors),
  operatesAsAServiceProvider.get(AppModeEnum.Distributors),
  significantServicesRevenue.get(AppModeEnum.Distributors),
]

export const distributorFindingDiscardReasons: LabeledDiscardReason[] = [
  notDiscarded.get(AppModeEnum.Distributors),
  noWebsite.get(AppModeEnum.Distributors),
  outOfBusiness.get(AppModeEnum.Distributors),
  isASubsidiary.get(AppModeEnum.Distributors),
]

export const serviceProviderTaggingDiscardReasons: LabeledDiscardReason[] = [
  notDiscarded.get(AppModeEnum.ServiceProviders),
  noWebsite.get(AppModeEnum.ServiceProviders),
  manufacturer.get(AppModeEnum.ServiceProviders),
  otherMisclassified.get(AppModeEnum.ServiceProviders),
  outOfBusiness.get(AppModeEnum.ServiceProviders),
  franchiseNonProfit.get(AppModeEnum.ServiceProviders),
  insufficientInfo.get(AppModeEnum.ServiceProviders),
  tooDiverse.get(AppModeEnum.ServiceProviders),
  sellsProprietarySoftware.get(AppModeEnum.ServiceProviders),
  operatesAsConsortium.get(AppModeEnum.ServiceProviders),
  isASubsidiary.get(AppModeEnum.ServiceProviders),
  significantGovernmentContracts.get(AppModeEnum.ServiceProviders),
]

export const serviceProviderFindingDiscardReasons: LabeledDiscardReason[] = [
  notDiscarded.get(AppModeEnum.ServiceProviders),
  noWebsite.get(AppModeEnum.ServiceProviders),
  isASubsidiary.get(AppModeEnum.ServiceProviders),
]
