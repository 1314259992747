import React, { Component } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import moment from 'moment'

type Props = {
  dataRangeTitle: string
  handleEvent?: any
  dataRangeContainer?: any
  startDate?: string
  endDate?: string
  handleDateSelectionEvent: string
}

class DataRange extends Component<Props> {
  handleEvent = (event, date) => {
    const { startDate, endDate } = date
    const areDatesSelected = startDate !== undefined && endDate !== undefined
    if (!areDatesSelected) {
      return
    }

    if (event.type === 'apply') {
      const { handleEvent, handleDateSelectionEvent } = this.props
      handleEvent(handleDateSelectionEvent, { ...date })
    }
  }

  render(): JSX.Element {
    const { dataRangeTitle } = this.props

    return (
      <DateRangePicker
        startDate={
          this.props.startDate ? moment(this.props.startDate) : undefined
        }
        endDate={this.props.endDate ? moment(this.props.endDate) : undefined}
        onEvent={this.handleEvent}
      >
        <button ref="date_input" className="data-range-button">
          {dataRangeTitle}
        </button>
      </DateRangePicker>
    )
  }
}

export default DataRange
