import React, { SyntheticEvent } from 'react'
import { Form, Row } from 'react-bootstrap'
import { map } from 'lodash'
import { SelectValue } from '../../shared/models'

interface Props {
  values: SelectValue[]
  onSelect: Function
  formElementLabel?: string
  initialLabel?: string | null
  default?: SelectValue
  inline?: boolean
  selected?: any
  disabled?: boolean
  multiple?: boolean
}

const SelectBox = ({
  values = [],
  initialLabel,
  formElementLabel,
  inline = false,
  onSelect,
  selected = '',
  disabled = false,
  multiple = false,
}: Props): JSX.Element => {
  return (
    <Form.Group as={Row} controlId="formGridState" className="align-middle">
      <Form.Label
        className={
          inline ? 'col-sm-4 font-weight-bold' : 'col-sm-12 font-weight-bold'
        }
      >
        {formElementLabel}
      </Form.Label>
      <Form.Control
        as="select"
        multiple={multiple}
        className={inline ? 'col-sm-8' : 'col-sm-12'}
        value={selected}
        disabled={disabled}
        onChange={({ target }: SyntheticEvent) => onSelect(target)}
      >
          { initialLabel &&
              <option
                  defaultValue=""
                  value=""
                  disabled={!initialLabel}
                  className="disabled"
              >
                  {initialLabel}
              </option>
          }
        {map(values, ({ value, label }, index) => (
          <option value={value} key={index}>
            {label}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  )
}

export default SelectBox
