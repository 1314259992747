import React, { Component } from 'react'
import { AppModeEnum } from '../../../shared/models'
import AuditTrailTable from '../audit-trail-table/AuditTrailTable'
import { Auditable } from '../../../shared/models'
import moment from 'moment'

import {
  getAuditComps,
  searchAuditCompanies,
  searchFounders,
  searchTaggers,
  searchTesters,
  searchReviewers,
  getAuditCompanyData,
  getUsers,
  cancelActiveRequests,
} from '../../../services'

interface PayloadAccessors {
  valueAccessor: string
  displayValueAccessor?: string
}

interface State {
  auditData: {
    data: Auditable[]
    totalCompsFound: number
    lockedCompaniesData: Auditable[]
    lockedCompaniesSelected: []
  }
  filterParams: any
  filterData: {
    companies: []
    founders: []
    taggers: []
    testers: []
    reviewers: []
  }
  paginationParams: {
    offset: number
    limit: number
  }
  isLoaded: boolean
  taggers: []
  reviewers: []
}
interface Props {}

class AuditTrailDistributor extends Component<Props, State> {
  searchCompanies: Function
  searchFounders: Function
  searchTaggers: Function
  searchTesters: Function
  searchReviewers: Function
  minCharsToPerformSearch: number
  auditTrailLimit: number
  refreshInterval: any

  constructor(props) {
    super(props)
    this.minCharsToPerformSearch = 3
    this.auditTrailLimit = 10
    const DEBOUNCE_TIME = 500
    this.refreshInterval = 0
    this.state = {
      filterParams: {
        offset: null,
        limit: null,
        companies: [],
        founders: [],
        taggers: [],
        testers: [],
        reviewers: [],
        companySearch: '',
        founderSearch: '',
        taggerSearch: '',
        testerSearch: '',
        reviewerSearch: '',
        compSearchLimit: this.auditTrailLimit,
        founderSearchLimit: this.auditTrailLimit,
        taggerSearchLimit: this.auditTrailLimit,
        testerSearchLimit: this.auditTrailLimit,
        reviewerSearchLimit: this.auditTrailLimit,
        foundInitDate: null,
        foundFinalDate: null,
        tagInitDate: null,
        tagFinalDate: null,
        testInitDate: null,
        testFinalDate: null,
        reviewInitDate: null,
        reviewFinalDate: null,
      },
      filterData: {
        companies: [],
        founders: [],
        taggers: [],
        testers: [],
        reviewers: [],
      },
      auditData: {
        data: [],
        totalCompsFound: 0,
        lockedCompaniesData: [],
        lockedCompaniesSelected: [],
      },
      paginationParams: {
        offset: 0,
        limit: 10,
      },
      isLoaded: false,
      taggers: [],
      reviewers: [],
    }

    this.searchCompanies = this.debounce(
      this.performCompaniesRequest,
      DEBOUNCE_TIME
    )

    this.searchFounders = this.debounce(
      this.performFoundersRequest,
      DEBOUNCE_TIME
    )

    this.searchTaggers = this.debounce(
      this.performTaggersRequest,
      DEBOUNCE_TIME
    )

    this.searchTesters = this.debounce(
      this.performTestersRequest,
      DEBOUNCE_TIME
    )

    this.searchReviewers = this.debounce(
      this.performReviewersRequest,
      DEBOUNCE_TIME
    )
  }

  componentDidMount = async (): Promise<void> => {
    await cancelActiveRequests()
    await this.getTaggingUsers()
    this.setState({ ...this.state, isLoaded: true })
  }

  componentWillUnmount(): void {
    clearInterval(this.refreshInterval)
  }

  performFilterRequest = async () => {
    const payload = this.formatFilterRequest()
    const getAuditCompsResult = await getAuditComps(
      payload,
      AppModeEnum.Distributors
    )
    const auditComps = getAuditCompsResult ? getAuditCompsResult.data : {}
    this.setFilterResponse(auditComps)
  }

  standarizeResponse = (items, accessors: PayloadAccessors) => {
    return items.map(item => {
      let { valueAccessor, displayValueAccessor } = accessors
      displayValueAccessor = displayValueAccessor || valueAccessor

      return {
        displayValue: item[displayValueAccessor],
        value: item[valueAccessor],
      }
    })
  }

  formatFilterRequest = () => {
    const { filterParams, paginationParams } = this.state
    const companies = this.getDropdownItemValues(filterParams.companies)
    const founders = this.getDropdownItemValues(filterParams.founders)
    const foundInitDate = filterParams.foundInitDate
    const foundFinalDate = filterParams.foundFinalDate
    const taggers = this.getDropdownItemValues(filterParams.taggers)
    const tagInitDate = filterParams.tagInitDate
    const tagFinalDate = filterParams.tagFinalDate
    const testers = this.getDropdownItemValues(filterParams.testers)
    const testInitDate = filterParams.testInitDate
    const testFinalDate = filterParams.testFinalDate
    const reviewers = this.getDropdownItemValues(filterParams.reviewers)
    const reviewInitDate = filterParams.reviewInitDate
    const reviewFinalDate = filterParams.reviewFinalDate
    const { offset, limit } = paginationParams

    return {
      companies,
      founders,
      foundInitDate,
      foundFinalDate,
      taggers,
      tagInitDate,
      tagFinalDate,
      testers,
      testInitDate,
      testFinalDate,
      reviewers,
      reviewInitDate,
      reviewFinalDate,
      offset,
      limit,
    }
  }

  debounce = (fn, time) => {
    let timeout
    return (...fnParams) => {
      const functionCall = () => fn(...fnParams)

      clearTimeout(timeout)
      timeout = setTimeout(functionCall, time)
    }
  }

  getDropdownItemValues = items => {
    return items.map(item => item.value)
  }

  setFilterResponse = filterResponse => {
    if (filterResponse) {
      const { total, comps } = filterResponse
      this.setState({
        auditData: {
          ...this.state.auditData,
          data: comps,
          totalCompsFound: total,
        },
      })
    }
  }

  resetAllFilters = () => {
    const today = moment().format('YYYY-MM-DD')
    this.setState(
      {
        auditData: {
          data: [],
          totalCompsFound: 0,
          lockedCompaniesSelected: [],
          lockedCompaniesData: [],
        },
        filterParams: {
          offset: null,
          limit: null,
          companies: [],
          founders: [],
          taggers: [],
          testers: [],
          reviewers: [],
          companySearch: '',
          founderSearch: '',
          taggerSearch: '',
          testerSearch: '',
          reviewerSearch: '',
          compSearchLimit: this.auditTrailLimit,
          founderSearchLimit: this.auditTrailLimit,
          taggerSearchLimit: this.auditTrailLimit,
          testerSearchLimit: this.auditTrailLimit,
          reviewerSearchLimit: this.auditTrailLimit,
          foundInitDate: today,
          foundFinalDate: today,
          tagInitDate: today,
          tagFinalDate: today,
          testInitDate: today,
          testFinalDate: today,
          reviewInitDate: today,
          reviewFinalDate: today,
        },
        paginationParams: {
          limit: 10,
          offset: 0,
        },
      },
      () => {
        this.setState({
          filterParams: {
            ...this.state.filterParams,
            foundInitDate: null,
            foundFinalDate: null,
            tagInitDate: null,
            tagFinalDate: null,
            testInitDate: null,
            testFinalDate: null,
            reviewInitDate: null,
            reviewFinalDate: null,
          },
        })
      }
    )
  }

  getTaggingUsers = async (): Promise<any> => {
    const usersResult = await getUsers(AppModeEnum.Distributors)
    const users = usersResult
      ? usersResult.data
      : { taggers: [], reviewers: [] }
    const taggers = this.standarizeResponse(users.taggers, {
      valueAccessor: 'user_id',
      displayValueAccessor: 'full_name',
    })
    const reviewers = this.standarizeResponse(users.reviewers, {
      valueAccessor: 'user_id',
      displayValueAccessor: 'full_name',
    })
    this.setState({ taggers, reviewers })
  }

  performCompaniesRequest = async (
    companyQuery,
    compSearchLimit
  ): Promise<any> => {
    const { filterData } = this.state
    if (companyQuery.length < this.minCharsToPerformSearch) {
      this.setState({ filterData: { ...filterData, companies: [] } })
      return
    }
    const searchAuditCompaniesResult = await searchAuditCompanies(
      AppModeEnum.Distributors,
      companyQuery,
      compSearchLimit
    )

    const auditCompanies = searchAuditCompaniesResult
      ? searchAuditCompaniesResult.data.map(auditCompany => {
          return {
            ...auditCompany,
            companyId: `${auditCompany.companyId}-${auditCompany.importId}-${
              auditCompany.version
            }`,
          }
        })
      : []

    const companies = this.standarizeResponse(auditCompanies, {
      valueAccessor: 'companyId',
      displayValueAccessor: 'name',
    })
    this.setState({ filterData: { ...filterData, companies } })
  }

  performFoundersRequest = async (
    founderQuery,
    founderSearchLimit
  ): Promise<any> => {
    const { filterData } = this.state
    if (founderQuery.length < this.minCharsToPerformSearch) {
      this.setState({ filterData: { ...filterData, founders: [] } })
      return
    }

    const searchFoundersResponse = await searchFounders(
      AppModeEnum.Distributors,
      founderQuery,
      founderSearchLimit
    )

    const foundersData = searchFoundersResponse
      ? searchFoundersResponse.data
      : []

    const founders = this.standarizeResponse(foundersData, {
      valueAccessor: 'user_id',
      displayValueAccessor: 'full_name',
    })

    this.setState({ filterData: { ...filterData, founders } })
  }

  performTaggersRequest = async (
    taggerQuery,
    taggerSearchLimit
  ): Promise<any> => {
    const { filterData } = this.state
    if (taggerQuery.length < this.minCharsToPerformSearch) {
      this.setState({ filterData: { ...filterData, taggers: [] } })
      return
    }

    const searchTaggersResponse = await searchTaggers(
      AppModeEnum.Distributors,
      taggerQuery,
      taggerSearchLimit
    )

    const taggersData = searchTaggersResponse ? searchTaggersResponse.data : []

    const taggers = this.standarizeResponse(taggersData, {
      valueAccessor: 'user_id',
      displayValueAccessor: 'full_name',
    })

    this.setState({ filterData: { ...filterData, taggers } })
  }

  performTestersRequest = async (
    testerQuery,
    testerSearchLimit
  ): Promise<any> => {
    const { filterData } = this.state
    if (testerQuery.length < this.minCharsToPerformSearch) {
      this.setState({ filterData: { ...filterData, testers: [] } })
      return
    }

    const searchTestersResponse = await searchTesters(
      AppModeEnum.Distributors,
      testerQuery,
      testerSearchLimit
    )

    const testersData = searchTestersResponse ? searchTestersResponse.data : []

    const testers = this.standarizeResponse(testersData, {
      valueAccessor: 'user_id',
      displayValueAccessor: 'full_name',
    })

    this.setState({ filterData: { ...filterData, testers } })
  }

  performReviewersRequest = async (
    reviewerQuery,
    reviewerSearchLimit
  ): Promise<any> => {
    const { filterData } = this.state
    if (reviewerQuery.length < this.minCharsToPerformSearch) {
      this.setState({ filterData: { ...filterData, reviewers: [] } })
      return
    }

    const searchReviewersResponse = await searchReviewers(
      AppModeEnum.Distributors,
      reviewerQuery,
      reviewerSearchLimit
    )

    const reviewersData = searchReviewersResponse
      ? searchReviewersResponse.data
      : []

    const reviewers = this.standarizeResponse(reviewersData, {
      valueAccessor: 'user_id',
      displayValueAccessor: 'full_name',
    })

    this.setState({ filterData: { ...filterData, reviewers } })
  }

  handleCompSearch = async ({ event }): Promise<void> => {
    event.persist()
    const { filterParams } = this.state
    filterParams.compSearchLimit = this.auditTrailLimit
    const { value: companyQuery } = event.target
    this.searchCompanies(companyQuery, filterParams.compSearchLimit)

    this.setState({
      filterParams: { ...filterParams, companySearch: companyQuery },
    })
  }

  handleFounderSearch = async ({ event }): Promise<void> => {
    event.persist()
    const { filterParams } = this.state
    const { value: founderQuery } = event.target
    this.searchFounders(founderQuery, filterParams.founderSearchLimit)

    this.setState({
      filterParams: { ...filterParams, founderSearch: founderQuery },
    })
  }

  handleTaggerSearch = async ({ event }): Promise<void> => {
    event.persist()
    const { filterParams } = this.state
    const { value: taggerQuery } = event.target
    this.searchTaggers(taggerQuery, filterParams.taggerSearchLimit)

    this.setState({
      filterParams: { ...filterParams, taggerSearch: taggerQuery },
    })
  }

  handleTesterSearch = async ({ event }): Promise<void> => {
    event.persist()
    const { filterParams } = this.state
    const { value: testerQuery } = event.target
    this.searchTesters(testerQuery, filterParams.testerSearchLimit)

    this.setState({
      filterParams: { ...filterParams, testerSearch: testerQuery },
    })
  }

  handleReviewerSearch = async ({ event }): Promise<void> => {
    event.persist()
    const { filterParams } = this.state
    const { value: reviewerQuery } = event.target
    this.searchReviewers(reviewerQuery, filterParams.reviewerSearchLimit)

    this.setState({
      filterParams: { ...filterParams, reviewerSearch: reviewerQuery },
    })
  }

  handlePagination = paginationParams => {
    this.setState({ paginationParams }, async () => {
      await this.performFilterRequest()
    })
  }

  handleResetFilterClick = () => {
    const { filterParams } = this.state
    const today = moment().format('YYYY-MM-DD')
    this.setState(
      {
        filterParams: {
          ...filterParams,
          companies: [],
          founders: [],
          taggers: [],
          testers: [],
          reviewers: [],
          companySearch: '',
          founderSearch: '',
          taggerSearch: '',
          testerSearch: '',
          reviewerSearch: '',
          compSearchLimit: this.auditTrailLimit,
          founderSearchLimit: this.auditTrailLimit,
          taggerSearchLimit: this.auditTrailLimit,
          testerSearchLimit: this.auditTrailLimit,
          reviewerSearchLimit: this.auditTrailLimit,
          foundInitDate: today,
          foundFinalDate: today,
          tagInitDate: today,
          tagFinalDate: today,
          testInitDate: today,
          testFinalDate: today,
          reviewInitDate: today,
          reviewFinalDate: today,
        },
        paginationParams: {
          limit: 10,
          offset: 0,
        },
        auditData: {
          ...this.state.auditData,
          lockedCompaniesData: [],
          lockedCompaniesSelected: [],
        },
      },
      async () => {
          this.setState({
            filterParams: {
              ...this.state.filterParams,
              foundInitDate: null,
              foundFinalDate: null,
              tagInitDate: null,
              tagFinalDate: null,
              testInitDate: null,
              testFinalDate: null,
              reviewInitDate: null,
              reviewFinalDate: null,
            },
          })
        await this.performFilterRequest()
      }
    )
  }

  handleApplyFiltersClick = async () => {
    await this.performFilterRequest()
  }

  toggleSelection = (selectedItem, allItemsSelected) => {
    allItemsSelected = allItemsSelected.slice(0)
    const selectedValues = allItemsSelected.map(item => item.value)

    const isAlreadySelected = selectedValues.includes(selectedItem.value)
    if (isAlreadySelected) {
      return {
        data: allItemsSelected.filter(
          item => item.value !== selectedItem.value
        ),
        isAlreadySelected: true,
      }
    }

    allItemsSelected.push(selectedItem)
    return {
      data: allItemsSelected,
      isAlreadySelected: false,
    }
  }

  handleCompSelection = async compSelected => {
    const [companyId, importId, version] = compSelected.value.split('-')
    const { auditData } = this.state
    let { lockedCompaniesSelected, lockedCompaniesData } = auditData
    const result = this.toggleSelection(compSelected, lockedCompaniesSelected)
    if (result.isAlreadySelected) {
      lockedCompaniesData = lockedCompaniesData.filter(
        item =>
          item.companyId !== companyId ||
          item.importId !== Number(importId) ||
          item.version !== Number(version)
      )
    } else {
      const companyResult = await getAuditCompanyData(
        companyId,
        importId,
        version,
        AppModeEnum.Distributors
      )
      const company = companyResult ? companyResult.data : {}
      lockedCompaniesData.push(company)
    }
    this.setState({
      auditData: {
        ...auditData,
        lockedCompaniesData,
        lockedCompaniesSelected: result.data,
      },
    })
  }

  handleFounderSelection = founderSelected => {
    const { filterParams } = this.state
    const { founders } = filterParams

    const founderSelecteds = this.toggleSelection(founderSelected, founders)
    this.setState(
      {
        filterParams: { ...filterParams, founders: founderSelecteds.data },
      },
      async () =>
        founderSelecteds.isAlreadySelected &&
        (await this.performFilterRequest())
    )
  }

  handleTaggerSelection = taggerSelected => {
    const { filterParams } = this.state
    const { taggers } = filterParams

    const taggerSelecteds = this.toggleSelection(taggerSelected, taggers)
    this.setState(
      {
        filterParams: { ...filterParams, taggers: taggerSelecteds.data },
      },
      async () =>
        taggerSelecteds.isAlreadySelected && (await this.performFilterRequest())
    )
  }

  handleTesterSelection = testerSelected => {
    const { filterParams } = this.state
    const { testers } = filterParams

    const testerSelecteds = this.toggleSelection(testerSelected, testers)
    this.setState(
      {
        filterParams: { ...filterParams, testers: testerSelecteds.data },
      },
      async () =>
        testerSelecteds.isAlreadySelected && (await this.performFilterRequest())
    )
  }

  handleReviewerSelection = reviewerSelected => {
    const { filterParams } = this.state
    const { reviewers } = filterParams

    const reviewerSelecteds = this.toggleSelection(reviewerSelected, reviewers)
    this.setState(
      {
        filterParams: { ...filterParams, reviewers: reviewerSelecteds.data },
      },
      async () =>
        reviewerSelecteds.isAlreadySelected &&
        (await this.performFilterRequest())
    )
  }

  dateSelection = selectedDates => {
    return {
      initDate: moment(selectedDates.startDate)
        .utc()
        .format(),
      endDate: moment(selectedDates.endDate)
        .utc()
        .format(),
    }
  }

  handleRefreshButtonClick = async () => {
    await this.performFilterRequest()
  }

  handleFoundDate = selectedDates => {
    const { filterParams } = this.state
    const { initDate, endDate } = this.dateSelection(selectedDates)
    this.setState({
      filterParams: {
        ...filterParams,
        foundInitDate: initDate,
        foundFinalDate: endDate,
      },
    })
  }

  handleTagDate = selectedDates => {
    const { filterParams } = this.state
    const { initDate, endDate } = this.dateSelection(selectedDates)
    this.setState({
      filterParams: {
        ...filterParams,
        tagInitDate: initDate,
        tagFinalDate: endDate,
      },
    })
  }

  handleTestDate = selectedDates => {
    const { filterParams } = this.state
    const { initDate, endDate } = this.dateSelection(selectedDates)
    this.setState({
      filterParams: {
        ...filterParams,
        testInitDate: initDate,
        testFinalDate: endDate,
      },
    })
  }

  handleReviewDate = selectedDates => {
    const { filterParams } = this.state
    const { initDate, endDate } = this.dateSelection(selectedDates)
    this.setState({
      filterParams: {
        ...filterParams,
        reviewInitDate: initDate,
        reviewFinalDate: endDate,
      },
    })
  }

  handleCompSearchScrollEnd = async () => {
    const { companySearch, compSearchLimit } = this.state.filterParams
    const nextLimit = compSearchLimit + this.auditTrailLimit
    this.setState({
      filterParams: {
        ...this.state.filterParams,
        compSearchLimit: nextLimit,
      },
    })
    await this.performCompaniesRequest(companySearch, nextLimit)
  }

  handleEvent = (eventToTrigger, eventParams) => {
    const events = {
      handlePagination: this.handlePagination,
      handleResetFilterClick: this.handleResetFilterClick,
      handleApplyFiltersClick: this.handleApplyFiltersClick,
      handleRefreshButtonClick: this.handleRefreshButtonClick,
      handleCompSearch: this.handleCompSearch,
      handleCompSelection: this.handleCompSelection,
      handleFounderSearch: this.handleFounderSearch,
      handleFounderSelection: this.handleFounderSelection,
      handleTaggerSearch: this.handleTaggerSearch,
      handleTaggerSelection: this.handleTaggerSelection,
      handleTesterSearch: this.handleTesterSearch,
      handleTesterSelection: this.handleTesterSelection,
      handleReviewerSearch: this.handleReviewerSearch,
      handleReviewerSelection: this.handleReviewerSelection,
      handleFoundDate: this.handleFoundDate,
      handleTagDate: this.handleTagDate,
      handleTestDate: this.handleTestDate,
      handleReviewDate: this.handleReviewDate,
      handleCompSearchScrollEnd: this.handleCompSearchScrollEnd,
    }

    return events[eventToTrigger](eventParams)
  }

  render(): JSX.Element {
    const {
      auditData,
      isLoaded,
      filterParams,
      filterData,
      taggers,
      reviewers,
    } = this.state

    return (
      <>
        {isLoaded ? (
          <AuditTrailTable
            auditData={auditData}
            handleEvent={this.handleEvent}
            appMode={AppModeEnum.Distributors}
            filterParams={filterParams}
            filterData={filterData}
            taggers={taggers}
            reviewers={reviewers}
          />
        ) : null}
      </>
    )
  }
}

export default AuditTrailDistributor
