import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Container, Form, Row, Col, Tab, Nav } from 'react-bootstrap'

import { SidebarActiveItem, DistributeToManager } from '../../../shared/models'
import { updateSidebarActiveItem } from '../../../store/actions'
import { redistributeQueueRoute } from '../../../shared/constants'
import RedistributeQueueToManager from './components/manager/RedistributeQueueToManager'
import RedistributeQueueToUser from './components/user/RedistributeQueueToUser'

interface State {}

interface DispatchProps {
  updateSidebarActiveItem: (key: SidebarActiveItem) => void
}

interface StateProps {}

type Props = StateProps & DispatchProps

const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch => ({
  updateSidebarActiveItem: (key: SidebarActiveItem) => {
    dispatch(updateSidebarActiveItem(key))
  },
})

class DistributorRedistributeQueue extends Component<Props, State> {
  componentDidMount(): void {
    this.props.updateSidebarActiveItem(redistributeQueueRoute.eventKey)
  }

  render(): JSX.Element {
    return (
      <Form className="form-container">
        <Container fluid className="margin-top-75 padding-bottom-30">
          <Tab.Container id="tabs-container" defaultActiveKey="1">
            <Row className="custom-tabs">
              <Col sm={7}>
                <Tab.Content className="align-middle">
                  <Tab.Pane eventKey="1" className="four">
                    <RedistributeQueueToUser isFinding={true} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="2" className="four">
                    <RedistributeQueueToUser isFinding={false} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="3" className="three">
                    <RedistributeQueueToManager
                      type={
                        DistributeToManager.FindingReviewToManagerFindingReview
                      }
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="4" className="three">
                    <RedistributeQueueToManager
                      type={
                        DistributeToManager.TaggingReviewToManagerTaggingReview
                      }
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="5" className="three">
                    <RedistributeQueueToManager
                      type={DistributeToManager.FinalReviewToManagerFinalReview}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
              <Col sm={5}>
                <Nav variant="pills" as="ul" className="flex-column">
                  <span className="navbar-brand"> Redistribute To User</span>
                  <Nav.Item>
                    <Nav.Link as="li" eventKey="1">
                      From Finding Queues
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link as="li" eventKey="2">
                      From Tagging Queues
                    </Nav.Link>
                  </Nav.Item>
                  <hr />
                  <span className="navbar-brand"> Redistribute To Manager</span>
                  <Nav.Item>
                    <Nav.Link as="li" eventKey="3">
                      From Manager Find Review
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link as="li" eventKey="4">
                      From Manager Tag Review
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link as="li" eventKey="5">
                      From Manager Final Review
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Form>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributorRedistributeQueue)
