import { get, post } from '../base/base-http-service'

import {
  GetCountriesResponse,
  DistributeToManager,
  QueueTypeEnum,
  GetBatchResponse,
} from '../../shared/models'

interface Sics {
  sic: string
  sicCompanies: number
}
interface GetSicsResponse {
  data: Sics[]
}

interface GetBatchFromSource {
  companyType: string
  companyStatus: string
  userId: number
}

export const distributorGetSicsForInitialFinding = async (
  sourceId
): Promise<GetSicsResponse> => {
  return get(
    `tasks/distributor/${sourceId}/finding/${QueueTypeEnum.FindingInitial}/sics`
  )
}

export const distributorGetSicsForInitialTagging = async (
  sourceId
): Promise<GetSicsResponse> => {
  return get(
    `tasks/distributor/${sourceId}/tagging/${QueueTypeEnum.TaggingInitial}/sics`
  )
}

export const distributorGetCountriesFromSystem = async (
  companyType,
  companyStatus
): Promise<GetCountriesResponse> => {
  return get(
    `distribution/fromSystem/${companyType}/${companyStatus}/countries`
  )
}

export const distributorGetBatchesFromSource = async (
  data: GetBatchFromSource
): Promise<GetBatchResponse> => {
  return get(
    `redistribution/fromSource/${data.companyType}/${data.companyStatus}/${
      data.userId
    }/batches`
  )
}

export const distributorGetBatchesFromSystem = async (
  companyType,
  companyStatus
): Promise<GetBatchResponse> => {
  return get(`distribution/fromSystem/${companyType}/${companyStatus}/batches`)
}

export const distributorGetSicsFromSystem = async (
  companyType,
  companyStatus
): Promise<GetSicsResponse> => {
  return get(`distribution/fromSystem/${companyType}/${companyStatus}/sics`)
}

export const distributorGetSicsByCountryFromSystem = async (
  companyType,
  companyStatus,
  domicileId
): Promise<GetSicsResponse> => {
  return get(
    `distribution/fromSystem/${companyType}/${companyStatus}/${domicileId}/sicsByCountry`
  )
}

export const getCountriesBySicForSourceFinding = async (
  sourceId: number,
  sic: string | null
): Promise<GetCountriesResponse> => {
  return get(
    `tasks/distributor/${sourceId}/finding/${
      QueueTypeEnum.FindingInitial
    }/countries/${sic ? sic : ''}`
  )
}

export const getCountriesBySicForSourceTagging = async (
  sourceId: number,
  sic: string | null
): Promise<GetCountriesResponse> => {
  return get(
    `tasks/distributor/${sourceId}/tagging/${
      QueueTypeEnum.TaggingInitial
    }/countries/${sic ? sic : ''}`
  )
}

export const redistributeReserveToUserDistributor = async ({
  sourceUserId,
  destinationUserId,
  domicileId,
  selectedBatch,
  taskCount,
  isFinding,
  sic,
}) => {
  return post(`distribution/distributor/${sourceUserId}`, {
    destinationUserId,
    domicile_id: domicileId,
    selectedBatch,
    taskCount,
    destinationType: isFinding
      ? QueueTypeEnum.FindingInitial
      : QueueTypeEnum.TaggingInitial,
    sic,
  })
}

const getQueueByReviewType = (
  type: DistributeToManager
): QueueTypeEnum | null => {
  switch (type) {
    case DistributeToManager.FindingReviewToManagerFindingReview:
      return QueueTypeEnum.FindingReview
    case DistributeToManager.TaggingReviewToManagerTaggingReview:
      return QueueTypeEnum.TaggingReview
    case DistributeToManager.FinalReviewToManagerFinalReview:
      return QueueTypeEnum.TaggingFinalReview
    default:
      return null
  }
}

export const distributeReserveToManagerDistributor = async ({
  destinationUserId,
  taskCount,
  type,
}) => {
  return post(`distribution/fromSystemToManager/distributor`, {
    destinationUserId,
    taskCount,
    destinationType: getQueueByReviewType(type),
  })
}

export const redistributeReserveToManagerDistributor = async ({
  sourceUserId,
  destinationUserId,
  taskCount,
  domicileId,
  selectedBatch,
  sic,
  type,
}) => {
  return post(`distribution/fromManagerToManager/distributor/${sourceUserId}`, {
    destinationUserId,
    taskCount,
    destinationType: getQueueByReviewType(type),
    domicile_id: domicileId,
    selectedBatch,
    sic,
  })
}
