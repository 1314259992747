export const ADD_REQUEST = 'ADD_REQUEST'
export const CANCEL_REQUESTS = 'CANCEL_REQUESTS'

export interface AddRequest {
  type: typeof ADD_REQUEST
  payload: { request: any }
}

export interface CancelRequests {
  type: typeof CANCEL_REQUESTS
}
export interface ActiveRequestsState {
  activeRequests: Array<any>
}
