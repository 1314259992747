import { get, patch } from './base/base-http-service'
import {
  TaggingPayload,
  AppModeEnum,
  ShowValueAddedEnum,
} from '../shared/models'

export const getNextTaggingTask = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return await get(`${getBase(activeMode)}/tagging/next`)
}

export const saveTaggingTask = async (
  payload: TaggingPayload,
  companyId: string,
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  if (!payload.showValueAdded || payload.showValueAdded === undefined) {
    // If showValueAdded does not exists on payload, we set ShowValueAddedEnum.No
    payload.showValueAdded = ShowValueAddedEnum.No
  }
  return await patch(`${getBase(activeMode)}/tagging`, companyId, payload)
}

export const getNextTaggingReviewTask = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return await get(`${getBase(activeMode)}/tagging/review/next`)
}

export const saveTaggingReview = async (
  payload: TaggingPayload,
  taggingId: string,
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return await patch(
    `${getBase(activeMode)}/tagging/review`,
    taggingId,
    payload
  )
}

export const getNextFinalReviewTask = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return await get(`${getBase(activeMode)}/tagging/final/next`)
}

export const saveFinalReview = async (
  payload: TaggingPayload,
  taggingId: string,
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return await patch(`${getBase(activeMode)}/tagging/final`, taggingId, payload)
}

const getBase = (activeMode: AppModeEnum): string => {
  if (activeMode === AppModeEnum.Distributors) {
    return `tasks/distributor`
  } else if (activeMode === AppModeEnum.ServiceProviders) {
    return `tasks/service-provider`
  }
  return ''
}
