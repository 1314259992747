import React, { Component } from 'react'
import { connect } from 'react-redux'
import { map } from 'lodash'

import { Container, Form, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'

import { systemManagerDashboardRoute } from '../../shared/constants'
import {
  SidebarActiveItem,
  SystemQueues,
  UserQueue,
  ManagerQueue,
} from '../../shared/models'
import { updateSidebarActiveItem } from '../../store/actions'

import {
  getSystemQueuesStatus,
  getUserQueuesStatus,
  getManagerQueuesStatus,
  cancelActiveRequests,
} from '../../services'

import RegularUserTable from './components/regular/RegularUserTable'
import ManagerTable from './components/manager/ManagerTable'
import SystemQueueTable from './components/system/SystemQueueTable'

import './SystemManagerDashboard.css'
import { AppState } from '../../store'
interface State {
  regularUsers: UserQueue[]
  managers: ManagerQueue[]
  systemQueues: SystemQueues | {}
  barUnit: number
}

interface DispatchProps {
  updateSidebarActiveItem: (key: SidebarActiveItem) => void
}

type Props = AppState & DispatchProps

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = dispatch => ({
  updateSidebarActiveItem: (key: SidebarActiveItem) => {
    dispatch(updateSidebarActiveItem(key))
  },
})

class SystemManagerDashboard extends Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      systemQueues: {},
      regularUsers: [],
      managers: [],
      barUnit: 0,
    }
  }

  async componentDidMount(): Promise<void> {
    this.props.updateSidebarActiveItem(systemManagerDashboardRoute.eventKey)
    await cancelActiveRequests()
    await this.getDashboardData()
  }

  getDashboardData(): void {
    this.getRegularUsersQueuesStatus()
    this.getManagerQueuesStatus()
    this.getSystemQueuesStatus()
  }

  async getSystemQueuesStatus(): Promise<void> {
    try {
      const systemQueuesStatusResult = await getSystemQueuesStatus(
        this.props.appMode.activeMode
      )
      const systemQueues = systemQueuesStatusResult
        ? systemQueuesStatusResult.data
        : []
      this.setState({ systemQueues })
    } catch (error) {
      throw error
    }
  }

  async getManagerQueuesStatus(): Promise<void> {
    try {
      const managerQueuesStatusResult = await getManagerQueuesStatus(
        this.props.appMode.activeMode
      )
      const managerQueues = managerQueuesStatusResult
        ? managerQueuesStatusResult.data
        : []
      this.setState({ managers: managerQueues })
    } catch (error) {
      throw error
    }
  }

  async getRegularUsersQueuesStatus(): Promise<void> {
    try {
      const getUserQueuesStatusResult = await getUserQueuesStatus(
        this.props.appMode.activeMode
      )
      const userQueues = getUserQueuesStatusResult
        ? getUserQueuesStatusResult.data
        : []
      this.setState({
        regularUsers: map(
          userQueues,
          ({
            user,
            findingDone,
            taggingDone,
            findingAssigned,
            taggingAssigned,
          }) => ({
            userName: user,
            availableFinding: findingAssigned,
            availableTagging: taggingAssigned,
            findingToday: findingDone,
            taggingToday: taggingDone,
            total:
              findingAssigned + taggingAssigned + findingDone + taggingDone,
          })
        ),
      })
    } catch (error) {
      throw error
    }
  }

  render(): JSX.Element {
    const { regularUsers, managers, systemQueues } = this.state

    return (
      <Form className="form-container system-manager-dashboard">
        <Container fluid className="margin-top-75 padding-bottom-30">
          <Row className="col-sm-12">
            <RegularUserTable regularUsers={regularUsers} />

            <Container className="col-sm-4 table-container margin-bottom-30">
              <h6 className="text-center margin-left-4vw">SYSTEM QUEUES</h6>
              <SystemQueueTable systemQueues={systemQueues} />

              <h6 className="text-center margin-top-20 margin-left-4vw">
                MANAGER QUEUES
              </h6>
              <ManagerTable managers={managers} />

              <span
                onClick={() => this.getDashboardData()}
                className="refresh-data"
              >
                <FontAwesomeIcon icon={faSync} />
              </span>
            </Container>
          </Row>
        </Container>
      </Form>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemManagerDashboard)
