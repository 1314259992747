import React from 'react'
import { Container } from 'react-bootstrap'
import { map } from 'lodash'

import RegularUserTotalsRow from './RegularUserTotalsRow'
import RegularUserRow from './RegularUserRow'

const RegularUserTable = ({ regularUsers }) => {
  return (
    <Container fluid className="col-sm-8 table-container">
      <h6 className="text-center">USER WORK QUEUES</h6>
      <table className="table table-striped ">
        <thead className="thead-purple">
          <tr className="text-center">
            <th className="align-top">
              Queue <br /> Owner
            </th>
            <th className="align-top">
              Finding <br /> Available <br />
              Now
            </th>
            <th className="align-top">
              Tagging <br /> Available <br /> Now
            </th>
            <th className="align-top">
              Finding <br /> Processed <br />
              Today
            </th>
            <th className="align-top">
              Tagging <br /> Processed <br /> Today
            </th>
            <th className="align-top empty" />
          </tr>
        </thead>
        <tbody>
          {map(regularUsers, (user, index) => (
            <RegularUserRow {...user} key={index} />
          ))}
          <RegularUserTotalsRow regularUsers={regularUsers} />
        </tbody>
      </table>
    </Container>
  )
}

export default RegularUserTable
