export const revenueFilters = [
  {
    displayValue: '>= $2.5 Million',
    value: 2.5,
  },
  {
    displayValue: '>= $5 Million',
    value: 5,
  },
  {
    displayValue: '>= $10 Million',
    value: 10,
  },
  {
    displayValue: '>= $25 Million',
    value: 25,
  },
  {
    displayValue: '>= $50 Million',
    value: 50,
  },
  {
    displayValue: '>= $100 Million',
    value: 100,
  },
]
