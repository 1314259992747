import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import {
  sidebarReducer,
  errorReducer,
  noDataReducer,
  loaderReducer,
  successReducer,
  appModeReducer,
  classificationReducer,
  activeRequestsReducer,
} from './reducers'

const rootReducer = combineReducers({
  sidebar: sidebarReducer,
  errors: errorReducer,
  noData: noDataReducer,
  loader: loaderReducer,
  success: successReducer,
  appMode: appModeReducer,
  classifications: classificationReducer,
  activeRequests: activeRequestsReducer,
})

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
)

export type AppState = ReturnType<typeof rootReducer>
