import React, { Component } from 'react'
import moment from 'moment'
import TaggingCompendiumTable from '../tagging-compendium-table/TaggingCompendiumTable'
import {
  AppModeEnum,
  Comparable,
  CompendiumStateEnum,
  CompendiumStateOptions,
  FrontEndError,
  TaggingHistoryCompany,
} from '../../../shared/models'
import {
  definedUrlFilters,
  revenueFilters,
  deprecatedCompsFilter,
} from '../../../shared/constants'
import {
  exportFoundComps,
  getCompanyHistory,
  getCompsCount,
  getComps,
  getCompsDetails,
  getCountries,
  getSICS,
  getSourceTypes,
  searchCompanies,
  getMajorClassifications,
  generateReport,
} from '../../../services'
import { setSuccess, setErrors } from '../../../store/actions'

interface DropdownData {
  displayValue: number | string
  value: any
}

interface PayloadAccessors {
  valueAccessor: string
  displayValueAccessor?: string
}

interface State {
  filtersChanged: boolean
  lockedCompanies: Array<any>
  comps: Array<any>
  majorsNonStandarized: Array<any>
  batchName: string
  destinationUserId: number
  compData: {
    data: Comparable[]
    totalCompsFound: number
    lockedCompData: Comparable[]
  }
  filterParams: any
  filterData: {
    sics: DropdownData[]
    countries: DropdownData[]
    companies: DropdownData[]
    majors: DropdownData[]
    minors: DropdownData[]
    sourceTypes: DropdownData[]
    revenue: DropdownData[]
    stateOptions: CompendiumStateOptions[]
    definedUrl: DropdownData[]
    showDeprecated: DropdownData[]
  }
  paginationParams: {
    offset: number
    limit: number
  }
  taggingHistories: TaggingHistoryCompany
  modalOpen: boolean
  isDistributeCompsModalOpen: boolean
  compSelected: any
  isLoaded: boolean
  isSubmitting: boolean
  disableExport: boolean
  lastFilterExecuted
}

interface Props {}

class TaggingCompendiumServiceProvider extends Component<Props, State> {
  searchCompanies: Function
  minCharsToPerformCompanySearch: number
  initialCompSearchLimit: number

  constructor(props) {
    super(props)
    this.minCharsToPerformCompanySearch = 3
    this.initialCompSearchLimit = 10

    const DEBOUNCE_TIME = 500

    const initialCompendiumStateOptions: CompendiumStateOptions[] = [
      { label: 'Untagged', value: CompendiumStateEnum.Untagged },
      { label: 'Tagged', value: CompendiumStateEnum.Tagged },
      { label: 'Discarded', value: CompendiumStateEnum.Discarded },
      { label: 'In Progress', value: CompendiumStateEnum.InProgress },
    ]

    this.state = {
      filtersChanged: false,
      lockedCompanies: [],
      majorsNonStandarized: [],
      comps: [],
      batchName: '',
      destinationUserId: 0,
      disableExport: false,
      filterParams: {
        country: [],
        companySearch: '',
        companies: [],
        sics: [],
        compSearchLimit: this.initialCompSearchLimit,
        offset: null,
        limit: null,
        majors: [],
        minors: [],
        sourceTypes: [],
        revenue: [],
        dateStart: null,
        dateEnd: null,
        selectedStateOption: CompendiumStateEnum.Untagged,
        definedUrl: [],
        showDeprecated: [],
      },
      filterData: {
        sics: [],
        countries: [],
        companies: [],
        majors: [],
        minors: [],
        sourceTypes: [],
        revenue: [],
        stateOptions: initialCompendiumStateOptions,
        definedUrl: [],
        showDeprecated: [],
      },
      compData: {
        data: [],
        totalCompsFound: 0,
        lockedCompData: [],
      },
      paginationParams: {
        offset: 0,
        limit: 10,
      },
      taggingHistories: {
        companyId: 0,
        compIdentifier: '',
        taskId: 0,
        updatedat: '',
        country: '',
        compName: '',
        sic: '',
        sourceType: 0,
        dataProvidedDescription: '',
        websiteBasedInfo: '',
        history: [],
      },
      modalOpen: false,
      compSelected: undefined,
      isLoaded: false,
      isSubmitting: false,
      isDistributeCompsModalOpen: false,
      lastFilterExecuted: {
        sics: [],
        countries: [],
        companies: [],
        majors: [],
        minors: [],
        sourceTypes: [],
        revenue: [],
        definedUrl: [],
        showDeprecated: [],
      },
    }
    this.searchCompanies = this.debounce(
      this.performCompaniesRequest,
      DEBOUNCE_TIME
    )
  }

  componentDidMount = async (): Promise<void> => {
    await Promise.all([
      this.handleGetCountries(),
      this.handleGetSics(),
      this.handleGetSourceTypes(),
      this.handleGetMajorClassifications(),
    ])

    this.setState({
      filterData: {
        ...this.state.filterData,
        revenue: revenueFilters,
        definedUrl: definedUrlFilters,
        showDeprecated: deprecatedCompsFilter,
      },
      isLoaded: true,
    })
  }

  componentDidUpdate = (_prevProps, prevState: State): void => {
    if (this.state.filtersChanged && !prevState.filtersChanged) {
      setSuccess(
        'You have changed the search criteria. Please click "Apply Filter" to rerun the search'
      )
    }
  }

  handleGetCountries = async () => {
    const countriesResult = await getCountries(AppModeEnum.ServiceProviders)
    const countries = countriesResult ? countriesResult.data : []
    const countriesDataStandarized = this.standarizeResponse(countries, {
      valueAccessor: 'country_id',
      displayValueAccessor: 'name',
    })
    this.setState({
      filterData: {
        ...this.state.filterData,
        countries: countriesDataStandarized,
      },
    })
  }

  handleGetSics = async () => {
    const sicsResult = await getSICS(AppModeEnum.ServiceProviders)
    const sics = sicsResult ? sicsResult.data : []
    const sicsDataStandarized = this.standarizeResponse(sics, {
      valueAccessor: 'sicNumber',
    })
    this.setState({
      filterData: {
        ...this.state.filterData,
        sics: sicsDataStandarized,
      },
    })
  }

  handleGetSourceTypes = async () => {
    const sourceTypesResult = await getSourceTypes(AppModeEnum.ServiceProviders)
    const sourceTypes = sourceTypesResult ? sourceTypesResult.data : []
    const sourceTypesDataStandarized = this.standarizeResponse(sourceTypes, {
      valueAccessor: 'dataSourceId',
      displayValueAccessor: 'dataSourceLabel',
    })
    this.setState({
      filterData: {
        ...this.state.filterData,
        sourceTypes: sourceTypesDataStandarized,
      },
    })
  }

  handleGetMajorClassifications = async () => {
    const majorClassificationsResult = await getMajorClassifications()
    const majors = majorClassificationsResult
      ? majorClassificationsResult.data
      : []
    const majorsStandarized = this.standarizeResponse(majors, {
      valueAccessor: 'majorClassificationId',
      displayValueAccessor: 'name',
    })
    this.setState({
      filterData: {
        ...this.state.filterData,
        majors: majorsStandarized,
        minors: this.loadAllMinors(majors).minorsStandarized,
      },
      majorsNonStandarized: majors,
    })
  }

  performFilterRequest = async () => {
    const payload = this.formatFilterRequest()
    this.setState({ lastFilterExecuted: payload })
    const { compData, filterParams, filtersChanged } = this.state
    if (filtersChanged) {
      const compsCountResult = await getCompsCount(
        payload,
        AppModeEnum.ServiceProviders
      )
      const totalCompsFound = compsCountResult
        ? compsCountResult.data.counter
        : 0
      if (filterParams.selectedStateOption === CompendiumStateEnum.Untagged) {
        this.setState({
          comps: [],
          disableExport: filterParams.showDeprecated.some(
            option => option.value
          ),
          compData: {
            ...compData,
            totalCompsFound,
            data: [],
          },
          filtersChanged: false,
          paginationParams: {
            limit: 10,
            offset: 0,
          },
        })
      } else {
        const compsResult = await getComps(
          payload,
          AppModeEnum.ServiceProviders
        )
        const comps = compsResult ? compsResult.data : []
        const pageNumber = (payload.offset + 10) / 10
        const companyIds = comps
          .filter(comp => comp.pageNumber === pageNumber)
          .map(comp => comp.companyId)
        const result = await getCompsDetails(
          { companyIds },
          AppModeEnum.ServiceProviders
        )
        this.setState({
          disableExport: filterParams.showDeprecated.some(
            option => option.value
          ),
          comps,
          compData: {
            ...compData,
            totalCompsFound,
            data: result.data.comps,
          },
          filtersChanged: false,
          paginationParams: {
            limit: 10,
            offset: 0,
          },
        })
        if (totalCompsFound > 100) {
          setSuccess(
            `Your search only returned a sample of 100 results. Please filter your search further`,
            false
          )
        }
      }
    }
  }

  standarizeResponse = (items, accessors: PayloadAccessors) => {
    if (items.length === 0) {
      return []
    }

    return items.map(item => {
      let { valueAccessor, displayValueAccessor } = accessors
      displayValueAccessor = displayValueAccessor || valueAccessor

      return {
        displayValue: String(item[displayValueAccessor]),
        value: item[valueAccessor],
        lastOfGroup: item.lastOfGroup ? true : false,
      }
    })
  }

  formatFilterRequest = () => {
    const { filterParams, paginationParams, batchName } = this.state
    const domicileId = this.getDropdownItemValues(filterParams.country)
    const sics = this.getDropdownItemValues(filterParams.sics)
    const lockedCompIds = this.getDropdownItemValues(filterParams.companies)
    const majors = this.getDropdownItemValues(filterParams.majors)
    const minors = this.getDropdownItemValues(filterParams.minors)
    const sourceTypes = this.getDropdownItemValues(filterParams.sourceTypes)
    const revenue = this.getDropdownItemValues(filterParams.revenue)
    const definedUrl = this.getDropdownItemValues(filterParams.definedUrl)
    const showDeprecated = this.getDropdownItemValues(
      filterParams.showDeprecated
    )
    const { offset, limit } = paginationParams
    const { dateStart, dateEnd } = filterParams
    const stateOption = filterParams.selectedStateOption
    return {
      sics,
      batchName,
      lockedCompIds,
      offset,
      limit,
      sourceTypes,
      domicile_id: domicileId[0],
      majors,
      minors,
      revenue: revenue[0] * 1000000,
      dateStart,
      dateEnd,
      stateOption,
      definedUrl: typeof definedUrl[0] !== 'undefined' ? definedUrl[0] : null,
      showDeprecated:
        typeof showDeprecated[0] !== 'undefined' ? showDeprecated[0] : null,
    }
  }

  getDropdownItemValues = items => {
    return items.map(item => item.value)
  }

  setFilterResponse = ({ data }) => {
    const { comps, compData } = data
    this.setState({
      compData: {
        ...compData,
        lockedCompData: this.state.compData.lockedCompData,
        data: comps,
      },
    })
  }

  debounce = (fn, time) => {
    let timeout
    return (...fnParams) => {
      const functionCall = () => fn(...fnParams)

      clearTimeout(timeout)
      timeout = setTimeout(functionCall, time)
    }
  }

  resetAllFilters = () => {
    const { filterData } = this.state
    this.setState(
      {
        compData: {
          data: [],
          lockedCompData: [],
          totalCompsFound: 0,
        },
        filterParams: {
          country: [],
          companySearch: '',
          compSearchLimit: this.initialCompSearchLimit,
          companies: [],
          sics: [],
          offset: null,
          limit: null,
          majors: [],
          minors: [],
          sourceTypes: [],
          revenue: [],
          definedUrl: [],
          showDeprecated: [],
          dateStart: moment().format('YYYY-MM-DD'),
          dateEnd: moment().format('YYYY-MM-DD'),
          selectedStateOption: CompendiumStateEnum.Untagged,
        },
        filterData: {
          ...filterData,
          minors: this.loadAllMinors(this.state.majorsNonStandarized)
            .minorsStandarized,
        },
        paginationParams: {
          limit: 10,
          offset: 0,
        },
        isSubmitting: false,
        filtersChanged: true,
        lastFilterExecuted: {
          sics: [],
          countries: [],
          companies: [],
          majors: [],
          minors: [],
          sourceTypes: [],
          revenue: [],
          definedUrl: [],
          showDeprecated: [],
        },
      },
      () => {
        this.setState({
          filterParams: {
            ...this.state.filterParams,
            dateStart: null,
            dateEnd: null,
          },
        })
      }
    )
  }

  handlePagination = paginationParams => {
    this.setState({ paginationParams }, async () => {
      await this.performGetCompaniesDetailsByPagination()
    })
  }

  performGetCompaniesDetailsByPagination = async () => {
    const { paginationParams, comps, compData } = this.state
    const pageNumber = (paginationParams.offset + 10) / 10
    const companyIds = comps
      .filter(comp => comp.pageNumber === pageNumber)
      .map(comp => comp.companyId)
    const result = await getCompsDetails(
      { companyIds },
      AppModeEnum.ServiceProviders
    )
    this.setState({
      compData: {
        ...compData,
        data: result.data.comps,
      },
    })
  }

  handleSubmitButton = () => {
    this.setState({
      isSubmitting: true,
    })
  }

  handleExportListedCompsClick = async () => {
    this.handleSubmitButton()
    const {
      compData,
      lastFilterExecuted,
      filterParams,
      batchName,
      destinationUserId,
    } = this.state
    const totalComps = filterParams.companies.length || compData.totalCompsFound
    const lockedCompIds = this.getDropdownItemValues(filterParams.companies)
    const exportFilter = {
      ...lastFilterExecuted,
      lockedCompIds,
      batchName,
      destinationUserId,
    }
    await exportFoundComps(exportFilter, AppModeEnum.ServiceProviders)
    this.handleToggleisDistributeCompsModalOpen()
    this.resetAllFilters()
    setSuccess(
      `Successfully exported ${totalComps} comps to distribute reserve`
    )
  }

  handleToggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen,
    }))
  }

  handleValidateCompaniesToExportLength = () => {
    const { compData } = this.state
    return compData.totalCompsFound <= 5000
  }

  handleToggleisDistributeCompsModalOpen = () => {
    if (!this.handleValidateCompaniesToExportLength()) {
      setErrors(
        new FrontEndError(
          'companiesToExportLength',
          `Cannot export companies. Limit of companies to export is 5000`
        )
      )
      return
    }
    this.setState(prevState => ({
      isDistributeCompsModalOpen: !prevState.isDistributeCompsModalOpen,
    }))
  }

  handleCompanyInfoClick = async ({ companyId }) => {
    const companyInfoResult = await getCompanyHistory(
      companyId,
      AppModeEnum.ServiceProviders
    )
    const data = companyInfoResult ? companyInfoResult.data : {}
    this.setState({ taggingHistories: data }, () => {
      this.handleToggleModal()
    })
  }

  handleCountrySelection = selectedCountry => {
    if (!this.handleValidateStateOptionSelect()) {
      return
    }
    const { filterParams } = this.state

    this.setState({
      filterParams: { ...filterParams, country: [selectedCountry] },
      filtersChanged: true,
    })
  }

  handleDefinedUrlSelection = definedUrl => {
    if (!this.handleValidateStateOptionSelect()) {
      return
    }
    const { filterParams } = this.state

    this.setState({
      filterParams: { ...filterParams, definedUrl: [definedUrl] },
      filtersChanged: true,
    })
  }

  handleDeprecatedCompsSelection = showDeprecated => {
    if (!this.handleValidateStateOptionSelect()) {
      return
    }
    const { filterParams } = this.state

    this.setState({
      filterParams: { ...filterParams, showDeprecated: [showDeprecated] },
      filtersChanged: true,
    })
  }

  handleRevenueSelection = selectedRevenue => {
    if (!this.handleValidateStateOptionSelect()) {
      return
    }
    const { filterParams } = this.state

    this.setState({
      filterParams: { ...filterParams, revenue: [selectedRevenue] },
      filtersChanged: true,
    })
  }

  handleSicSelection = selectedSic => {
    if (!this.handleValidateStateOptionSelect()) {
      return
    }
    const { filterParams } = this.state
    const { sics } = filterParams

    const selectedSics = this.toggleSelection(selectedSic, sics)

    this.setState({
      filterParams: { ...filterParams, sics: selectedSics.data },
      filtersChanged: true,
    })
  }

  setBatchName = (batchName: string) => {
    this.setState({
      batchName,
    })
  }

  setDestinationUserId = (destinationUserId: number) => {
    this.setState({
      destinationUserId,
    })
  }

  handleMajorSelection = selectedMajor => {
    if (!this.handleValidateStateOptionSelect()) {
      return
    }
    const { filterParams, filterData } = this.state
    const { majors } = filterParams

    const selectedMajors = this.toggleSelection(selectedMajor, majors)
    const {
      minorsSelectedAfterMajorchanged,
      minorsStandarized,
    } = selectedMajors.data.length
      ? this.loadMinorsAvaliable(selectedMajors.data)
      : this.loadAllMinors(this.state.majorsNonStandarized)

    this.setState({
      filterParams: {
        ...filterParams,
        majors: selectedMajors.data,
        minors: minorsSelectedAfterMajorchanged,
      },
      filterData: { ...filterData, minors: minorsStandarized },
      filtersChanged: true,
    })
  }
  loadAllMinors = majorsNonStandarized => {
    const allMajors = majorsNonStandarized
    const minorsAvalibleForSelection = this.extractMinorsFromMajors(allMajors)
    const minorsStandarized = this.standarizeResponse(
      minorsAvalibleForSelection,
      {
        valueAccessor: 'minorClassificationId',
        displayValueAccessor: 'name',
      }
    )
    return { minorsSelectedAfterMajorchanged: [], minorsStandarized }
  }

  extractMinorsFromMajors = majors => {
    return majors
      .map(major => {
        if (major.minorClassifications.length) {
          major.minorClassifications[
            major.minorClassifications.length - 1
          ].lastOfGroup = true
        }
        return major.minorClassifications
      })
      .flat()
  }

  loadMinorsAvaliable = majors => {
    const selectedMajorsIds = majors.map(majorSelected => majorSelected.value)
    const majorsWithMinorsAvaliable = this.state.majorsNonStandarized.filter(
      major => selectedMajorsIds.includes(major.majorClassificationId)
    )
    const minorsAvalibleForSelection = this.extractMinorsFromMajors(
      majorsWithMinorsAvaliable
    )

    const minorsStandarized = this.standarizeResponse(
      minorsAvalibleForSelection,
      {
        valueAccessor: 'minorClassificationId',
        displayValueAccessor: 'name',
      }
    )

    const idsMinorsAvalibleForSelection = minorsStandarized.map(
      minorAvaliable => minorAvaliable.value
    )

    const minorsSelectedAfterMajorchanged = this.state.filterParams.minors.filter(
      selectedMinor =>
        idsMinorsAvalibleForSelection.includes(selectedMinor.value)
    )
    return { minorsSelectedAfterMajorchanged, minorsStandarized }
  }

  handleMinorSelection = selectedMinor => {
    if (!this.handleValidateStateOptionSelect()) {
      return
    }
    const { filterParams } = this.state
    const { minors } = filterParams

    const selectedMinors = this.toggleSelection(selectedMinor, minors)

    this.setState({
      filterParams: { ...filterParams, minors: selectedMinors.data },
      filtersChanged: true,
    })
  }

  handleSourceTypeSelection = selectedSourceType => {
    if (!this.handleValidateStateOptionSelect()) {
      return
    }
    const { filterParams } = this.state
    const { sourceTypes } = filterParams

    const selectedSourceTypes = this.toggleSelection(
      selectedSourceType,
      sourceTypes
    )
    this.setState({
      filterParams: { ...filterParams, sourceTypes: selectedSourceTypes.data },
      filtersChanged: true,
    })
  }

  handleCompSelection = compSelected => {
    const { filterParams } = this.state
    const { companies } = filterParams
    const compSelecteds = this.toggleSelection(compSelected, companies)
    let lockedData = this.state.compData.lockedCompData
    let totalCompsFound = this.state.compData.totalCompsFound
    if (!compSelecteds.isAlreadySelected) {
      lockedData.push(
        this.state.lockedCompanies.find(
          comp => comp.companyId === compSelected.value
        )
      )
      totalCompsFound += 1
    } else {
      lockedData = lockedData.filter(
        locked => locked.companyId !== compSelected.value
      )
      totalCompsFound -= 1
    }
    this.setState({
      filterParams: { ...filterParams, companies: compSelecteds.data },
      compData: {
        ...this.state.compData,
        lockedCompData: lockedData,
        totalCompsFound,
      },
    })
  }

  toggleSelection = (selectedItem, allItemsSelected) => {
    allItemsSelected = allItemsSelected.slice(0)
    const selectedValues = allItemsSelected.map(item => item.value)

    const isAlreadySelected = selectedValues.includes(selectedItem.value)
    if (isAlreadySelected) {
      return {
        data: allItemsSelected.filter(
          item => item.value !== selectedItem.value
        ),
        isAlreadySelected: true,
      }
    }

    allItemsSelected.push(selectedItem)
    return {
      data: allItemsSelected,
      isAlreadySelected: false,
    }
  }

  handleRefreshButtonClick = () => {
    this.setState(
      {
        filtersChanged: true,
      },
      () => this.performFilterRequest()
    )
  }

  handleResetFilterClick = async () => {
    const { filterData } = this.state
    this.setState(
      {
        comps: [],
        filterParams: {
          country: [],
          companySearch: '',
          compSearchLimit: this.initialCompSearchLimit,
          companies: [],
          sics: [],
          offset: null,
          limit: null,
          majors: [],
          minors: [],
          sourceTypes: [],
          revenue: [],
          definedUrl: [],
          showDeprecated: [],
          dateStart: moment().format('YYYY-MM-DD'),
          dateEnd: moment().format('YYYY-MM-DD'),
          selectedStateOption: CompendiumStateEnum.Untagged,
        },
        filterData: {
          ...filterData,
          minors: this.loadAllMinors(this.state.majorsNonStandarized)
            .minorsStandarized,
        },
        paginationParams: {
          limit: 10,
          offset: 0,
        },
        isSubmitting: false,
        filtersChanged: true,
      },
      async () => {
        this.setState({
          filterParams: {
            ...this.state.filterParams,
            dateStart: null,
            dateEnd: null,
          },
        })
      }
    )
  }

  performCompaniesRequest = async (
    companyQuery,
    compSearchLimit
  ): Promise<any> => {
    const { filterData } = this.state
    if (companyQuery.length < this.minCharsToPerformCompanySearch) {
      this.setState({ filterData: { ...filterData, companies: [] } })
      return
    }

    const compsResult = await searchCompanies(
      AppModeEnum.ServiceProviders,
      companyQuery,
      compSearchLimit
    )
    const comps = compsResult ? compsResult.data : []
    const companyIds = comps.map(comp => comp.companyId)
    const detailsPayload = { companyIds }

    const compsDetailsResult = await getCompsDetails(
      detailsPayload,
      AppModeEnum.ServiceProviders
    )

    const data = compsDetailsResult ? compsDetailsResult.data : []
    const companies = this.standarizeResponse(data.comps, {
      valueAccessor: 'companyId',
      displayValueAccessor: 'compName',
    })

    this.setState({
      lockedCompanies: data.comps,
      filterData: { ...filterData, companies },
    })
  }

  dateSelection = selectedDates => {
    return {
      initDate: moment(selectedDates.startDate)
        .utc()
        .format(),
      endDate: moment(selectedDates.endDate)
        .utc()
        .format(),
    }
  }

  handleFinishedDate = selectedDates => {
    if (!this.handleValidateStateOptionSelect()) {
      return
    }
    const { filterParams } = this.state
    const { initDate, endDate } = this.dateSelection(selectedDates)
    this.setState({
      filterParams: {
        ...filterParams,
        dateStart: initDate,
        dateEnd: endDate,
      },
      filtersChanged: true,
    })
  }

  handleCompSearch = async ({ event }): Promise<void> => {
    if (!this.handleValidateStateOptionSelect()) {
      return
    }
    event.persist()
    const { filterParams } = this.state
    const { value: companyQuery } = event.target
    this.searchCompanies(companyQuery, filterParams.compSearchLimit)

    this.setState({
      filterParams: { ...filterParams, companySearch: companyQuery },
    })
  }

  handleApplyFiltersClick = async () => {
    if (!this.handleValidateStateOptionSelect()) {
      return
    }
    await this.performFilterRequest()
  }

  handleCompSearchScrollEnd = async () => {
    const { companySearch, compSearchLimit } = this.state.filterParams
    const nextLimit = compSearchLimit + this.initialCompSearchLimit

    this.setState({
      filterParams: {
        ...this.state.filterParams,
        compSearchLimit: nextLimit,
      },
    })

    await this.performCompaniesRequest(companySearch, nextLimit)
  }

  handleStateOptionChange = async (newStateOption: CompendiumStateEnum) => {
    this.setState({
      filterParams: {
        ...this.state.filterParams,
        selectedStateOption: newStateOption,
      },
      filtersChanged: true,
    })
  }

  handleValidateStateOptionSelect = () => {
    if (this.state.filterParams.selectedStateOption === undefined) {
      setErrors(
        new FrontEndError(
          'stateOption',
          'Please select a radio button before apply filters'
        )
      )
      return false
    }
    return true
  }

  handleGenerateReport = async () => {
    const payload = this.formatFilterRequest()
    try {
      await generateReport(payload, AppModeEnum.ServiceProviders)
      this.resetAllFilters()
      setSuccess(
        `Download has started. You can monitor the progress and download the report from the Download Repository page`,
        false
      )
    } catch (error) {
      setErrors(
        new FrontEndError(
          'generateReport',
          'Something failed trying to generate the report, try again'
        )
      )
    }
  }

  handleEvent = (eventToTrigger, eventParams) => {
    const events = {
      handlePagination: this.handlePagination,
      handleExportListedCompsClick: this.handleExportListedCompsClick,
      handleResetFilterClick: this.handleResetFilterClick,
      handleRefreshButtonClick: this.handleRefreshButtonClick,
      handleCompanyInfoClick: this.handleCompanyInfoClick,
      handleToggleModal: this.handleToggleModal,
      handleToggleisDistributeCompsModalOpen: this
        .handleToggleisDistributeCompsModalOpen,
      handleCompSearch: this.handleCompSearch,
      handleCountrySelection: this.handleCountrySelection,
      handleRevenueSelection: this.handleRevenueSelection,
      handleDefinedUrlSelection: this.handleDefinedUrlSelection,
      handleDeprecatedCompsSelection: this.handleDeprecatedCompsSelection,
      handleSicSelection: this.handleSicSelection,
      handleCompSelection: this.handleCompSelection,
      handleMajorSelection: this.handleMajorSelection,
      handleSourceTypeSelection: this.handleSourceTypeSelection,
      handleApplyFiltersClick: this.handleApplyFiltersClick,
      handleCompSearchScrollEnd: this.handleCompSearchScrollEnd,
      handleMinorSelection: this.handleMinorSelection,
      handleFinishedDate: this.handleFinishedDate,
      handleStateOptionChange: this.handleStateOptionChange,
      handleGenerateReport: this.handleGenerateReport,
    }

    return events[eventToTrigger](eventParams)
  }

  render(): JSX.Element {
    const {
      comps,
      compData,
      compSelected,
      modalOpen,
      isLoaded,
      filterParams,
      filterData,
      taggingHistories,
      isSubmitting,
      isDistributeCompsModalOpen,
      disableExport,
    } = this.state

    return (
      <>
        {isLoaded ? (
          <TaggingCompendiumTable
            disableExport={disableExport}
            setBatchName={this.setBatchName}
            setDestinationUserId={this.setDestinationUserId}
            comps={comps}
            compData={compData}
            compSelected={compSelected}
            modalOpen={modalOpen}
            isDistributeCompsModalOpen={isDistributeCompsModalOpen}
            handleEvent={this.handleEvent}
            appMode={AppModeEnum.ServiceProviders}
            filterParams={filterParams}
            filterData={filterData}
            taggingHistories={taggingHistories}
            isSubmitting={isSubmitting}
            batchName={this.state.batchName}
            destinationUserId={this.state.destinationUserId}
          />
        ) : null}
      </>
    )
  }
}

export default TaggingCompendiumServiceProvider
