import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { AppModeEnum } from '../../shared/models'
import { AppModeState } from '../../store/types'
import { AppState } from '../../store'

interface StateProps {
  appMode: AppModeState
}

interface State {}

interface DispatchProps {}

type Props = StateProps & DispatchProps

const mapStateToProps = (state: AppState) => ({ ...state })
const mapDispatchToProps = () => ({})

class AppModeChecker extends Component<Props, State> {
  render(): JSX.Element {
    const {
      appMode: { activeMode },
    } = this.props
    if (activeMode === AppModeEnum.NotSelected) {
      return <Redirect to="/mode" />
    }

    return <div />
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppModeChecker)
