import { get, post } from '../base/base-http-service'

import {
  GetCountriesResponse,
  QueueTypeEnum,
  GetBatchResponse,
} from '../../shared/models'
import { IRedistributeFromManagerParams } from '../../shared/models/request'

interface Sics {
  sic: string
  sicCompanies: number
}
interface GetSicsResponse {
  data: Sics[]
}

export const distributionGetCountriesFromSystem = async (
  companyType,
  companyStatus,
  needReview
): Promise<GetCountriesResponse> => {
  return get(
    `distribution/fromSystem/${companyType}/${companyStatus}/${
      needReview ? 'true' : 'false'
    }/countries`
  )
}

export const distributionGetBatchesFromSystem = async (
  companyType,
  companyStatus,
  needReview
): Promise<GetBatchResponse> => {
  return get(
    `distribution/fromSystem/${companyType}/${companyStatus}/${
      needReview ? 'true' : 'false'
    }/batches`
  )
}

export const distributionGetSicsFromSystem = async (
  companyType,
  companyStatus,
  needReview
): Promise<GetSicsResponse> => {
  return get(
    `distribution/fromSystem/${companyType}/${companyStatus}/${
      needReview ? 'true' : 'false'
    }/sics`
  )
}

export const distributionGetSicsByCountryFromSystem = async (
  companyType,
  companyStatus,
  needReview,
  domicileId
): Promise<GetSicsResponse> => {
  return get(
    `distribution/fromSystem/${companyType}/${companyStatus}/${
      needReview ? 'true' : 'false'
    }/${domicileId}/sicsByCountry`
  )
}

function getQueueByReviewType(originStatus, needReview) {
  if (originStatus === 'finding-tester-done') {
    return QueueTypeEnum.FindingReview
  } else if (originStatus === 'tagging-tester-done' && needReview) {
    return QueueTypeEnum.TaggingReview
  } else if (originStatus === 'tagging-tester-done' && !needReview) {
    return QueueTypeEnum.TaggingFinalReview
  } else {
    return null
  }
}

export const distributeReserveToUser = async ({
  companyType,
  originCompanyStatus,
  needReview,
  destinationUserId,
  selectedSics,
  taskCount,
  domicileId,
  selectedBatch,
}) => {
  return post(`distribution/fromSystemToUser/${companyType}`, {
    originCompanyStatus,
    needReview: needReview ? 'true' : 'false',
    destinationUserId,
    selectedSics,
    taskCount,
    domicile_id: domicileId,
    selectedBatch,
    destinationType:
      originCompanyStatus === 'finding-unassigned'
        ? QueueTypeEnum.FindingInitial
        : QueueTypeEnum.TaggingInitial,
  })
}

export const distributeReserveToManager = async ({
  companyType,
  originCompanyStatus,
  needReview,
  destinationUserId,
  selectedSics,
  taskCount,
  domicileId,
  selectedBatch,
}) => {
  return post(`distribution/fromSystemToManager/${companyType}`, {
    originCompanyStatus,
    needReview: needReview ? 'true' : 'false',
    destinationUserId,
    selectedSics,
    taskCount,
    domicile_id: domicileId,
    selectedBatch,
    destinationType: getQueueByReviewType(originCompanyStatus, needReview),
  })
}

export const getCountriesToRedistributeFromManager = async ({
  companyType,
  companyStatus,
  sourceId,
}: IRedistributeFromManagerParams) => {
  return get(
    `redistribution/fromSource/${companyType}/${companyStatus}/${sourceId}/countries/${null}`
  )
}
export const getCountriesBySicToRedistributeFromManager = async ({
  companyType,
  companyStatus,
  sourceId,
  sic,
}: IRedistributeFromManagerParams) => {
  return get(
    `redistribution/fromSource/${companyType}/${companyStatus}/${sourceId}/countries/${sic}`
  )
}
export const getSicsToRedistributeFromManager = async ({
  companyType,
  companyStatus,
  sourceId,
}: IRedistributeFromManagerParams) => {
  return get(
    `redistribution/fromSource/${companyType}/${companyStatus}/${sourceId}/sics`
  )
}
