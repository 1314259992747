import React, { Component } from 'react'
import { Alert } from 'react-bootstrap'
import { connect } from 'react-redux'
import { map, isEmpty } from 'lodash'

import { AppState } from '../../../store'
import { ErrorState } from '../../../store/types'
import { clearErrors } from '../../../store/actions'

import './ErrorBanner.css'

interface State {}

interface StateProps {
  errors: ErrorState
}

interface DispatchProps {}

type Props = StateProps & DispatchProps

const mapStateToProps = (state: AppState) => ({ ...state })
const mapDispatchToProps = () => ({})

class ErrorBanner extends Component<Props, State> {
  render(): JSX.Element {
    const {
      errors: { errors },
    } = this.props
    if (isEmpty(errors)) {
      return <div />
    }

    return (
      <Alert variant="danger" id="custom-alert">
        <span className="close" onClick={() => clearErrors()}>
          X
        </span>
        {map(errors, ({ msg, param }, index) => (
          <span key={index}>
            {msg || errors.global || errors.Error}{' '}
            {param ? ` for ${param}` : ''}
          </span>
        ))}
      </Alert>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorBanner)
