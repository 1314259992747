import React, { Component } from 'react'
import ReactDOM from 'react-dom'

interface State {
  coordenates: any
  isOpenMenu: Boolean
}

type Props = {
  dropdownTitle: string
  dropdownData: any
  selectedItems: any
  onChange: Function
  shouldShowLockIcon: boolean
  isMultiple: boolean
  isSearchable: boolean
  handleEvent?: any
  dropdown_container?: any
  searchValue?: string
  alignPopover: string
  handleSearchEventName: string
  bigger?: boolean
}

class DropDown extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      coordenates: {},
      isOpenMenu: false,
    }
  }

  componentDidMount(): void {
    const { dropdownTitle } = this.props

    const container = ReactDOM.findDOMNode(
      this.refs['dropdown_container']
    ) as HTMLDivElement
    const companySearchMenu = document.getElementById(
      `dropdown-menu-${dropdownTitle}`
    ) as HTMLElement
    document.addEventListener('click', this.handleClickOutside)
    companySearchMenu.addEventListener('scroll', this.handleScroll)

    this.setState({
      coordenates: container.getBoundingClientRect(),
    })
  }

  componentWillUnmount(): void {
    const { dropdownTitle } = this.props
    const companySearchMenu = document.getElementById(
      `dropdown-menu-${dropdownTitle}`
    ) as HTMLElement
    document.removeEventListener('click', this.handleClickOutside)
    companySearchMenu.addEventListener('scroll', this.handleScroll)
  }

  handleScroll = event => {
    const { handleEvent } = this.props
    const { target } = event
    const { scrollHeight, scrollTop, offsetHeight } = target

    const isScrollEnd = scrollHeight === scrollTop + offsetHeight
    if (isScrollEnd && handleEvent) handleEvent('handleCompSearchScrollEnd')
  }

  handleClickOutside = event => {
    const { dropdownTitle, isMultiple } = this.props

    const dropdownWrapper = document.querySelector(
      `#dropdownContainer${dropdownTitle}`
    ) as HTMLElement

    const clickedElement = event.target

    const hasClickedItem = clickedElement.className === 'item'
    if (hasClickedItem && isMultiple) {
      // Keep dropdown open
      return
    }

    const hasClickedInside = dropdownWrapper.contains(clickedElement)
    if (!hasClickedInside) {
      this.setState({ isOpenMenu: false })
    }
  }

  handleSearch = event => {
    const { handleEvent, handleSearchEventName } = this.props
    const MIN_CHARS_TO_PERFORM_REQUEST = 3
    const isOpenMenu = event.target.value.length >= MIN_CHARS_TO_PERFORM_REQUEST

    this.setState({ isOpenMenu })

    handleEvent(handleSearchEventName, { event })
  }

  toggleMenu = () => {
    const { isOpenMenu } = this.state

    this.setState({
      isOpenMenu: !isOpenMenu,
    })
  }

  formatDisplayValue = displayValue => {
    if (!displayValue) {
      return
    }
    let points = displayValue.length >= 100 ? '...' : ''
    return displayValue.substring(0, 100) + ' ' + points
  }

  checkIsSelected = item => {
    const { selectedItems } = this.props
    const selectedItemValues = selectedItems.map(
      selectedItem => selectedItem.value
    )

    return selectedItemValues.includes(item.value)
  }

  determinePlaceholder = () => {
    const { isMultiple, dropdownTitle, selectedItems } = this.props
    if (isMultiple || selectedItems[0] === undefined) {
      return dropdownTitle
    }
    const limitLength = 17
    const value = selectedItems[0].displayValue
    const displayValue =
      value.length > limitLength ? value.substr(0, limitLength) + ' ...' : value
    return displayValue
  }

  renderItemsToSelect = () => {
    const { onChange, dropdownData } = this.props

    if (dropdownData.length === 0) {
      return <p className="withoutItems">No items found</p>
    }

    return dropdownData.map(item => {
      const isSelected = this.checkIsSelected(item)
      if (!isSelected) {
        return (
          <div onClick={() => onChange(item)} key={item.value} className="item">
            {this.formatDisplayValue(item.displayValue)}
          </div>
        )
      }
      return <></>
    })
  }

  renderSelectedItems = () => {
    const {
      isMultiple,
      selectedItems,
      onChange,
      shouldShowLockIcon,
    } = this.props
    const { coordenates, isOpenMenu } = this.state

    if (!isMultiple) {
      return
    }

    return (
      <div
        ref="itemsSelected"
        className="filter_selected_items"
        style={{
          display: isOpenMenu ? 'inline' : 'none',
          top: (parseInt(coordenates.y) || 0) + 205,
          width:
            (parseInt(coordenates.width) || 0) +
            200 +
            (this.props.bigger ? 200 : 0),
          height: selectedItems.length * 34,
        }}
      >
        {selectedItems.map(item => {
          return (
            <div
              onClick={() => onChange(item)}
              key={item.value}
              className="item"
            >
              {shouldShowLockIcon ? '🔒' : ''}{' '}
              {this.formatDisplayValue(item.displayValue)}
            </div>
          )
        })}
      </div>
    )
  }

  renderDropdownType = () => {
    const { isSearchable, searchValue } = this.props

    const dynamicProps = {
      placeholder: this.determinePlaceholder(),
    }

    if (isSearchable) {
      return (
        <input
          ref="search_input"
          onChange={this.handleSearch}
          onClick={
            searchValue !== undefined && searchValue.length >= 3
              ? this.toggleMenu
              : () => {}
          }
          value={searchValue}
          className="dropdown-button"
          type="text"
          {...dynamicProps}
        />
      )
    }

    return (
      <button
        ref="search_input"
        onClick={this.toggleMenu}
        className="dropdown-button display_value"
      >
        {this.determinePlaceholder()}
      </button>
    )
  }

  render(): JSX.Element {
    const { coordenates, isOpenMenu } = this.state
    const {
      isMultiple,
      selectedItems,
      dropdownTitle,
      alignPopover,
    } = this.props

    return (
      <div id={`dropdownContainer${dropdownTitle}`} ref="dropdown_container">
        <div className="dropdown_container">
          <div>
            {isMultiple && selectedItems.length > 0 && (
              <div className="amountSelected">{selectedItems.length}</div>
            )}

            {this.renderDropdownType()}
          </div>
          <div ref="items_container" tabIndex={1}>
            <div
              className={
                alignPopover === 'left' ? 'arrow-left' : 'arrow-center'
              }
              style={{ display: isOpenMenu ? 'inline' : 'none' }}
            />
            <div
              ref="items"
              id={`dropdown-menu-${dropdownTitle}`}
              className="filter_select_item"
              style={{
                display: isOpenMenu ? 'inline' : 'none',
                top: (parseInt(coordenates.y) || 0) - 45,
                width:
                  (parseInt(coordenates.width) || 0) +
                  200 +
                  (this.props.bigger ? 200 : 0),
              }}
            >
              {this.renderItemsToSelect()}
            </div>

            {this.renderSelectedItems()}
          </div>
        </div>
      </div>
    )
  }
}

export default DropDown
