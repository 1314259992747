import React, { Component } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import styles from './DateRangePicker.module.css'
import moment from 'moment'

type Props = {
  dataRangeTitle: string
  handleEvent?: any
  dataRangeContainer?: any
  startDate?: string
  endDate?: string
  handleDateSelectionEvent: string
}

class DataRange extends Component<Props> {
  handleEvent = (event, date) => {
    const { startDate, endDate } = date
    const areDatesSelected = !!startDate && !!endDate
    if (!areDatesSelected) {
      return
    }

    if (event.type === 'apply') {
      const { handleEvent, handleDateSelectionEvent } = this.props
      handleEvent(handleDateSelectionEvent, { ...date })
    }
  }

  render(): JSX.Element {
    const { dataRangeTitle } = this.props
    const startDate = this.props.startDate
      ? moment(this.props.startDate)
      : undefined
    const endDate = this.props.endDate ? moment(this.props.endDate) : undefined

    return (
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onEvent={this.handleEvent}
      >
        <button ref="date_input" className={styles["data-range-button"]}>
          {dataRangeTitle}
        </button>
      </DateRangePicker>
    )
  }
}

export default DataRange
