export const websiteUrlPrefixRegex = new RegExp('^(https?:\\/\\/|www.).+$')
export const websiteUrlDotsRegex = new RegExp('\\.', 'g')
export const websiteUrlInvalidSpaceInBetweenDotsRegex = new RegExp(
  '(\\.([^a-zA-Z0-9])+)',
  'g'
)

export const modalContentInvalidWebsiteFormatMessage = {
  title:
    'A valid URL must be entered in the XBS Website field. The URL must conform to the following parameters:',
  content: [
    'Must begin with either "http://", "https://", or "www."',
    'Must contain a minimum of 1 period "."',
    'If the URL contains more than 1 period there must be a minimum of 1 text or numeric character (not including spaces or special characters) separating each of the periods',
  ],
}

export const modalContentWebsiteUrlConflictNoWebsiteDiscardReason = {
  title:
    'If the Discard Reason is "No Website" then no value may exist in the XBS Website Field',
  content: [],
}
