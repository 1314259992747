import { get, post } from './base/base-http-service'
import {
  ComparableFilterRequest,
  AppModeEnum,
  ComparableDetailsRequest,
} from '../shared/models'

export const getCompsCount = async (
  payload: ComparableFilterRequest,
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return await post(
    `${getBase(activeMode)}-compendium/search-comps-counter`,
    payload
  )
}

export const getComps = async (
  payload: ComparableFilterRequest,
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return await post(`${getBase(activeMode)}-compendium/search-comps`, payload)
}

export const getCompsDetails = async (
  payload: ComparableDetailsRequest,
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return await post(
    `${getBase(activeMode)}-compendium/get-companies-details`,
    payload
  )
}

export const exportFoundComps = async (
  payload: ComparableFilterRequest,
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return await post(
    `${getBase(activeMode)}-compendium/restart-tagging-process-by-comps-id`,
    payload
  )
}

export const getCompanyHistory = async (
  companyId: string,
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return await post(`${getBase(activeMode)}-compendium/get-company-history`, {
    companyId,
  })
}

export const getCountries = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return await get(`${getBase(activeMode)}-compendium/list-countries`)
}

export const getMajorClassifications = async () => {
  return await get(`major-classifications`)
}

export const getSICS = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return await get(`${getBase(activeMode)}-compendium/list-sics`)
}

export const getSourceTypes = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return await get(`${getBase(activeMode)}-compendium/list-origins`)
}

export const searchCompanies = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected,
  companyName: string,
  limit: number
) => {
  return await get(
    `${getBase(
      activeMode
    )}-compendium/search-companies-by-name/${companyName}?limit=${limit}`
  )
}

export const generateReport = async (
  payload: ComparableFilterRequest,
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return await post(
    `${getBase(activeMode)}-compendium/generate-report`,
    payload
  )
}

const getBase = (activeMode: AppModeEnum): string => {
  if (activeMode === AppModeEnum.Distributors) {
    return `distributor`
  } else if (activeMode === AppModeEnum.ServiceProviders) {
    return `service-provider`
  }
  return ''
}
