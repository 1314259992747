export enum AppModeEnum {
  NotSelected = 0,
  Distributors = 2,
  ServiceProviders = 3,
}

export const AppModeObject = {
  0: { text: '', opposite: 0 },
  2: { text: 'Distributors', opposite: 3 },
  3: { text: 'Service Providers', opposite: 2 },
}
