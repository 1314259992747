import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'

import { getUserLabel } from '../../services'

export const TaskPerformersInfo = ({ doneBy, testedBy }): JSX.Element => (
  <Row>
    <Col>
      <Form.Group>
        <p>
          <span className="font-weight-bold">Tagger:</span>{' '}
          {getUserLabel(doneBy)}
        </p>
        <p>
          <span className="font-weight-bold">Tester:</span>{' '}
          {getUserLabel(testedBy)}
        </p>
      </Form.Group>
    </Col>
  </Row>
)

export default TaskPerformersInfo
