export const ReportTypesObject = {
  compendium: {
    label: 'Compendium',
    name: 'compendium',
  },
  dailyTaggers: {
    label: 'Taggers daily process',
    name: 'dailyTaggers',
  },
  statisticsByCountry: {
    label: 'Statistics by country',
    name: 'statisticsByCountry',
  },
  batchProgress: {
    label: 'Batch Progress',
    name: 'batchProgress',
  },
  taggersProcessOvertime: {
    label: 'Taggers Process Overtime',
    name: 'taggersProcessOvertime',
  },
}

export const ReportTypesArray = [
  {
    label: 'Compendium',
    name: 'compendium',
  },
  {
    label: 'Taggers daily process',
    name: 'dailyTaggers',
  },
  {
    label: 'Statistics by country',
    name: 'statisticsByCountry',
  },
  {
    label: 'Batch Progress',
    name: 'batchProgress',
  },
  {
    label: 'Taggers Process Overtime',
    name: 'taggersProcessOvertime',
  },
]
