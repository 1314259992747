import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb } from 'react-bootstrap'
import { connect } from 'react-redux'
import { find, get } from 'lodash'

import { AppState } from '../../../store'
import { SidebarState } from '../../../store/types'

import { SidebarActiveItem } from '../../../shared/models'
import { routes } from '../../../shared/constants'

interface StateProps {
  sidebar: SidebarState
}

interface State {}

interface DispatchProps {}

type Props = StateProps & DispatchProps

const mapStateToProps = (state: AppState) => ({ ...state })
const mapDispatchToProps = () => ({})

class BreadcrumbNavigation extends Component<Props, State> {
  getActiveRouteLabel(eventKey: SidebarActiveItem): string {
    return get(find(routes, { eventKey }), 'label', '')
  }

  render(): JSX.Element {
    const {
      sidebar: { activeItem },
    } = this.props
    if (activeItem === SidebarActiveItem.Home) return <div />
    return (
      <div className="container-breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item as="div" active>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item as="div" active>
            {this.getActiveRouteLabel(activeItem)}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BreadcrumbNavigation)
