import {
  toggleNoDataPageDisplayState,
  NoDataState,
  TOGGLE_NO_DATA_PAGE,
} from '../types'
import { NoDataDisplayStateEnum } from '../../shared/models'

const initialState: NoDataState = {
  state: NoDataDisplayStateEnum.Hide,
  page: null,
}

export function noDataReducer(
  state = initialState,
  action: toggleNoDataPageDisplayState
): toggleNoDataPageDisplayState | any {
  switch (action.type) {
    case TOGGLE_NO_DATA_PAGE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
