import { SidebarActiveItem } from '../../shared/models'

export const UPDATE_ACTIVE_ITEM = 'UPDATE_ACTIVE_ITEM'

export interface SidebarActiveItemUpdate {
  type: typeof UPDATE_ACTIVE_ITEM
  payload: { activeItem: SidebarActiveItem }
}

export interface SidebarState {
  activeItem: SidebarActiveItem
}
