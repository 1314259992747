import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faInfo,
  faDownload,
} from '@fortawesome/free-solid-svg-icons'
import Pagination from '../../../shared/pagination/Pagination'

import {
  AppModeEnum,
  Comparable,
  CompendiumStateEnum,
  DistributorDiscardReasonEnum,
  ServiceProviderDiscardReasonEnum,
} from '../../../../shared/models'

type Props = {
  comps: Array<any>
  compData: {
    data: Comparable[]
    totalCompsFound: number
    lockedCompData: Comparable[]
  }
  handleEvent: (eventToTrigger: string, eventParams?: object) => void
  appMode: AppModeEnum
  isSubmitting: boolean
  selectedStateOption: CompendiumStateEnum
  shouldDisableExport: boolean
}

class CompendiumTable extends Component<Props> {
  handlePagination = paginationParams => {
    const { handleEvent } = this.props

    handleEvent('handlePagination', paginationParams)
  }

  renderComparableRows = () => {
    const { compData } = this.props
    return compData.data.map(comp => this.renderRow(comp))
  }

  renderLockedComparableRows = () => {
    const { compData } = this.props
    if (compData.lockedCompData.length > 0)
      return (
        <>
          <tr className="table-last-row">
            <td colSpan={13}>Locked Companies</td>
          </tr>

          {compData.lockedCompData.map(comp => this.renderRow(comp))}

          {compData.data.length !== 0 && (
            <tr className="table-last-row">
              <td colSpan={13}>Rest of the companies</td>
            </tr>
          )}
        </>
      )
  }

  formatRevenueValue = revenue => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })

    return formatter.format(revenue)
  }

  renderRow = comparable => {
    return (
      <tr key={comparable.companyId} className="compendium-table-row">
        <td>{comparable.compName}</td>
        <td>{comparable.country}</td>
        <td>{comparable.sicCode}</td>
        <td>{comparable.sourceTypeName}</td>
        <td>{this.formatRevenueValue(comparable.revenue)}</td>
        {this.renderAppModeDependentColumns(comparable)}
        {this.renderStateOptionDependentColumns(comparable)}
        <td>
          <span
            onClick={() =>
              this.props.handleEvent('handleCompanyInfoClick', {
                companyId: comparable.companyId,
              })
            }
            className="compendium-table-info-icon-wrapper"
          >
            <FontAwesomeIcon
              icon={faInfo}
              className="compendium-table-info-icon"
            />
          </span>
        </td>
      </tr>
    )
  }

  renderPagination = () => {
    const { comps } = this.props

    if (comps.length > 0) {
      return (
        <Pagination
          numberOfItems={comps.length}
          onPaginationChange={this.handlePagination}
        />
      )
    }
  }

  renderColumns = () => {
    const { compData } = this.props
    if (compData.data.length || compData.lockedCompData.length) {
      return (
        <tr>
          <th>Comparable Name</th>
          <th>Country</th>
          <th>SIC Code</th>
          <th>Database</th>
          <th>Revenue</th>
          {this.renderAppModeDependentHeaders()}
          <th>Discard Reason</th>
          {this.renderStateOptionDependentHeaders()}
          <th>Comparable Info</th>
        </tr>
      )
    }
  }

  renderAppModeDependentHeaders = () => {
    const { appMode } = this.props

    if (appMode === AppModeEnum.ServiceProviders) {
      return (
        <>
          <th>Major 1</th>
          <th>Minors</th>
          <th>Major 2</th>
          <th>Minors</th>
          <th>Major 3</th>
          <th>Minors</th>
        </>
      )
    }
  }

  renderStateOptionDependentHeaders = () => {
    const { selectedStateOption } = this.props
    if (selectedStateOption === CompendiumStateEnum.InProgress) {
      return (
        <>
          <th>Queue</th>
        </>
      )
    }
  }

  renderAppModeDependentColumns = comparable => {
    const { appMode } = this.props
    const majorOne = comparable.majorOne || ''
    const majorTwo = comparable.majorTwo || ''
    const majorThree = comparable.majorThree || ''
    const discardReason = comparable.discard_id

    if (appMode === AppModeEnum.ServiceProviders) {
      return (
        <>
          <td>{majorOne.name}</td>
          <td>{majorOne.minors}</td>
          <td>{majorTwo.name}</td>
          <td>{majorTwo.minors}</td>
          <td>{majorThree.name}</td>
          <td>{majorThree.minors}</td>
          <td>{ServiceProviderDiscardReasonEnum[discardReason]}</td>
        </>
      )
    }

    if (appMode === AppModeEnum.Distributors) {
      return (
        <>
          <td>{DistributorDiscardReasonEnum[discardReason]}</td>
        </>
      )
    }
  }

  renderStateOptionDependentColumns = comparable => {
    const { selectedStateOption } = this.props

    if (selectedStateOption === CompendiumStateEnum.InProgress) {
      return (
        <>
          <td>{comparable.queue}</td>
        </>
      )
    }
  }

  renderLastRow = () => {
    const { compData, handleEvent, isSubmitting } = this.props

    if (compData.totalCompsFound === 0) {
      return (
        <tr className="table-last-row">
          <td colSpan={13}>
            Whoops! There is no data in here. Try applying some filters.
          </td>
        </tr>
      )
    }

    return (
      <tr className="table-last-row">
        <td>
          Number of comps found:
          <span className="number-of-comps-listed">
            {compData.totalCompsFound}
          </span>
          {compData.totalCompsFound > 100 && (
            <span
              onClick={() => handleEvent('handleGenerateReport')}
              style={{ marginLeft: '20px' }}
            >
              <FontAwesomeIcon
                icon={faDownload}
                className="compendium-table-download-file"
              />
            </span>
          )}
        </td>

        <td colSpan={13} style={{ verticalAlign: 'middle' }}>
          <div>
            Export found comps to distribute reserve
            <span
              aria-disabled={this.props.shouldDisableExport}
              onClick={() =>
                handleEvent('handleToggleisDistributeCompsModalOpen')
              }
              className="compendium-table-distribute-reserve-icon-wrapper"
              style={isSubmitting || this.props.shouldDisableExport? { pointerEvents: 'none' } : undefined}
            >
              <FontAwesomeIcon
                className="compendium-table-distribute-reserve-icon"
                icon={faArrowRight}
              />
            </span>
          </div>
        </td>
      </tr>
    )
  }

  render(): JSX.Element {
    return (
      <div className="container_table_compendium">
        <Table
          style={{ width: '100%' }}
          striped
          bordered
          responsive="lg"
          className="compendium-table"
        >
          <thead className="compendium-table-head">
            {this.renderColumns()}
          </thead>
          <tbody className="compendium-table-body">
            {this.renderLockedComparableRows()}

            {this.renderComparableRows()}

            {this.renderLastRow()}
          </tbody>
        </Table>

        {this.renderPagination()}
      </div>
    )
  }
}

export default CompendiumTable
