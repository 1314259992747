import React, { Component } from 'react'
import CompendiumStateOption from '../../../shared/CompendiumStateOption'
import {
  CompendiumStateEnum,
  CompendiumStateOptions,
} from '../../../../shared/models'

interface State {}

interface Props {
  handleEvent: Function
  stateOptions: CompendiumStateOptions[]
  selectedOption?: CompendiumStateEnum
}

class StateOptions extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render(): JSX.Element {
    const { handleEvent, stateOptions, selectedOption } = this.props

    const handleStateOption = selectedOption =>
      handleEvent('handleStateOptionChange', selectedOption)

    return (
      <div className="compendium_state_options_container">
        <CompendiumStateOption
          handleChange={handleStateOption}
          selected={selectedOption}
          stateOptions={stateOptions}
        />
      </div>
    )
  }
}

export default StateOptions
