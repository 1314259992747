import React from 'react'
import { Row } from 'react-bootstrap'
import { map } from 'lodash'

import ManagerRow from './ManagerRow'

const ManagerTable = ({ managers }) => {
  return (
    <Row className="manager-queue">
      <table className="table table-striped">
        <thead>
          <tr className="text-center">
            <th className="align-top empty" colSpan={2} />
            <th className="align-top text-center">Find</th>
            <th className="align-top text-center">Tag</th>
            <th className="align-top text-center">Final</th>
          </tr>
        </thead>
        <tbody>
          {map(managers, (manager, index) => (
            <ManagerRow {...manager} key={index} />
          ))}
        </tbody>
      </table>
    </Row>
  )
}

export default ManagerTable
