import React, { Component } from 'react'
import { connect } from 'react-redux'

import { AppState } from '../../store'
import { AppModeEnum } from '../../shared/models'
import Modal from './components/Modal'

import UserFindingDistributor from './distributor/UserFindingDistributor'
import UserFindingServiceProvider from './service-provider/UserFindingServiceProvider'

interface State {
  modalOpen: boolean
  modalContent: string
  handleEvent: (eventToTrigger: string, eventParams?: object) => void
}

interface DispatchProps {}

type Props = AppState & DispatchProps

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = () => ({})

class UserFinding extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      modalContent: 'invalidWebsiteUrlFormat',
      handleEvent: this.handleEvent.bind(this),
    }
  }

  handleEvent = (eventToTrigger, eventParams) => {
    const events = {
      handleToggleModalInvalidWebsiteUrl: this
        .handleToggleModalInvalidWebsiteUrl,
      handleToggleModalNoWebsiteButOneWasProvided: this
        .handleToggleModalNoWebsiteButOneWasProvided,
    }
    return events[eventToTrigger](eventParams)
  }

  handleToggleModalInvalidWebsiteUrl = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      modalContent: 'invalidWebsiteUrlFormat',
    })
  }
  handleToggleModalNoWebsiteButOneWasProvided = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      modalContent: 'websiteUrlConflictNoWebsiteDiscardReason',
    })
  }

  render(): JSX.Element {
    const {
      appMode: { activeMode },
    } = this.props
    const { modalOpen, handleEvent, modalContent } = this.state
    return (
      <React.Fragment>
        <Modal
          isOpen={modalOpen}
          modalContent={modalContent}
          handleEvent={handleEvent}
        />
        {activeMode === AppModeEnum.Distributors ? (
          <UserFindingDistributor
            modalOpen={modalOpen}
            handleEvent={handleEvent}
          />
        ) : (
          <UserFindingServiceProvider
            modalOpen={modalOpen}
            handleEvent={handleEvent}
          />
        )}
      </React.Fragment>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserFinding)
