import { get, post } from './base/base-http-service'
import { ComparableFilterRequest, AppModeEnum } from '../shared/models'

export const getUsers = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return await get(`${getBase(activeMode)}-audit-trail/list-users`)
}

export const getAuditComps = async (
  payload: ComparableFilterRequest,
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return await post(`${getBase(activeMode)}-audit-trail/search-comps`, payload)
}

export const getAuditCompanyData = async (
  companyId: string,
  importId: string,
  version: string,
  activeMode: AppModeEnum = AppModeEnum.NotSelected
) => {
  return await post(`${getBase(activeMode)}-audit-trail/get-company`, {
    companyId,
    importId,
    version,
  })
}

export const searchAuditCompanies = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected,
  companyName: string,
  limit: number
) => {
  return await get(
    `${getBase(
      activeMode
    )}-audit-trail/search-companies-by-name/${companyName}?limit=${limit}`
  )
}

export const searchFounders = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected,
  founderName: string,
  limit: number
) => {
  return await get(
    `${getBase(
      activeMode
    )}-audit-trail/search-founders-by-name/${founderName}?limit=${limit}`
  )
}

export const searchTaggers = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected,
  taggerName: string,
  limit: number
) => {
  return await get(
    `${getBase(
      activeMode
    )}-audit-trail/search-taggers-by-name/${taggerName}?limit=${limit}`
  )
}

export const searchTesters = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected,
  testerName: string,
  limit: number
) => {
  return await get(
    `${getBase(
      activeMode
    )}-audit-trail/search-testers-by-name/${testerName}?limit=${limit}`
  )
}

export const searchReviewers = async (
  activeMode: AppModeEnum = AppModeEnum.NotSelected,
  reviewerName: string,
  limit: number
) => {
  return await get(
    `${getBase(
      activeMode
    )}-audit-trail/search-reviewers-by-name/${reviewerName}?limit=${limit}`
  )
}

const getBase = (activeMode: AppModeEnum): string => {
  if (activeMode === AppModeEnum.Distributors) {
    return `distributor`
  } else if (activeMode === AppModeEnum.ServiceProviders) {
    return `service-provider`
  }
  return ''
}
