import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'

import logo from '../../logo.jpg'

import { homeRoute } from '../../shared/constants'
import {
  SidebarActiveItem,
  AppModeEnum,
  AppModeObject,
} from '../../shared/models'
import { AppState } from '../../store'

import { updateAppMode, updateSidebarActiveItem } from '../../store/actions'
import { AppModeState } from '../../store/types'

import { cancelActiveRequests } from '../../services'

interface State {}

interface DispatchProps {
  updateSidebarActiveItem: (key: SidebarActiveItem) => void
  updateAppMode: (activeMode: AppModeEnum) => void
}

interface StateProps {
  appMode: AppModeState
}

type Props = StateProps & DispatchProps

const mapStateToProps = (state: AppState) => ({ ...state })
const mapDispatchToProps = dispatch => ({
  updateSidebarActiveItem: (key: SidebarActiveItem) => {
    dispatch(updateSidebarActiveItem(key))
  },
  updateAppMode: (activeMode: AppModeEnum) => {
    dispatch(updateAppMode(activeMode))
  },
})

class Home extends Component<Props, State> {
  async componentDidMount(): Promise<void> {
    await cancelActiveRequests()
    this.props.updateSidebarActiveItem(homeRoute.eventKey)
  }

  changeAppMode = () => {
    this.props.updateAppMode(
      AppModeObject[this.props.appMode.activeMode].opposite
    )
  }

  render(): JSX.Element {
    const {
      appMode: { activeMode },
    } = this.props
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo img img-fluid" alt="logo" />
          <p>Welcome to XBS Tagging!</p>
          <div className="App-mode-container">
            <p className="App-mode-text">
              Current App Mode: {AppModeObject[activeMode].text}
            </p>
            <Button className="btn App-mode-btn" onClick={this.changeAppMode}>
              Take me to{' '}
              {AppModeObject[AppModeObject[activeMode].opposite].text}
            </Button>
          </div>
        </header>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
