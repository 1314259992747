import React from 'react'

const ManagerRow = ({
  user_name,
  final_review_tagging,
  review_finding,
  review_tagging,
}) => {
  return (
    <tr>
      <td className="align-middle" colSpan={2}>
        {user_name}
      </td>
      <td className="align-middle text-center">{review_finding}</td>
      <td className="align-middle text-center">{review_tagging}</td>
      <td className="align-middle text-center">{final_review_tagging}</td>
    </tr>
  )
}

export default ManagerRow
