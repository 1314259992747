export const deprecatedCompsFilter = [
  {
    displayValue: 'Yes',
    value: 1,
  },
  {
    displayValue: 'No',
    value: null,
  },
]
