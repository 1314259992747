import React, { Component } from 'react'
import { connect } from 'react-redux'

import { SidebarActiveItem, AppModeEnum } from '../../shared/models'
import { updateSidebarActiveItem } from '../../store/actions'
import { AppState } from '../../store'
import DistributorRedistributeQueue from './distributor/DistributorRedistributeQueue'
import ServiceProviderRedistributeQueue from './service-provider/ServiceProviderRedistributeQueue'

interface State {}

interface DispatchProps {
  updateSidebarActiveItem: (key: SidebarActiveItem) => void
}

type Props = AppState & DispatchProps

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = dispatch => ({
  updateSidebarActiveItem: (key: SidebarActiveItem) => {
    dispatch(updateSidebarActiveItem(key))
  },
})

class RedistributeQueue extends Component<Props, State> {
  render(): JSX.Element {
    const {
      appMode: { activeMode },
    } = this.props

    if (activeMode === AppModeEnum.Distributors) {
      return <DistributorRedistributeQueue />
    } else {
      return <ServiceProviderRedistributeQueue />
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedistributeQueue)
