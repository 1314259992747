import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'
import CompendiumReportRepositoryTable from '../components/compendium-report-repository-table/CompendiumReportRepositoryTable'
import CompendiumReportRepositoryStats from '../components/compendium-report-repository-stats/CompendiumReportRepositoryStats'
import { getGeneratedDownloads } from '../../../../services'
import {
  AppModeEnum,
  DownloadItem,
  StatsDownloadEnum,
  StatsDownloadItem,
} from '../../../../shared/models'

interface State {
  items: DownloadItem[]
  isLoaded: boolean
  stats: StatsDownloadItem
}

interface Props {}

class CompendiumReportServiceProvider extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      isLoaded: false,
      stats: {
        total: 0,
        pending: 0,
        inProgress: 0,
        completed: 0,
      },
    }
  }

  componentDidMount = async (): Promise<void> => {
    this.handleGetRepositoryDownloads()
  }

  getItemsStats = (items: DownloadItem[]) => {
    const itemsStats: StatsDownloadItem = {
      total: items.length,
      pending: items.filter(item => item.status === StatsDownloadEnum.Pending)
        .length,
      inProgress: items.filter(
        item => item.status === StatsDownloadEnum.InProgress
      ).length,
      completed: items.filter(
        item => item.status === StatsDownloadEnum.Completed
      ).length,
    }
    return itemsStats
  }

  handleGetRepositoryDownloads = async () => {
    let { stats } = this.state
    const downloadResults = await getGeneratedDownloads(
      AppModeEnum.ServiceProviders
    )
    const items: DownloadItem[] = downloadResults ? downloadResults.data : []

    if (items.length) {
      stats = this.getItemsStats(items)
    }
    this.setState({ items, stats, isLoaded: true })
  }

  handleDownloadFileFromURI = uri => {
    const link = document.createElement('a')
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    link.remove()
  }

  handleRefresh = async () => {
    await this.handleGetRepositoryDownloads()
  }

  render(): JSX.Element {
    const { items, isLoaded, stats } = this.state

    return (
      <div className="download-repository-table">
        <span onClick={() => this.handleRefresh()} className="refresh-data">
          <FontAwesomeIcon icon={faSync} />
        </span>

        <CompendiumReportRepositoryStats stats={stats} />

        {isLoaded ? (
          <CompendiumReportRepositoryTable
            items={items}
            handleDownload={this.handleDownloadFileFromURI}
          />
        ) : null}
      </div>
    )
  }
}

export default CompendiumReportServiceProvider
