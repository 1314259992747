import React from 'react'
import { sumBy } from 'lodash'

const RegularUserTotalsRow = ({ regularUsers }) => {
  return (
    <tr className="row-height-20">
      <td />
      <td className="font-weight-bold align-middle text-center">
        {sumBy(regularUsers, 'availableFinding')}
      </td>
      <td className="font-weight-bold align-middle text-center">
        {sumBy(regularUsers, 'availableTagging')}
      </td>
      <td className="font-weight-bold align-middle text-center">
        {sumBy(regularUsers, 'findingDone')}
      </td>
      <td className="font-weight-bold align-middle text-center">
        {sumBy(regularUsers, 'taggingDone')}
      </td>
      <td />
    </tr>
  )
}

export default RegularUserTotalsRow
