import BatchDetail from './BatchDetails/BatchDetails'
import React, { Component } from 'react'
import { Batch } from '../../../../../../shared/models/batch'
import moment from 'moment'

interface State {
  isOpen: boolean
}

interface Props {
  item: Batch
  index: number
}

class CollapsableRow extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  handleOpenRow = (): void => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  render(): JSX.Element {
    const { index, item } = this.props
    return (
      <>
        <tr
          data-toggle="collapse"
          data-target={`.batch-${index}`}
          aria-controls={`batch-${index}`}
          onClick={this.handleOpenRow}
        >
          <td>{item.batchName}</td>
          <td>{item.companiesCount}</td>
          <td>{moment(item.createdAt).format('MM-DD-YYYY - HH:ss:mm')}</td>
          <td>
            {item.firstName} {item.lastName}
          </td>
        </tr>
        <tr
          className={`collapse multi-collapse batch-${index}`}
          id={`batch-${index}`}
        >
          {this.state.isOpen && <BatchDetail primary_batch_id={item.batchId} />}
        </tr>
      </>
    )
  }
}

export default CollapsableRow
