import React, { Component } from 'react'
import Filters from './components/Filters'
import Table from './components/Table'

import Modal from './components/Modal'
import DistributeCompsModal from './components/DistributeCompsModal'
import { Comparable, TaggingHistoryCompany } from '../../../shared/models'
import { AppModeEnum } from '../../../shared/models/enums'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'
import StateOptions from './components/StateOptions'

interface State {}

type Props = {
  comps: Array<any>
  batchName: string
  destinationUserId: number
  compData: {
    data: Comparable[]
    totalCompsFound: number
    lockedCompData: Comparable[]
  }
  setBatchName: (batchName: string) => void
  setDestinationUserId: (destinationUserId: number) => void
  handleEvent: (eventToTrigger: string, eventParams?: object) => void
  modalOpen: boolean
  compSelected: any
  appMode: AppModeEnum
  filterParams: any
  filterData: any
  taggingHistories: TaggingHistoryCompany
  isSubmitting: boolean
  isDistributeCompsModalOpen: boolean
  disableExport: boolean
}

class TaggingCompendiumTable extends Component<Props, State> {
  render(): JSX.Element {
    const {
      comps,
      compData,
      modalOpen,
      handleEvent,
      appMode,
      filterParams,
      filterData,
      taggingHistories,
      isSubmitting,
      isDistributeCompsModalOpen,
      setBatchName,
      batchName,
      setDestinationUserId,
      destinationUserId,
      disableExport,
    } = this.props

    return (
      <div className="compendium_container">
        <span
          onClick={() => handleEvent('handleRefreshButtonClick')}
          className="refresh-data"
        >
          <FontAwesomeIcon icon={faSync} />
        </span>

        <StateOptions
          handleEvent={handleEvent}
          stateOptions={filterData.stateOptions}
          selectedOption={filterParams.selectedStateOption}
        />

        <Filters
          appMode={appMode}
          handleEvent={handleEvent}
          filterParams={filterParams}
          filterData={filterData}
        />
        <Table
          appMode={appMode}
          compData={compData}
          handleEvent={handleEvent}
          isSubmitting={isSubmitting}
          comps={comps}
          selectedStateOption={filterParams.selectedStateOption}
          shouldDisableExport={disableExport}
        />

        <DistributeCompsModal
          open={isDistributeCompsModalOpen}
          handleEvent={handleEvent}
          setBatchName={setBatchName}
          batchName={batchName}
          setDestinationUserId={setDestinationUserId}
          destinationUserId={destinationUserId}
        />

        <Modal
          open={modalOpen}
          appMode={appMode}
          handleEvent={handleEvent}
          taggingHistories={taggingHistories}
        />
      </div>
    )
  }
}

export default TaggingCompendiumTable
