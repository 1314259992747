import React, { Component } from 'react'
import { Alert } from 'react-bootstrap'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import { AppState } from '../../../store'

import './SuccessBanner.css'
import { clearSuccess } from '../../../store/actions'

interface State {}

interface DispatchProps {}

type Props = AppState & DispatchProps

const mapStateToProps = (state: AppState) => ({ ...state })
const mapDispatchToProps = () => ({})

class SuccessBanner extends Component<Props, State> {
  setClearTimeout(): void {
    setTimeout(() => clearSuccess(), 2000)
  }

  render(): JSX.Element {
    const {
      success: { message, autoClose },
    } = this.props
    if (isEmpty(message)) {
      return <div />
    }
    if (autoClose) {
      this.setClearTimeout()
    }

    return (
      <Alert variant="success" className="success" id="custom-alert">
        <span className="close" onClick={() => clearSuccess()}>
          x
        </span>
        <span> {message} </span>
      </Alert>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuccessBanner)
