import React from 'react'
import { Link } from 'react-router-dom'
import { Nav } from 'react-bootstrap'
import { isUserAccessLevelValid } from '../../../../services'

const SidebarLink = ({ path, label, eventKey, accessLevelRoles }) => {
  return isUserAccessLevelValid(accessLevelRoles) ? (
    <Nav.Link as={Link} to={path} className="sidebar-link" eventKey={eventKey}>
      {label}
    </Nav.Link>
  ) : (
    <div />
  )
}

export default SidebarLink
