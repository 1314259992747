import { SidebarActiveItem, NoDataDisplayStateEnum } from '../../shared/models'
import { TOGGLE_NO_DATA_PAGE } from '../types'

export const toggleNoDataPage = (
  page: SidebarActiveItem,
  state: NoDataDisplayStateEnum
) => dispatch => {
  dispatch({
    type: TOGGLE_NO_DATA_PAGE,
    payload: {
      page,
      state,
    },
  })
}
