import { size, trim, upperFirst } from 'lodash'
import {
  ShowValueAddedEnum,
  FrontEndError,
  DistributorDiscardReasonEnum,
  ServiceProviderDiscardReasonEnum,
} from '../shared/models'
import {
  websiteUrlPrefixRegex,
  websiteUrlDotsRegex,
  websiteUrlInvalidSpaceInBetweenDotsRegex,
} from '../shared/constants'
import { setErrors } from '../store/actions'

const emailRegex: RegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/
const passwordRegex: RegExp = /[0-9a-zA-Z]{4,}/

export const validateEmail = (email: string): boolean => emailRegex.test(email)
export const validatePassword = (password: string): boolean =>
  passwordRegex.test(password)

export const validateStringLength = (
  stringToValidate: string,
  minimalLength: number = 4
): boolean => {
  return size(trim(stringToValidate)) >= minimalLength
}

export const validateWebsitesUrlFormat = function(
  websiteUrlToValidate: string
): boolean {
  const prefix = websiteUrlToValidate.match(websiteUrlPrefixRegex)
  const dots = websiteUrlToValidate.match(websiteUrlDotsRegex)
  const invalidSpaceInBetweenDots = websiteUrlToValidate.match(
    websiteUrlInvalidSpaceInBetweenDotsRegex
  )

  return !!(
    websiteUrlToValidate.length &&
    prefix &&
    (dots && dots.length >= 1) &&
    !invalidSpaceInBetweenDots
  )
}

export const validateDiscardReason = reason => {
  return (
    reason in DistributorDiscardReasonEnum ||
    reason in ServiceProviderDiscardReasonEnum
  )
}

export const validateShowValueAdded = showValueAdded => {
  return showValueAdded in ShowValueAddedEnum
}

export const validateField = (fieldValue, fieldName) => {
  if (!fieldValue) {
    setErrors(
      new FrontEndError(fieldName, `${upperFirst(fieldName)} is required.`)
    )
    return false
  }

  return true
}
