import {
  LoaderState,
  IncreaseNumberOfActiveRequests,
  DecreaseNumberOfActiveRequests,
  INCREASE_ACTIVE_REQUESTS,
  DECREASE_ACTIVE_REQUESTS,
} from '../types'

const initialState: LoaderState = {
  numberOfActiveRequests: 0,
}

export function loaderReducer(
  state = initialState,
  action: IncreaseNumberOfActiveRequests | DecreaseNumberOfActiveRequests
): LoaderState {
  switch (action.type) {
    case INCREASE_ACTIVE_REQUESTS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case DECREASE_ACTIVE_REQUESTS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}
