import {
  ClassificationsState,
  SetClassifications,
  ClearClassifications,
  SET_CLASSIFICATIONS,
  CLEAR_CLASSIFICATIONS,
  SetAllClassifications,
  SET_ALL_CLASSIFICATIONS,
  SET_AVAILABLE_MINOR_CLASSIFICATIONS,
  SetAvailableMinorClassifications,
  SET_SELECTED_MINOR_CLASSIFICATIONS,
} from '../types'

const initialState: ClassificationsState = {
  selected: [],
  allClassifications: [],
  availableMinorClassifications: [],
  selectedMinorClassifications: [],
}

export function classificationReducer(
  state = initialState,
  action:
    | SetClassifications
    | ClearClassifications
    | SetAllClassifications
    | SetAvailableMinorClassifications
    | any
): ClassificationsState {
  switch (action.type) {
    case SET_CLASSIFICATIONS: {
      return {
        ...state,
        selected: [...[], ...action.payload],
      }
    }
    case SET_ALL_CLASSIFICATIONS: {
      return {
        ...state,
        allClassifications: [...[], ...action.payload],
      }
    }
    case SET_AVAILABLE_MINOR_CLASSIFICATIONS: {
      return {
        ...state,
        availableMinorClassifications: [...[], ...action.payload],
      }
    }
    case SET_SELECTED_MINOR_CLASSIFICATIONS: {
      return {
        ...state,
        selectedMinorClassifications: [...[], ...action.payload],
      }
    }
    case CLEAR_CLASSIFICATIONS: {
      return {
        ...state,
        ...{
          selected: [],
          availableMinorClassifications: [],
          selectedMinorClassifications: [],
        },
      }
    }
    default:
      return state
  }
}
