import React, { Component } from 'react'
import { connect } from 'react-redux'

import { AppModeEnum } from '../../shared/models'
import FindingReviewDistributor from './distributor/FindingReviewDistributor'
// tslint:disable-next-line:max-line-length
import FindingReviewServiceProvider from './service-provider/FindingReviewServiceProvider'
import { AppState } from '../../store'

interface State {}

interface DispatchProps {}

type Props = AppState & DispatchProps

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = () => ({})

class FindingReview extends Component<Props, State> {
  render(): JSX.Element {
    const {
      appMode: { activeMode },
    } = this.props

    if (activeMode === AppModeEnum.Distributors) {
      return <FindingReviewDistributor />
    } else {
      return <FindingReviewServiceProvider />
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FindingReview)
