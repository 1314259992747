import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Container, Form, Row, Col, Tab, Nav } from 'react-bootstrap'

import { distributeReserveRoute } from '../../../shared/constants'
import { SidebarActiveItem, DistributeToManager } from '../../../shared/models'

import { updateSidebarActiveItem } from '../../../store/actions'

import DistributeReserverHOC from '../DistributeReserveHOC'

import '../../../shared/style/tabs.css'

interface State {}

interface DispatchProps {
  updateSidebarActiveItem: (key: SidebarActiveItem) => void
}

interface StateProps {
  type: DistributeToManager
}

type Props = StateProps & DispatchProps

const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch => ({
  updateSidebarActiveItem: (key: SidebarActiveItem) => {
    dispatch(updateSidebarActiveItem(key))
  },
})

class DistributorDistributeReserve extends Component<Props, State> {
  componentDidMount(): void {
    this.props.updateSidebarActiveItem(distributeReserveRoute.eventKey)
  }

  render(): JSX.Element {
    return (
      <Form className="form-container">
        <Container fluid className="margin-top-75 padding-bottom-30">
          <Tab.Container id="tabs-container" defaultActiveKey="1">
            <Row className="custom-tabs">
              <Col sm={7}>
                <Tab.Content className="align-middle">
                  <Tab.Pane mountOnEnter eventKey="1" className="three">
                    <DistributeReserverHOC
                      companyType="Distributor"
                      originCompanyStatus="finding-unassigned"
                      needReview={false}
                    />
                  </Tab.Pane>
                  <Tab.Pane mountOnEnter eventKey="2" className="three">
                    <DistributeReserverHOC
                      companyType="Distributor"
                      originCompanyStatus="tagging-unassigned"
                      needReview={false}
                    />
                  </Tab.Pane>
                  <Tab.Pane mountOnEnter eventKey="3" className="two">
                    <DistributeReserverHOC
                      companyType="Distributor"
                      originCompanyStatus="finding-tester-done"
                      needReview={false}
                    />
                  </Tab.Pane>
                  <Tab.Pane mountOnEnter eventKey="4" className="two">
                    <DistributeReserverHOC
                      companyType="Distributor"
                      originCompanyStatus="tagging-tester-done"
                      needReview={true}
                    />
                  </Tab.Pane>
                  <Tab.Pane mountOnEnter eventKey="5" className="two">
                    <DistributeReserverHOC
                      companyType="Distributor"
                      originCompanyStatus="tagging-tester-done"
                      needReview={false}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
              <Col sm={5}>
                <Nav variant="pills" as="ul" className="flex-column">
                  <span className="navbar-brand"> Distribute To User</span>
                  <Nav.Item>
                    <Nav.Link as="li" eventKey="1">
                      From FINDING Reserve to FINDING Queue
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link as="li" eventKey="2">
                      From TAGGING Reserve to TAGGING Queue
                    </Nav.Link>
                  </Nav.Item>
                  <hr />
                  <span className="navbar-brand"> Distribute To Manager</span>
                  <Nav.Item>
                    <Nav.Link as="li" eventKey="3">
                      From FINDING Review To Manager FINDING Review
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link as="li" eventKey="4">
                      From TAGGING Review To Manager TAGGING Review
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link as="li" eventKey="5">
                      From FINAL Review To Manager FINAL Review
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Form>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributorDistributeReserve)
