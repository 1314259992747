export enum SidebarActiveItem {
  Home = 1,
  UserTagging,
  UserFinding,
  FinalReview,
  FindingReview,
  TaggingReview,
  ManageLogins,
  DistributeReserve,
  RedistributeQueue,
  SystemManagerDashboard,
  AccuracyLevels,
  TaggingCompendium,
  AuditTrail,
  ReportsRepository,
}
