import React, { Component, ReactElement } from 'react'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'

import { AuthService, getEnvironment } from '../../../services/auth-service'
import { get } from '../../../services/base/base-http-service'
import { AppState } from '../../../store'
import { AppModeEnum, Environment } from '../../../shared/models'
import { updateAppMode } from '../../../store/actions'

import logo from '../../../logo.jpg'
import './Header.css'

interface State {
  loggedOut: boolean
  environmentInfo: EnvironmentInfo
}

interface EnvironmentInfo {
  environment: string
  uiVersion: string
  apiVersion: string
  higherVersion: string
}
interface DispatchProps {
  updateAppMode(activeMode: AppModeEnum): void
}

type Props = AppState & DispatchProps

const mapStateToProps = (state: AppState) => ({ ...state })
const mapDispatchToProps = dispatch => ({
  updateAppMode: (activeMode: AppModeEnum) => {
    dispatch(updateAppMode(activeMode))
  },
})

class Header extends Component<Props, State> {
  state: State = {
    loggedOut: false,
    environmentInfo: {
      environment: '',
      uiVersion: '',
      apiVersion: '',
      higherVersion: '',
    },
  }

  async logout(): Promise<void> {
    const authService = AuthService.getInstance()
    await authService.logout()
    this.setState(() => ({
      loggedOut: true,
    }))
    this.props.updateAppMode(AppModeEnum.NotSelected)
  }

  async componentDidMount() {
    const environment: Environment | null = await getEnvironment()
    if (environment) {
      let { XbsEnvironment, version: uiVersionNumber } = environment

      let apiVersionNumber: string = (await get(`health`)).data
      uiVersionNumber = uiVersionNumber || '0.0.1'
      apiVersionNumber = apiVersionNumber || '0.0.1'

      const higherVersion: string =
        Number(uiVersionNumber.replace(/\./g, '')) >
        Number(apiVersionNumber.replace(/\./g, ''))
          ? uiVersionNumber
          : apiVersionNumber

      const environmentInfo = {
        environment: XbsEnvironment,
        uiVersion: uiVersionNumber,
        apiVersion: apiVersionNumber,
        higherVersion,
      }

      this.setState({ ...this.state, environmentInfo })
    }
  }

  getVersionInfo() {
    const title: string = `Env: ${
      this.state.environmentInfo.environment
    } - UI Version: ${this.state.environmentInfo.uiVersion} -  API Version: ${
      this.state.environmentInfo.apiVersion
    }`
    const options = {
      develop: 'Dev',
      staging: 'Staging',
      production: this.state.environmentInfo.higherVersion,
    }
    return {
      title,
      higherVersion: options[this.state.environmentInfo.environment],
    }
  }
  render(): ReactElement {
    if (this.state.loggedOut) {
      return <Redirect to="/login" />
    }
    return (
      <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
        <div className="navbar-brand">
          <img height="30" src={logo} alt="logo" />
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="nav-details d-md-none d-sm-none d-none d-lg-block d-xl-block">
          <span>XBS Tagging App</span>
        </div>

        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav nav">
            <div className="version" title={this.getVersionInfo().title}>
              {this.getVersionInfo().higherVersion}
            </div>
            <li className="nav-item border-left">
              <div className="nav-link" onClick={() => this.logout()}>
                <i className="fas fa-sign-out-alt" /> (Log Out)
              </div>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
