import React, { Component } from 'react'
import BatchProcessTable from './components/BatchProcessTable/BatchProcessTable'
import { getBatches } from '../../../services/batch-process-report-service'
import { Batch, Header } from '../../../shared/models/batch'

interface State {
  items: Batch[]
  isLoaded: boolean
  headers: Header[]
}

class BatchProcessRepository extends Component<{}, State> {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      isLoaded: false,
      headers: [
        {
          name: 'Batch Name',
          id: 'batch_name',
          direction: 'none',
        },
        {
          name: 'Batch Total',
          id: 'companies_count',
          direction: 'none',
        },
        {
          name: 'Created On',
          id: 'batch.created_at',
          direction: 'none',
        },
        {
          name: 'Created By',
          id: 'first_name, last_name',
          direction: 'none',
        },
      ],
    }
  }

  componentDidMount = async (): Promise<void> => {
    const batches = await getBatches('createdAt', 'desc')
    this.setState({
      items: batches.data,
    })
  }

  sortBatches = async (column, direction): Promise<void> => {
    const batches = await getBatches(column, direction)
    const headers = this.state.headers
    const sortedHeaders: Header[] = []
    for (let header of headers) {
      const curHeader = header
      curHeader.direction = curHeader.id === column ? direction : 'none'
      sortedHeaders.push(curHeader)
    }
    this.setState({
      items: batches.data,
      headers: sortedHeaders,
    })
  }

  render(): JSX.Element {
    return (
      <BatchProcessTable
        items={this.state.items}
        headers={this.state.headers}
        sort={this.sortBatches}
      />
    )
  }
}

export default BatchProcessRepository
