import {
  SuccessState,
  SET_SUCCESS,
  SetSuccess,
  ClearSuccess,
  CLEAR_SUCCESS,
} from '../types'

const initialState: SuccessState = {
  message: null,
  autoClose: true,
}

export function successReducer(
  state = initialState,
  action: SetSuccess | ClearSuccess
): SuccessState {
  switch (action.type) {
    case SET_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CLEAR_SUCCESS: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}
