import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Spinner } from 'react-bootstrap'

import { AppState } from '../../store'

import './Loader.css'

interface State {}

interface DispatchProps {}

type Props = AppState & DispatchProps

const mapStateToProps = (state: AppState) => ({ ...state })
const mapDispatchToProps = () => ({})

class Loader extends Component<Props, State> {
  render(): JSX.Element {
    const {
      loader: { numberOfActiveRequests },
    } = this.props
    if (numberOfActiveRequests === 0) {
      return <div />
    }

    return (
      <div id="loader-overflow">
        <div id="loader-spinner">
          <Spinner animation="grow" />
          <Spinner animation="grow" />
          <Spinner animation="grow" />
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Loader)
