import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { AuthService, isUserAccessLevelValid } from '../../services'
import Main from '../main/Main'

const authService = AuthService.getInstance()

const AuthProtectedRoute = ({
  component: Component,
  accessLevelRoles,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props: any) =>
        authService.isLoggedIn() && authService.isUserInfoAvailable() ? (
          <div>
            {isUserAccessLevelValid(accessLevelRoles) ? (
              <Main {...props} component={Component} />
            ) : (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: props.location },
                }}
              />
            )}
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              search: props.location.search,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default AuthProtectedRoute
