export const INCREASE_ACTIVE_REQUESTS = 'INCREASE_ACTIVE_REQUESTS'
export const DECREASE_ACTIVE_REQUESTS = 'DECREASE_ACTIVE_REQUESTS'

export interface IncreaseNumberOfActiveRequests {
  type: typeof INCREASE_ACTIVE_REQUESTS
  payload: LoaderState
}
export interface DecreaseNumberOfActiveRequests {
  type: typeof DECREASE_ACTIVE_REQUESTS
  payload: LoaderState
}
export interface LoaderState {
  numberOfActiveRequests: number
}
