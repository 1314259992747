import { map, range } from 'lodash'
import { User } from '../shared/models'

export const getTaskCountOptions = (min = 10, max = 210, step = 10) => {
  return map(range(min, max, step), count => ({ label: count, value: count }))
}

export const formatUserDataForLabel = ({
  firstName,
  lastName,
  username,
}: User): string => {
  return `${firstName} ${lastName} (${username})`
}

export const formatUrl = (url: string): string => {
  let formattedUrl = url
  if (!url) return ''

  if (url.indexOf('http://') < 0 && url.indexOf('https://') < 0) {
    formattedUrl = `http://${url}`
  }

  return formattedUrl
}

export const getUserLabel = (user: User): string => {
  return user ? `${user.fullName}` : ''
}

const taskCountOptionsUpToOneThousand = [
  ...getTaskCountOptions(10, 200),
  ...getTaskCountOptions(200, 1100, 100),
]

export const taskCountOptionsForUserAtFinding = () => {
  return taskCountOptionsUpToOneThousand
}

export const taskCountOptionsForUserAtTagging = () => {
  return taskCountOptionsUpToOneThousand
}

export const taskCountOptionsForManagerAtTagging = () => {
  return taskCountOptionsUpToOneThousand
}

export const taskCountOptionsForManagerAtFinding = () => {
  return taskCountOptionsUpToOneThousand
}

export const taskCountOptionsForManagerAtFinal = () => {
  return taskCountOptionsUpToOneThousand
}
