import React, { Component } from 'react'
import { connect } from 'react-redux'

import { AppModeEnum } from '../../shared/models'
import { AppState } from '../../store'

import FinalReviewDistributor from './distributor/FinalReviewDistributor'
import FinalReviewServiceProvider from './service-provider/FinalReviewServiceProvider'

interface State {}

interface DispatchProps {}

type Props = AppState & DispatchProps

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = () => ({})

class FinalReview extends Component<Props, State> {
  render(): JSX.Element {
    const {
      appMode: { activeMode },
    } = this.props

    if (activeMode === AppModeEnum.Distributors) {
      return <FinalReviewDistributor />
    } else {
      return <FinalReviewServiceProvider />
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinalReview)
