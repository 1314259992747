import React, { Component } from 'react'
import Filters from './components/Filters'
import Table from './components/Table'

import { AppModeEnum } from '../../../shared/models/enums'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'
import { Auditable } from '../../../shared/models'

interface State {}

interface PayloadAccessors {
  valueAccessor: string
  displayValueAccessor?: string
}

type Props = {
  auditData: {
    data: Auditable[]
    totalCompsFound: number
    lockedCompaniesSelected: []
    lockedCompaniesData: Auditable[]
  }
  handleEvent: (eventToTrigger: string, eventParams?: object) => void
  appMode: AppModeEnum
  filterParams: object
  filterData: any
  taggers: Array<any>
  reviewers: Array<any>
}

class TaggingCompendiumTable extends Component<Props, State> {

  standarizeResponse = (items, accessors: PayloadAccessors) => {
    return items.map(item => {
      let { valueAccessor, displayValueAccessor } = accessors
      displayValueAccessor = displayValueAccessor || valueAccessor

      return {
        displayValue: item[displayValueAccessor],
        value: item[valueAccessor],
      }
    })
  }

  render(): JSX.Element {
    const {
      auditData,
      handleEvent,
      appMode,
      filterParams,
      filterData,
      taggers,
      reviewers,
    } = this.props

    return (
      <div className="audit_trail_container">
        <span
          onClick={() => handleEvent('handleRefreshButtonClick')}
          className="refresh-data"
        >
          <FontAwesomeIcon icon={faSync} />
        </span>

        <Filters
          appMode={appMode}
          handleEvent={handleEvent}
          filterParams={filterParams}
          filterData={filterData}
          taggers={taggers}
          reviewers={reviewers}
          lockedCompanies={auditData.lockedCompaniesSelected}
        />
        <Table
          appMode={appMode}
          auditData={auditData}
          handleEvent={handleEvent}
        />
      </div>
    )
  }
}

export default TaggingCompendiumTable
