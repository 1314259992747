import { NoDataDisplayStateEnum, SidebarActiveItem } from '../../shared/models'

export const TOGGLE_NO_DATA_PAGE = 'TOGGLE_NO_DATA_PAGE'

export interface toggleNoDataPageDisplayState {
  type: typeof TOGGLE_NO_DATA_PAGE
  payload: NoDataState
}

export interface NoDataState {
  state: NoDataDisplayStateEnum
  page: SidebarActiveItem | null
}
