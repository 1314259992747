import React, { Component } from 'react'
import { TaggersDailyProgressReportFilter } from '../../../../shared/models'
import DataRange from './DataRangePicker'

interface Props {
  handleEvent: Function
  filterParams: TaggersDailyProgressReportFilter
}

class TaggersDailyReportFilters extends Component<Props> {
  render(): JSX.Element {
    const { handleEvent, filterParams } = this.props

    return (
      <div className="filter-button-container">
        <div className="filter-button-middle-container">
          <DataRange
            dataRangeTitle="Report Date"
            startDate={filterParams.reportInitDate || undefined}
            endDate={filterParams.reportFinalDate || undefined}
            handleEvent={handleEvent}
            handleDateSelectionEvent="handleReportDateSelection"
          />
        </div>
        <div className="filter-button-end-container">
          <div
            className="button"
            onClick={() => handleEvent('handleApplyFiltersClick')}
          >
            Search
          </div>

          <div
            className="button"
            onClick={() => handleEvent('handleResetFilterClick')}
          >
            Reset Filter
          </div>
        </div>
      </div>
    )
  }
}

export default TaggersDailyReportFilters
