import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Modal as BoostrapModal } from 'react-bootstrap'
import {
  modalContentInvalidWebsiteFormatMessage,
  modalContentWebsiteUrlConflictNoWebsiteDiscardReason,
} from '../../../shared/constants'

interface Props {
  isOpen: boolean
  modalContent: string
  handleEvent: (eventToTrigger: string, eventParams?: object) => void
}

class Modal extends Component<Props> {
  render(): JSX.Element | null {
    const { handleEvent } = this.props
    const modalBody = {
      websiteUrlConflictNoWebsiteDiscardReason: modalContentWebsiteUrlConflictNoWebsiteDiscardReason,
      invalidWebsiteUrlFormat: modalContentInvalidWebsiteFormatMessage,
    }

    return (
      <div>
        <BoostrapModal
          show={this.props.isOpen}
          onHide={() =>
            handleEvent('handleToggleModalNoWebsiteButOneWasProvided')
          }
          size="lg"
          centered
          className="compendium-modal"
        >
          <BoostrapModal.Body>
            <div className="p-3">
              {modalBody[this.props.modalContent].title}
            </div>
            {!!modalBody[this.props.modalContent].content && (
              <ul>
                {modalBody[this.props.modalContent].content.map(
                  (bullet, key) => (
                    <li key={key}>{bullet}</li>
                  )
                )}
              </ul>
            )}
          </BoostrapModal.Body>
          <BoostrapModal.Footer>
            <Button
              variant="primary"
              onClick={() =>
                handleEvent('handleToggleModalNoWebsiteButOneWasProvided')
              }
              className="compendium-button"
            >
              Ok
            </Button>
          </BoostrapModal.Footer>
        </BoostrapModal>
      </div>
    )
  }
}

export default Modal
