export const SET_SUCCESS = 'SET_SUCCESS'
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS'

export interface SetSuccess {
  type: typeof SET_SUCCESS
  payload: any
}

export interface ClearSuccess {
  type: typeof CLEAR_SUCCESS
}

export interface SuccessState {
  message: string | null
  autoClose: boolean
}
